import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Dashboard.scss';
import Throbber from '../Throbber/Throbber';

export class Dashboard extends Component {
  render() {
    const {
      user,
      selectedOrganization: { isEnabled, id: selectedOrgId },
    } = this.props;
    return (
      <div>
        <span className="title-text">User Dashboard</span>

        <div className="flexColumn indented">
          <h3>{`Welcome, ${user.firstName}`}</h3>
          {(selectedOrgId &&
            ((isEnabled && <h4>Select a feature from the navigation panel on the left.</h4>) || (
              <h4 className="text-danger">
                Your organization is deactivated. Please contact Classy Professional Services team at
                classy-services@classy.org if you need any more assistance.
              </h4>
            ))) || <Throbber loading={true} />}
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  user: PropTypes.object.isRequired,
  selectedOrganization: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user, selectedOrganization } = state.login;
  return {
    user,
    selectedOrganization,
  };
};

export default connect(mapStateToProps)(Dashboard);
