import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagPluginReactResult } from '@bugsnag/plugin-react';
import constants from '../../Helpers/constants';

if (Bugsnag) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY_CLIENT || 'REACT_APP_BUGSNAG_API_KEY_CLIENT is not set',
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.REACT_APP_NODE_ENV || constants.ENVIRONMENTS.DEV,
    // to debug this locally, add `constants.ENVIRONMENTS.DEV` to the enabledReleaseStages array
    enabledReleaseStages: [constants.ENVIRONMENTS.PRODUCTION, constants.ENVIRONMENTS.STAGING],
    appVersion: process.env.REACT_APP_VERSION || 'unknown',
  });
}

class NoopErrorBoundary extends React.Component {
  render() {
    return <>{this.props.children}</>;
  }
}

let ErrorComponent: React.ElementType;
if (typeof Bugsnag.getPlugin('react') === 'undefined') {
  ErrorComponent = NoopErrorBoundary;
} else {
  ErrorComponent = (Bugsnag.getPlugin('react') as BugsnagPluginReactResult).createErrorBoundary(React);
}

class ErrorBoundary extends React.Component {
  render() {
    return <ErrorComponent>{this.props.children}</ErrorComponent>;
  }
}

export default ErrorBoundary;
