import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { persistor, store } from './Redux/store';
import LoginCheck from './Components/LoginCheck/LoginCheck';

import { injectStore } from './Services/Api';

injectStore(store);

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LoginCheck />
      </PersistGate>
    </Provider>
  );
};

export default App;
