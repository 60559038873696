import React, { Component } from 'react';
import { connect } from 'react-redux';

import constants from '../../Helpers/constants';
import validators from '../../Helpers/validators';
import recurringMigrationDonationActions from '../../Redux/RecurringDonationMigration.redux';
import api from '../../Services/Api';
import ClassyAlert from '../ClassyAlert/ClassyAlert';
import ClassyButton from '../ClassyButton/ClassyButton';

import RecurringDonationMigrationForm from './RecurringDonationMigrationForm/RecurringDonationMigrationForm';
import { RecurringDonationMigrationView } from './RecurringDonationMigrationView';
import './RecurringDonationMigrationView.scss';

export class RecurringDonationMigration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
      showAlert: false,
      alertMessage: '',
      clientValidationErrors: {},
    };
  }

  setAlert = (errors, showAlert = true) => {
    let alertMessage = errors;
    if (Array.isArray(errors)) {
      alertMessage = errors.map((error) => error?.message || error?.toString()).join(', ');
    }

    this.setState({ alertMessage, showAlert });
  };

  componentDidMount = () => {
    this.getJobs();
  };

  getJobs = async (page = constants.PAGINATION.PAGE_DEFAULT, pageSize = constants.PAGINATION.SIZE_DEFAULT, sort) => {
    this.props.getRecurringDonationMigrationJobsRequest();

    try {
      const response = await api.getAllRecurringDonationMigrationJobs(page, pageSize, sort);

      if (response && response.success) {
        this.props.getRecurringDonationMigrationJobsSuccess(response);
      } else {
        this.props.getRecurringDonationMigrationJobsFailure(response);
      }

      return response;
    } catch (error) {
      this.props.getRecurringDonationMigrationJobsFailure(error);
      this.setAlert(error.errors);
      return;
    }
  };

  onFileUpload = async (job) => {
    this.setState({ clientValidationErrors: {} });
    const clientValidationErrors = validators.validateRecurringDonationMigrationJob(job);
    // the validation errors from the server have the field-name information
    // stripped out, so these two validation displays are divergent. :(
    if (clientValidationErrors.error) {
      this.setState({ clientValidationErrors: clientValidationErrors });
      this.setAlert(validators.getJoiErrorsAsArray(clientValidationErrors.error));
      return;
    }
    const formData = this.createFormDataFromJob(job);
    try {
      const createRecurringDonationMigrationJobResponse = await api.createRecurringDonationMigrationJob(formData);
      // noinspection JSUnresolvedVariable
      if (createRecurringDonationMigrationJobResponse.success) {
        const recurringDonationMigrationCreationResponseData = createRecurringDonationMigrationJobResponse.data;
        const { ...data } = {
          ...job,
          ...recurringDonationMigrationCreationResponseData,
        };
        this.props.createRecurringDonationMigrationJobSuccess(data);
        this.setState({ isEditMode: false });
      } else {
        this.setAlert('An error occurred when creating a job');
      }
    } catch (exception) {
      this.setAlert(exception.errors);
    }
  };

  onDownloadFile = (jobId, urlFn) => {
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = urlFn(jobId);
    link.click();
    document.body.removeChild(link);
  };

  onDownloadDonors = (jobId) => this.onDownloadFile(jobId, api.downloadDonorFileUrl);

  createFormDataFromJob = (job) => {
    const formData = new FormData();
    formData.append('id', job.id);
    if (job.organizationId) {
      formData.append('organizationId', job.organizationId);
    }
    formData.append('label', job.label);
    formData.append('isDryRun', job.isDryRun);
    formData.append('submissionType', job.submissionType);

    if ('donorFile' in job && job['donorFile']) {
      formData.append('donorFile', job.donorFile, job.donorFile.name);
    }
    if ('tokenFile' in job && job['tokenFile']) {
      formData.append('tokenFile', job['tokenFile'], job['tokenFile'].name);
    }
    return formData;
  };
  openEditForm = () => {
    this.setState({ isEditMode: true });
  };
  closeEditForm = () => {
    this.setState({ isEditMode: false });
  };

  submitForm = async (newJob) => this.onFileUpload(newJob);

  render = () => {
    return (
      <>
        <ClassyAlert
          data-testid="rdm_classy_alert"
          show={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />

        <h2 className="title-text">Recurring Donation Migration</h2>

        {this.state.isEditMode && (
          <RecurringDonationMigrationForm
            data-testid="rdm_submit_form"
            onSubmit={this.submitForm}
            onCancel={this.closeEditForm}
            alertErrors={this.setAlert}
          />
        )}

        {!this.state.isEditMode && (
          <RecurringDonationMigrationView
            data-testid="rdm_view"
            recurringDonationMigrationJobs={this.props.recurringDonationMigrationJobs}
            recurringDonationMigrationJobsTotalPages={this.props.recurringDonationMigrationJobsTotalPages}
            onFileUpload={this.onFileUpload}
            clientValidationErrors={this.state.clientValidationErrors}
            onDownloadDonors={this.onDownloadDonors}
            getJobs={this.getJobs}
          />
        )}

        {!this.state.isEditMode && (
          <div className="flexRow">
            <ClassyButton
              data-testid="rdm_submit_button"
              className="recurring-donations-migration__submit-button"
              title="New Job"
              onClick={this.openEditForm}
            />
          </div>
        )}
      </>
    );
  };
}

const mapStateToProps = (state) => {
  const {
    loadingRecurringDonationMigrationJobs,
    recurringDonationMigrationJobs,
    recurringDonationMigrationJobsError,
    recurringDonationMigrationJobsTotalPages,
  } = state.recurringDonationMigration;

  const accessToken = state.login.accessToken;

  return {
    accessToken,
    loadingRecurringDonationMigrationJobs,
    recurringDonationMigrationJobs,
    recurringDonationMigrationJobsError,
    recurringDonationMigrationJobsTotalPages,
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    createRecurringDonationMigrationJobSuccess,
    getRecurringDonationMigrationJobsFailure,
    getRecurringDonationMigrationJobsRequest,
    getRecurringDonationMigrationJobsSuccess,
  } = recurringMigrationDonationActions;

  return {
    createRecurringDonationMigrationJobSuccess: (recurringDonationMigrationJob) =>
      dispatch(createRecurringDonationMigrationJobSuccess(recurringDonationMigrationJob)),
    getRecurringDonationMigrationJobsFailure: (recurringDonationMigrationJobsError) =>
      dispatch(getRecurringDonationMigrationJobsFailure(recurringDonationMigrationJobsError)),
    getRecurringDonationMigrationJobsRequest: () => dispatch(getRecurringDonationMigrationJobsRequest()),
    getRecurringDonationMigrationJobsSuccess: (recurringDonationMigrationJobs) =>
      dispatch(getRecurringDonationMigrationJobsSuccess(recurringDonationMigrationJobs)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecurringDonationMigration);
