import React from 'react';
import { RlcOrganization } from '../../Services/Rlc/RlcOrganization';
import { CellInfo } from 'react-table';
import ClassyTable from '../ClassyTable/ClassyTable';
import { useSelectedOrganizationId } from './RlcHooks';

interface AccountListProps {
  organizations: Array<RlcOrganization>;
  onManage: (organization: RlcOrganization) => void;
}

const AccountList = ({ organizations, onManage }: AccountListProps) => {
  const selectedOrganizationId = useSelectedOrganizationId();
  const columns = [
    {
      Header: 'Account Id',
      accessor: 'organizationId',
      width: 150,
      Cell: (row: CellInfo) => <div title={row.value}>{row.value}</div>,
    },
    {
      Header: 'Account Name',
      width: 250,
      resize: true,
      accessor: 'name',
      Cell: (row: CellInfo) => <div title={row.value}>{row.value}</div>,
    },
    {
      Header: 'Status',
      width: 250,
      resize: true,
      accessor: 'initialSetupComplete',
      Cell: (row: CellInfo) => {
        let status: string;
        if (row.value && row.original.rlcGroupId) {
          status = 'Synced';
        } else if (row.original.rlcGroupId) {
          status = 'Initial sync in progress...';
        } else {
          status = 'Not added to RLC yet';
        }

        return <div title="initialSetupComplete">{status}</div>;
      },
    },
    {
      width: 150,
      Cell: (row: CellInfo) => (
        <>
          {!!row.original.initialSetupComplete &&
            !!row.original.rlcGroupId &&
            selectedOrganizationId === row.original.organizationId && (
              <button className="btn-link" onClick={() => onManage(row.row._original)}>
                Manage campaigns
              </button>
            )}
        </>
      ),
    },
  ];

  return (
    <>
      <h2>Active Accounts</h2>
      <ClassyTable
        showWhenEmpty
        columns={columns}
        data={organizations}
        defaultPageSize={20}
        exportFilename={'rlcAccounts'}
        loading={false}
        manualPagination={false}
        pageSizeOptions={[20, 50]}
        resizable={true}
      />
      {organizations && !organizations.length ? <span>There are no accounts.</span> : null}
    </>
  );
};

export default AccountList;
