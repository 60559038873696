import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import qs from 'qs';
import api from '../../Services/Api';
import ClassyAlert from '../ClassyAlert/ClassyAlert';
import history from '../../Router/history';
import ClassyButton from '../ClassyButton/ClassyButton';
import LoginActions from '../../Redux/Login.redux';
import validators from '../../Helpers/validators';
import './NewPassword.scss';

export class NewPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordConfirm: '',
      isUpdated: false,
      showAlert: false,
      alertMessage: '',
    };
  }

  setAlert = (alertMessage, showAlert = true) => {
    this.setState({ alertMessage, showAlert });
  };

  getQueryString = () => {
    return typeof window !== 'undefined' && qs.parse(window.location.search);
  };

  getPasswordResetToken = () => {
    const queryString = this.getQueryString();
    return queryString['?t'];
  };

  getEmail = () => {
    const queryString = this.getQueryString();
    return queryString['e'];
  };

  onChangePassword = (e, state) => {
    this.setState({ [state]: e.target.value });
  };

  oneTimePasswordChangeSuccess() {
    const { user, setUser } = this.props;

    user.isOneTimePassword = false;
    setUser(user);
    window.location.reload();
  }

  onSubmitNewPassword = async (event) => {
    event.preventDefault();
    const { password, passwordConfirm } = this.state;
    const passwordResetToken = this.getPasswordResetToken();
    const email = this.getEmail();

    if (validators.isValidPassword(password) === false) {
      this.setAlert('Your password does not meet the requirements.');
    } else if (password !== passwordConfirm) {
      this.setAlert('The passwords you have entered do not match.');
    } else {
      try {
        const setNewUserPasswordResponse = await api.setNewUserPassword({
          password,
          passwordResetToken,
          email,
        });
        if (!passwordResetToken) {
          this.oneTimePasswordChangeSuccess();
        } else if (setNewUserPasswordResponse.success) {
          this.setState({ isUpdated: true });
        }
      } catch (error) {
        let message = JSON.stringify(error);
        if (error.errors.length) {
          message = error.errors;
        }
        this.setAlert(message);
      }
    }
  };

  render() {
    const { password, passwordConfirm, isUpdated } = this.state;

    const passwordResetToken = this.getPasswordResetToken();

    return (
      <div id="newPassword">
        <ClassyAlert
          show={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />

        <h2 className="title-text">Choose a new password</h2>

        <div className="flexColumn">
          <span className="margin-vertical-large">Minimum Requirements:</span>
          <ul>
            <li>8 - 50 characters in length</li>
            <li>at least 1 uppercase letter</li>
            <li>at least 1 number</li>
            <li>at least 1 special character (e.g., !, $, @, etc.)</li>
          </ul>
        </div>
        <Form className="password-form">
          <FormGroup>
            <FormControl
              onChange={(newPassword) => this.onChangePassword(newPassword, 'password')}
              placeholder="New Password"
              type="password"
              value={password}
            />
            <FormControl
              onChange={(newPasswordConfirm) => this.onChangePassword(newPasswordConfirm, 'passwordConfirm')}
              placeholder="Confirm New Password"
              type="password"
              value={passwordConfirm}
            />
            <ClassyButton disabled={!password || !passwordConfirm} title="Submit" onClick={this.onSubmitNewPassword} />
          </FormGroup>
        </Form>

        {isUpdated && (
          <div className="margin-vertical-large">
            Your password has been reset successfully. Click the link below to return to the login page.
          </div>
        )}
        {passwordResetToken && (
          <div>
            <button className="btn-link" onClick={() => history.push('/')}>
              To Login Page
            </button>
            <i className="fa fa-arrow-right padding-medium" />
          </div>
        )}
      </div>
    );
  }
}

NewPassword.propTypes = {
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state.login;
  return {
    user,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { setUser } = LoginActions;

  return {
    setUser: (user) => dispatch(setUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);
