import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Checkbox, ControlLabel, FormControl, FormGroup, Form, HelpBlock } from 'react-bootstrap';

import './RecurringDonationMigrationForm.scss';
import OrganizationIdInput from '../../OrganizationIdInput/OrganizationIdInput.jsx';
import ClassyButton from '../../ClassyButton/ClassyButton';
import validators from '../../../Helpers/validators';
import constants from '../../../Helpers/constants';

class RecurringDonationMigrationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: uuidv4(),
      createdAt: Date.now(),
      organizationId: null,
      organizationIdLoading: false,
      label: '',
      submissionType: constants.RECURRING_DONATION_MIGRATION_OPTIONS[0],
      donorFile: null,
      tokenFile: null,
      isDryRun: true,
      validated: false,
      tokenFileError: null,
      userFileError: null,
      labelError: null,
      submitDisabled: false,
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ validated: true });
    const isValid = await this.isFormValid();
    if (isValid) {
      this.setState({ submitDisabled: true });
      const job = this.getJob();

      try {
        await this.props.onSubmit(job);
        this.setState({ submitDisabled: false });
      } catch (e) {
        // Additional Error Handling
        this.setState({ submitDisabled: false });
      }
    } else {
      let message = 'Please fix the errors and fill the required fields before submitting the file';
      if (this.state.organizationIdLoading) {
        message = 'Please wait for the Organization Id Field validation to complete';
      }
      this.props.alertErrors(message);
    }
  };

  handleCancel = (e) => {
    e.preventDefault();
    this.props.onCancel();
  };

  isFormValid = async () => {
    const job = this.getJob();
    const result = validators.validateRecurringDonationMigrationJob(job);

    if (result.error) {
      this.setState({
        tokenFileError: validators.getValidationStateFromJoiError(result.error, 'tokenFile'),
        userFileError: validators.getValidationStateFromJoiError(result.error, 'donorFile'),
        labelError: validators.getValidationStateFromJoiError(result.error, 'label'),
        organizationIdError: validators.getValidationStateFromJoiError(result.error, 'organizationId'),
      });
      return false;
    } else {
      this.setState({
        tokenFileError: 'success',
        userFileError: 'success',
        labelError: 'success',
        organizationIdError: 'success',
      });
      return true;
    }
  };

  getJob() {
    const job = (({ id, createdAt, organizationId, label, donorFile, tokenFile, isDryRun }) => ({
      id,
      createdAt,
      organizationId,
      label,
      donorFile,
      tokenFile,
      isDryRun,
    }))(this.state);

    const submissionType = this.state.submissionType.value;
    job.submissionType = submissionType;

    return job;
  }

  handleCheckbox = (e) => {
    const isDryRun = e.target.checked;
    this.setState({ isDryRun });
  };

  handleFileChange = (e) => {
    const { files, name } = e.target;
    if (!files || !files[0]) {
      return;
    }
    this.setState({ [name]: files[0] });
  };

  handleTypeChange = (submissionType) => {
    this.setState({ submissionType });
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  setOrganizationId = (id) => this.setState({ organizationId: id });

  setOrganizationIdLoading = (isLoading) => this.setState({ organizationIdLoading: isLoading });

  render() {
    const { label, submissionType, isDryRun, organizationIdError, submitDisabled } = this.state;

    const submissionIsTokenEx =
      this.state.submissionType.value === constants.RECURRING_DONATION_MIGRATION_JOB_SUBMISSION_TYPES.TOKENEX;

    const submissionNeedsTokenFile = validators.submissionTypeRequiresTokenFile(this.state.submissionType.value);

    return (
      <div className="form-wrapper">
        <Form data-testid="rdm_form" noValidate validated="false">
          <OrganizationIdInput
            label="Global Organization Id"
            helpBlock={
              <>
                The global organization Id, if provided, will be applied to all rows that do not have an organizationId
                defined.
                <br />
                If this field is empty, please ensure organizationId is provided in the donor file.
              </>
            }
            organizationIdError={organizationIdError}
            setOrganizationId={this.setOrganizationId}
            setOrganizationIdLoading={this.setOrganizationIdLoading}
          />
          <FormGroup data-testid="rdm_job_label_form_group" controlId="label" validationState={this.state.labelError}>
            <ControlLabel data-testid="rdm_job_label_control_label">Job Label</ControlLabel>
            <FormControl
              data-testid="rdm_job_label_form_control"
              required
              name="label"
              onChange={this.handleInputChange}
              placeholder="Job Label"
              type="text"
              value={label}
            />
            <HelpBlock data-testid="rdm_label_help_block">
              A label is required to identify this job, e.g. "Eastern US Donors"
            </HelpBlock>
            <FormControl.Feedback />
          </FormGroup>
          <FormControl
            data-testid="rdm_submission_type_form_control"
            name="submissionType"
            componentClass="react-select"
          >
            <ControlLabel data-testid="rdm_submission_type_control_label">Submission Type</ControlLabel>
            <Select
              data-testid="rdm_submission_type_select"
              aria-label="Submission Type"
              name="submissionType"
              options={constants.RECURRING_DONATION_MIGRATION_OPTIONS}
              value={submissionType}
              onChange={this.handleTypeChange}
            />
            <FormControl.Feedback />
            <HelpBlock data-testid="rdm_migration_help_block">
              Select the type of Migration you’re running. This is dependent on the Organization’s payment processor
            </HelpBlock>
          </FormControl>
          <FormGroup
            data-testid="rdm_donor_file_form_group"
            className="user-file"
            validationState={this.state.userFileError}
          >
            <ControlLabel data-testid="rdm_donor_file_control_label">Donor File (CSV)</ControlLabel>
            <input
              data-testid="rdm_donor_file_input"
              name="donorFile"
              type="file"
              required
              accept=".csv"
              onChange={this.handleFileChange}
            />
            <FormControl.Feedback />
            <HelpBlock data-testid="rdm_template_csv_file_help_block">
              A CSV is required for the Donor File.
              <a href="/recurring-donations/stripe-donor-file-template.csv" className="template-file" download>
                Link to Stripe template Donor File.
              </a>
              <a href="/recurring-donations/tokenex-donor-file-template.csv" className="template-file" download>
                Link to TokenEx template Donor File.
              </a>
              <a href="/recurring-donations/paypal-commerce-donor-file-template.csv" className="template-file" download>
                Link to PayPal Commerce template Donor File.
              </a>
            </HelpBlock>
          </FormGroup>
          {submissionNeedsTokenFile && (
            <FormGroup data-testid="rdm_token_file_form_group" validationState={this.state.tokenFileError}>
              <ControlLabel data-testid="rdm_token_file_control_label">
                Token File {submissionIsTokenEx ? 'CSV' : 'JSON'}
              </ControlLabel>
              <input
                data-testid="rdm_token_file_input"
                name="tokenFile"
                type="file"
                required
                accept={submissionIsTokenEx ? '.csv' : 'application/json'}
                onChange={this.handleFileChange}
              />
              <FormControl.Feedback />
              {submissionIsTokenEx ? (
                <HelpBlock data-testid="rdm_tokenex_token_file_help_block">
                  A CSV file is required for the first TokenEx submission if the Donor File does not contain tokens.
                  <a href="/recurring-donations/tokenex-token-template.csv" className="template-file" download>
                    Link to template Token File.
                  </a>
                </HelpBlock>
              ) : (
                <HelpBlock data-testid="rdm_stripe_token_file_help_block">
                  A JSON file is required for the first Stripe submission if the Donor File does not contain tokens.
                  <a href="/recurring-donations/stripe-token-template.json" className="template-file" download>
                    Link to template Token File.
                  </a>
                </HelpBlock>
              )}
            </FormGroup>
          )}
          <div className="submit-button">
            <ClassyButton
              className="recurring-donations-migration__submit-button"
              title="Submit"
              disabled={submitDisabled}
              onClick={this.handleSubmit}
            />
            <ClassyButton
              className="recurring-donations-migration__submit-button"
              title="Cancel"
              disabled={submitDisabled}
              onClick={this.handleCancel}
            />
            <Checkbox data-testid="rdm_dry_run_checkbox" checked={isDryRun} onChange={this.handleCheckbox}>
              Dry Run
            </Checkbox>
            <HelpBlock data-testid="rdm_dry_run_help_block" className="dry-run-help">
              (When enabled, runs the Validation Job)
            </HelpBlock>
          </div>
        </Form>
      </div>
    );
  }
}

RecurringDonationMigrationForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default RecurringDonationMigrationForm;
