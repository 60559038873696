import React, { useCallback, useEffect, useState } from 'react';
import { Col, ControlLabel, FormControl, FormGroup, Row } from 'react-bootstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isEqual } from 'lodash';

import validators from '../../../Helpers/validators';
import ClassyButton from '../../ClassyButton/ClassyButton';
import api from '../../../Services/Api';
import OfflineDonationActions from '../../../Redux/OfflineDonations.redux';
import ClassyAlert from '../../ClassyAlert/ClassyAlert';

const initialState = {
  locationId: null,
  userId: null,
};

const EditUserLocation = ({ selectedOrgId, match }) => {
  const [alert, setAlert] = useState({ alertMessage: '', showAlert: false });
  const [users, setUsers] = useState([]);
  const [isEditing, setEditing] = useState(false);
  const [userLocationData, selectedField] = useState(initialState);
  const [locations, setLocation] = useState([]);
  const [initial, setInitial] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let response;
    setLoading(true);
    try {
      const locationResponse = isEditing
        ? await api.editUserLocation({
            organizationId: selectedOrgId,
            locationId: userLocationData.locationId,
            userId: userLocationData.userId,
            userLocationId: get(match, 'params.id'),
          })
        : await api.createUserLocation({
            organizationId: selectedOrgId,
            userId: userLocationData.userId,
            locationId: userLocationData.locationId,
          });
      if (locationResponse.success) {
        setAlert({
          alertMessage: isEditing ? locationResponse.data : 'User location created successfully',
          showAlert: true,
        });
      } else {
        response =
          'Unfortunately, we ran into a problem while processing this request - please contact the administrator';
      }
    } catch (exception) {
      response = exception.errors;
    } finally {
      setLoading(false);
    }

    if (response) {
      setAlert({ alertMessage: response.data || response, showAlert: true });
    }
    return response;
  };

  const handleState = (name, e) => {
    selectedField({
      ...userLocationData,
      [name]: e.value,
    });
  };

  const getUserLocationById = useCallback(async () => {
    let response;
    setLoading(true);
    try {
      const locationResponse = await api.getUserLocationById({
        organizationId: selectedOrgId,
        userLocationId: get(match, 'params.id'),
      });
      if (locationResponse.success) {
        const data = {
          locationId: locationResponse.data.LocationId,
          userId: locationResponse.data.userId,
        };
        selectedField(data);
        setInitial(data);
      } else {
        response =
          'Unfortunately, we ran into a problem while processing this request - please contact the administrator';
      }
    } catch (exception) {
      response = exception.errors;
    } finally {
      setLoading(false);
    }

    if (response) {
      setAlert({ alertMessage: response.data || response, showAlert: true });
    }
    return response;
  }, [selectedOrgId, match]);

  const getUsers = useCallback(async () => {
    let response;
    let updateUser;
    setLoading(true);
    try {
      const getUsersResponse = await api.getOrganizationAdmins(selectedOrgId);

      if (getUsersResponse.success) {
        updateUser = getUsersResponse.data.map((val, index) => ({
          index: index + 1,
          id: val.id,
          user: `${val.email} (${val.firstName} ${val.lastName})`,
        }));
        setUsers(updateUser);
      } else {
        response =
          'Unfortunately, we ran into a problem while processing this request - please contact the administrator';
      }
    } catch (exception) {
      response = exception.errors;
    } finally {
      setLoading(false);
    }

    if (response) {
      setAlert(response);
    }
  }, [selectedOrgId]);

  const getLocations = useCallback(async () => {
    setLoading(true);
    try {
      const campaignsResponse = await api.getLocations(selectedOrgId);
      if (campaignsResponse.success) {
        const locationData = campaignsResponse.data;
        if (locationData && Array.isArray(locationData)) {
          setLocation(locationData);
        }
      }
    } catch (exception) {
      setAlert(`Error: ${exception.errors}\nThere was an error retrieving the campaigns for this organization.`);
    } finally {
      setLoading(false);
    }
  }, [selectedOrgId]);

  useEffect(() => {
    getUsers();
    getLocations();
    if (get(match, 'params.id')) {
      getUserLocationById();
      setEditing(true);
    }
  }, [getUsers, getUserLocationById, match, getLocations]);

  const formatUsers = () => {
    let formattedUsers = [];
    if (users && users.length) {
      formattedUsers = users.map((campaign) =>
        (({ id, user }) => ({
          value: id,
          label: user,
        }))(campaign),
      );
    }
    return formattedUsers;
  };

  const formatLocations = () => {
    let formattedLocations = [];
    if (locations && locations.length) {
      formattedLocations = locations.map((location) =>
        (({ id, stateCode, cityName, customLocation }) => ({
          value: id,
          label: (stateCode && `${stateCode}, ${cityName}`) || customLocation,
        }))(location),
      );
    }
    return formattedLocations;
  };

  const isFormValid = () => {
    if (!isEqual(initial, userLocationData)) {
      if (userLocationData.locationId && userLocationData.userId) return false;
    }
    return true;
  };

  return (
    <div>
      <ClassyAlert
        show={alert.showAlert}
        alertMessage={alert.alertMessage}
        onHide={() => setAlert({ alertMessage: '', showAlert: false })}
      />

      <h2 className="title-text">{isEditing ? 'Edit' : 'Create'} User Location</h2>

      <form onSubmit={handleSubmit}>
        <fieldset disabled={loading ? 'disabled' : null}>
          <Row className="blog">
            <Col xs={12}>
              <FormGroup controlId="state" validationState={validators.getValidStateId(userLocationData.userId)}>
                <ControlLabel>
                  User<sup className="required-marker">*</sup>
                </ControlLabel>
                <FormControl componentClass="react-select" name="campaignId">
                  <Select
                    isDisabled={loading}
                    menuPortalTarget={document.body}
                    value={formatUsers().filter((user) => user.value === userLocationData.userId)}
                    onChange={(e) => handleState('userId', e)}
                    options={formatUsers()}
                  />
                </FormControl>
              </FormGroup>

              <FormGroup
                controlId="customLocation"
                validationState={validators.getValidStateId(userLocationData.locationId)}
              >
                <ControlLabel>
                  Location <sup className="required-marker">*</sup>
                </ControlLabel>
                <FormControl componentClass="react-select" name="customLocation">
                  <Select
                    isDisabled={loading}
                    menuPortalTarget={document.body}
                    value={formatLocations().filter((location) => location.value === userLocationData.locationId)}
                    onChange={(e) => handleState('locationId', e)}
                    options={formatLocations()}
                  />
                </FormControl>
              </FormGroup>
            </Col>
          </Row>

          <ClassyButton title="Submit" type="submit" disabled={isFormValid()} />
        </fieldset>
      </form>
    </div>
  );
};

EditUserLocation.propTypes = {
  selectedOrgId: PropTypes.number,
  setCampaigns: PropTypes.func,
  campaigns: PropTypes.array,
  match: PropTypes.object,
};

const mapStateToProps = (state) => {
  const {
    selectedOrganization: { id: selectedOrgId },
  } = state.login;
  const { campaigns } = state.offlineDonations;

  return {
    selectedOrgId,
    campaigns,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { setCampaigns } = OfflineDonationActions;
  return {
    setCampaigns: (campaigns) => dispatch(setCampaigns(campaigns)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserLocation);
