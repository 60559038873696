import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import api from '../../../Services/Api';
import history from '../../../Router/history';
import constants from '../../../Helpers/constants';
import utilities from '../../../Helpers/utilities';
import ClassyAlert from '../../ClassyAlert/ClassyAlert';
import ClassyButton from '../../ClassyButton/ClassyButton';
import ClassyTable from '../../ClassyTable/ClassyTable';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMessage: '',
      showAlert: false,
      users: [],
    };

    this.renderUsersTable = this.renderUsersTable.bind(this);
    this.onClickEditUser = this.onClickEditUser.bind(this);
    this.setAlert = this.setAlert.bind(this);
  }

  async componentDidMount() {
    await this.getUsers();
  }

  setAlert(alertMessage, showAlert = true) {
    this.setState({ alertMessage, showAlert });
  }

  async getUsers() {
    let response;
    let updateUser;
    try {
      const getUsersResponse = await api.getUsers();
      if (getUsersResponse.success) {
        updateUser = getUsersResponse.data.map((val, index) => {
          const role = val['Role.name'];
          const createdBy = val['CreatedByUser.email'];
          const updatedBy = val['UpdatedByUser.email'];
          const createdAt = val.createdAt
            ? moment.utc(val.createdAt).local().format(constants.DATES.TABLE_DATE_FORMAT)
            : null;
          const updatedAt = val.updatedAt
            ? moment.utc(val.updatedAt).local().format(constants.DATES.TABLE_DATE_FORMAT)
            : null;
          return {
            index: index + 1,
            id: val.id,
            firstName: val.firstName,
            lastName: val.lastName,
            email: val.email,
            userStatus: val.userStatus,
            createdBy,
            updatedBy,
            role,
            createdAt,
            updatedAt,
          };
        });
        this.setState({ users: updateUser });
      } else {
        response =
          'Unfortunately, we ran into a problem while processing this request - please contact the administrator';
      }
    } catch (exception) {
      response = exception.errors;
    }
    if (response) {
      this.setAlert(response);
    }
  }

  filterUsersData() {
    let filteredData = [];
    if (this.state.users) {
      filteredData = this.state.users;
    }
    return filteredData;
  }

  onClickEditUser(selectedUserId) {
    history.push({ pathname: `/admin/users/${selectedUserId}/edit` });
  }

  renderUsersTable() {
    const { users } = this.state;
    const columns = [
      {
        width: 50,
        Cell: (row) => (
          <button className="btn-link" onClick={() => this.onClickEditUser(row.row.id)}>
            Edit
          </button>
        ),
      },
      {
        Header: 'ID',
        accessor: 'id',
        width: 50,
      },
      {
        Header: 'Email',
        accessor: 'email',
        filterable: true,
        filterMethod: utilities.filterStringHelper,
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
        filterable: true,
        filterMethod: utilities.filterStringHelper,
      },
      {
        Header: 'Last Name ',
        accessor: 'lastName',
        filterable: true,
        filterMethod: utilities.filterStringHelper,
      },
      {
        Header: 'Role',
        accessor: 'role',
        width: 100,
      },
      {
        Header: 'Status',
        accessor: 'userStatus',
        width: 100,
      },
      {
        Header: 'Created By',
        accessor: 'createdBy',
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: (row) => (row.value ? moment.utc(row.value).local().format(constants.DATES.TABLE_DATE_FORMAT) : null),
      },
      {
        Header: 'Updated By',
        accessor: 'updatedBy',
      },
      {
        Header: 'Updated At',
        accessor: 'updatedAt',
        Cell: (row) => (row.value ? moment.utc(row.value).local().format(constants.DATES.TABLE_DATE_FORMAT) : null),
      },
    ];

    return (
      <div>
        <ClassyTable
          showWhenEmpty
          data={users || []}
          columns={columns || []}
          exportFilename={'Users'}
          formattedExportData={this.filterUsersData()}
          HeaderLeftComponent={
            <LinkContainer to={constants.ROUTES.ADMIN_CREATE_USERS}>
              <ClassyButton title="Create User" />
            </LinkContainer>
          }
          defaultPageSize={15}
          pageSizeOptions={[15, 25, 50, 100]}
        />
        {users && !users.length ? <span>No users found.</span> : null}
      </div>
    );
  }

  render() {
    return (
      <div>
        <ClassyAlert
          show={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />
        <h2 className="title-text">Users</h2>
        {this.renderUsersTable()}
      </div>
    );
  }
}

Users.propTypes = {
  organizations: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { organizations } = state.login;

  return {
    organizations,
  };
};

export default connect(mapStateToProps)(Users);
