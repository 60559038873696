import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { BaseProgramDesignation, RlcParentDesignation } from '../../Services/Rlc/RlcParentDesignation';
import api, { Pagination } from '../../Services/Api';
import DesignationList, { DesignationFilter } from './DesignationList';
import useSWR, { useSWRConfig } from 'swr';
import useDebouncedState from '../../Helpers/debounceHook';
import EditDesignationModal from './EditDesignation';
import { APIPaginatedResponse } from '../../Typings/Services/Api';

interface DesignationFetcherKey {
  url: string;
  filter: DesignationFilter;
  page: number;
}

const createDesignationSearchUrl = (url: string, filter: DesignationFilter) => {
  const newUrl = url;
  const searchParams = new URLSearchParams();
  searchParams.append('page', filter.currentPage.toString());
  searchParams.append('pageSize', filter.pageSize.toString());
  filter.sortBy.forEach((s) => {
    searchParams.append('sortBy', `${s.id}${s.desc ? ',desc' : ''}`);
  });
  filter.filterBy.forEach((s) => {
    searchParams.append(s.id, s.value);
  });
  return `${newUrl}?${searchParams.toString()}`;
};

const fetcher = ({ url, filter }: DesignationFetcherKey) =>
  url ? api.api.get(createDesignationSearchUrl(url, filter)).then((res) => res) : null;

/**
 * TODO
 * https://docs.google.com/document/d/10Szf7lfry5V_HMhB46KXmUOZNU54a4HevV-vuQdPH9Y/edit#heading=h.rfpon355nvj6
 *
 * - Editor
 * - add User's classyDesignationId to the columns
 * - Can search by :
 *     Classy Designation ID
 */

const defaultFilter: DesignationFilter = {
  filterBy: [],
  sortBy: [],
  currentPage: 0,
  pageSize: 20,
};

const createNewDesignation = (): BaseProgramDesignation => ({
  isPublic: true,
  pdName: '',
  pdDescription: '',
  pdExternalReferenceId: '',
  pdGoal: null,
  pdPostalCode: '',
  pdStartDate: null,
  pdEndDate: null,
  pdCity: '',
  pdState: '',
  pdIsActive: true,
  pdIsComplete: false,
});

function filterBaseDesignation(designation: RlcParentDesignation): BaseProgramDesignation {
  const { id, rlcGroupId, createdBy, updatedBy, createdAt, updatedAt, ...rest } = designation;
  return rest;
}

const getConfirmationMessage = (orgCount: number, isAdd: boolean) =>
  'Are you sure that you would like to proceed? ' +
  `This will ${isAdd ? 'add' : 'modify'} this designation ${isAdd ? 'to' : 'in'} ${orgCount} ` +
  ` Salvation Army account${orgCount !== 1 ? 's' : ''}.`;

const Designations = () => {
  const userRlcGroup = useSelector<any, number | undefined>((state) => state.login?.user?.rlcGroupId);

  const [filter, setFilter] = useDebouncedState<DesignationFilter>(defaultFilter);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const [selectedBaseDesignation, setSelectedBaseDesignation] = useState<BaseProgramDesignation | null>(null);
  const [selectedDesignationId, setSelectedDesignationId] = useState<number | null>(null);
  const [orgCount, setOrgCount] = useState<number>(0);
  const [confirmationMessage, setConfirmationMessage] = useState<string>('');
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalError, setModalError] = useState<string | undefined>(undefined);
  const [isInFlight, setIsInFlight] = useState<boolean>(false);
  const { mutate } = useSWRConfig();

  const { error: swrError, data: swrData /* isValidating */ } = useSWR<APIPaginatedResponse<RlcParentDesignation>>(
    { url: userRlcGroup ? `${api.apiUrl}rlc/${userRlcGroup}/designations/` : null, filter },
    fetcher as any,
  );

  const [designations, setDesignations] = useState<Array<RlcParentDesignation>>([]);

  const onFilterChanged = useCallback(
    (newFilter: DesignationFilter) => {
      setFilter(newFilter);
    },
    [setFilter],
  );

  useEffect(() => {
    setModalError(swrError);
  }, [swrError]);

  const onAddDesignationClicked = useCallback(() => {
    setModalTitle('Add Designation');
    setConfirmationMessage(getConfirmationMessage(orgCount, true));
    setSelectedDesignationId(null);
    setSelectedBaseDesignation(createNewDesignation());
  }, [orgCount]);

  const onEditDesignationClicked = useCallback(
    (designation: RlcParentDesignation) => {
      setModalTitle('Edit Designation');
      setConfirmationMessage(getConfirmationMessage(orgCount, false));
      const baseDesignation = filterBaseDesignation(designation);
      setSelectedDesignationId(designation.id);
      setSelectedBaseDesignation(baseDesignation);
    },
    [orgCount],
  );

  const onEditCancelled = useCallback(() => {
    setSelectedDesignationId(null);
    setModalError(undefined);
    setSelectedBaseDesignation(null);
  }, []);

  const onSaveDesignationRequested = useCallback(
    async (designation: BaseProgramDesignation) => {
      if (!userRlcGroup) {
        return;
      }

      setIsInFlight(true);
      setModalError(undefined);
      const controller = new AbortController();

      const createOrUpdateDesignationRequest = {
        ...(selectedDesignationId ? { id: selectedDesignationId } : null),
        ...designation,
        rlcGroupId: userRlcGroup,
      };
      try {
        await api.createOrUpdateRlcParentDesignation(createOrUpdateDesignationRequest, {
          signal: controller.signal,
        });
        setSelectedBaseDesignation(null);
        // https://swr.vercel.app/docs/mutation
        await mutate({ url: `${api.apiUrl}rlc/${userRlcGroup}/designations/`, filter });
      } catch (e) {
        const error = e as any;
        let errorMessage = error.message;
        if (error?.errors) {
          errorMessage = error.errors.join(', ');
        }
        setModalError(errorMessage);
      } finally {
        setIsInFlight(false);
      }
      return () => {
        controller.abort();
      };
    },
    [userRlcGroup, filter, mutate, selectedDesignationId],
  );

  useEffect(() => {
    if (!userRlcGroup) {
      return;
    }

    const controller = new AbortController();
    api
      .getRlcOrganizations(userRlcGroup, { signal: controller.signal })
      .then((orgResponse) => {
        if (orgResponse.success) {
          setOrgCount(orgResponse.data.length);
        } else {
          setModalError(orgResponse.errors.join(', '));
          console.error(orgResponse.errors);
        }
      })
      .catch((e) => {
        console.error('== api.getRlcOrganizations == ');
        console.error(e);
        setModalError(e);
      });
    return () => {
      controller.abort();
    };
  }, [userRlcGroup]);

  useEffect(() => {
    try {
      if (swrData && swrData.success) {
        const { data, pagination } = swrData;
        setPagination(pagination);
        setFilter((prevState: DesignationFilter) => ({
          ...prevState,
          pageSize: filter.pageSize,
          currentPage: filter.currentPage,
          sortBy: filter.sortBy,
          filterBy: filter.filterBy,
        }));
        setDesignations(data ?? []);
      }
    } catch (error) {
      console.error('== error while retrieving designations');
      const e = error as Error;
      setModalError(e.message);
    }
    // eslint-disable-next-line
  }, [swrData]);
  if (!userRlcGroup) {
    return <div className="rlcDesignations__error_text">There is no RLC group set for this user.</div>;
  }

  return (
    <div>
      {selectedBaseDesignation && (
        <EditDesignationModal
          designation={selectedBaseDesignation}
          isOpen={!!selectedBaseDesignation}
          onSave={onSaveDesignationRequested}
          onCancel={() => onEditCancelled()}
          confirmationMessage={confirmationMessage}
          error={modalError}
          title={modalTitle}
          isInFlight={isInFlight}
        />
      )}
      <h2 className="title-text">Program Designations File</h2>
      <DesignationList
        designations={designations}
        onFilterChanged={onFilterChanged}
        pageCount={pagination?.total.pages || 0}
        onAddDesignationClicked={() => onAddDesignationClicked()}
        onEditDesignationClicked={onEditDesignationClicked}
      />
    </div>
  );
};

export default Designations;
