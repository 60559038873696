import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';

import history from '../../../Router/history';
import ClassyAlert from '../../ClassyAlert/ClassyAlert';
import ClassyTable from '../../ClassyTable/ClassyTable';
import api from '../../../Services/Api';
import constants from '../../../Helpers/constants';
import Throbber from '../../Throbber/Throbber';
import './styles.scss';

const {
  MESSAGES: { ERROR_DEFAULT },
} = constants;

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMessage: '',
      showAlert: false,
      reports: [],
      isLoading: false,
    };
  }

  componentDidUpdate(prevprops) {
    const { selectedOrgId } = this.props;
    if (prevprops.selectedOrgId !== selectedOrgId) {
      this.getReports();
    }
  }

  componentDidMount() {
    this.getReports();
  }

  getReports = async () => {
    const { selectedOrgId } = this.props;

    this.setState({ isLoading: true });

    try {
      const reports = await api.getScheduledReports({
        organizationId: selectedOrgId,
      });

      if (reports.success) {
        const { data } = reports;
        this.setState({ reports: data });
      } else {
        this.setAlert(ERROR_DEFAULT);
      }
    } catch (e) {
      this.setAlert(e.errors);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  setAlert = (alertMessage, showAlert = true) => {
    this.setState({ alertMessage, showAlert });
  };

  onClickEditReport = (selectedReportId) => {
    history.push({ pathname: `/scheduled-reports/${selectedReportId}/edit` });
  };

  onDeleteReport = async (selectedReportId) => {
    const { selectedOrgId } = this.props;

    const confirmDelete = () =>
      typeof window !== 'undefined' && window.confirm('Are you sure you want to delete report?');

    if (confirmDelete()) {
      this.setState({ isLoading: true });

      try {
        const report = await api.deleteScheduledReport({
          organizationId: selectedOrgId,
          reportId: selectedReportId,
        });
        if (report.success) {
          this.setAlert(report.data);
          this.getReports();
        } else {
          this.setAlert(ERROR_DEFAULT);
        }
      } catch (e) {
        this.setAlert(e.errors);
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };

  renderReportsTable() {
    const { reports } = this.state;
    const columns = [
      {
        Header: 'Row #',
        Cell: (row) => <div id="rowIndex">{typeof row.index !== 'undefined' ? row.index + 1 : null}</div>,
        width: 75,
      },
      {
        Header: 'File Name Prefix',
        accessor: 'fileNamePrefix',
      },
      {
        Header: 'Report frequency',
        accessor: 'ScheduledReportsTimeRange.ScheduledReportsFrequency.frequencyFriendlyName',
      },
      {
        Header: 'Time Range',
        accessor: 'ScheduledReportsTimeRange.timeRangeFriendlyName',
      },
      {
        Header: 'Report Generation Start Date',
        accessor: 'reportStartDate',
        Cell: (row) => (row.value ? moment.utc(row.value).local().format(constants.DATES.DATE_FORMAT) : '-'),
        width: 300,
      },
      {
        width: 50,
        Cell: (row) => (
          <button className="btn-link" onClick={() => this.onClickEditReport(get(row, 'row._original.id'))}>
            Edit
          </button>
        ),
      },
      {
        width: 100,
        Cell: (row) => (
          <button className="text-danger btn-link" onClick={() => this.onDeleteReport(get(row, 'row._original.id'))}>
            Delete
          </button>
        ),
      },
    ];

    return (
      <div>
        <ClassyTable
          showWhenEmpty
          data={reports || []}
          columns={columns || []}
          exportFilename={'Reports'}
          defaultPageSize={15}
          pageSizeOptions={[15, 25, 50, 100]}
        />
        {reports && !reports.length ? <span>No reports found.</span> : null}
      </div>
    );
  }

  renderThrobber = (message) => (
    <div>
      <div className="backdrop" />
      <div className="loader">
        <Throbber loading={true} />
        {message ? <span> {message} </span> : null}
      </div>
    </div>
  );

  render() {
    const { showAlert, alertMessage, isLoading } = this.state;
    return (
      <div>
        <ClassyAlert
          show={showAlert}
          alertMessage={alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />
        <h2 className="title-text">Scheduled Reports</h2>

        {this.renderReportsTable()}

        {isLoading && this.renderThrobber()}
      </div>
    );
  }
}

Reports.propTypes = {
  selectedOrgId: PropTypes.number,
};

const mapStateToProps = (state) => {
  const {
    selectedOrganization: { id: selectedOrgId },
  } = state.login;

  return {
    selectedOrgId,
  };
};

export default connect(mapStateToProps, null)(Reports);
