import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';

class ClassyTableCalendar extends Component {
  render() {
    const { dateFormat, maxDate, minDate, onChange, placeholder, row, selected } = this.props;

    return (
      <div>
        <DatePicker
          row={row}
          dateFormat={dateFormat}
          selected={selected ? moment(selected, dateFormat) : undefined}
          onChange={(value) => {
            const formattedValue = moment(value).format(dateFormat);
            onChange(row, formattedValue);
          }}
          onChangeRaw={(e) => e.preventDefault()}
          className="form-control"
          value={selected}
          placeholderText={placeholder}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
    );
  }
}

ClassyTableCalendar.propTypes = {
  dateFormat: PropTypes.string,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  row: PropTypes.object.isRequired,
  selected: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default ClassyTableCalendar;
