/* eslint react/prop-types: 0 */

import React from 'react';
import { Checkbox, FormControl, InputGroup } from 'react-bootstrap';
import Select from 'react-select';

import './style.scss';

const SelectFiled = ({ options, handleChange, questionId, type, value }) => (
  <FormControl componentClass="react-select" name="currencyType">
    <Select
      menuPortalTarget={document.body}
      options={options.map((option) => ({
        value: option.question_id,
        label: option.label,
      }))}
      onChange={(e) => handleChange(e, type, questionId)}
      value={value && { value, label: value }}
    />
  </FormControl>
);

const RadioButton = ({ handleChange, value, questionId }) => (
  <div>
    <span className="radio-label">
      <input
        className="radio-input"
        type="radio"
        name={questionId}
        value="yes"
        checked={value === 'yes'}
        onChange={handleChange}
      />
      Yes
    </span>
    <span className="radio-label">
      <input
        className="radio-input"
        name={questionId}
        type="radio"
        value="no"
        checked={value === 'no'}
        onChange={handleChange}
      />
      No
    </span>
  </div>
);

const DateField = ({ handleChange, questionId }) => (
  <FormControl type="date" name={questionId} onChange={handleChange} />
);

const CurrencyField = ({ handleChange, questionId }) => (
  <InputGroup>
    <InputGroup.Addon>$</InputGroup.Addon>
    <FormControl type="number" name={questionId} onChange={handleChange} required />
  </InputGroup>
);

const NumberField = ({ handleChange, questionId }) => (
  <FormControl type="number" name={questionId} onChange={handleChange} />
);

const MultiLine = ({ handleChange, questionId }) => (
  <FormControl
    bsSize="large"
    rows="3"
    name={questionId}
    componentClass="textarea"
    maxLength={255}
    onChange={handleChange}
  />
);

const Check = ({ handleChange, questionId, type }) => (
  <Checkbox name={questionId} onChange={(e) => handleChange(e, type)}>
    I Accept the Waiver Agreement.
  </Checkbox>
);

const TextField = ({ handleChange, questionId, maxLength, placeholder, value, tag }) => (
  <FormControl
    placeholder={placeholder}
    type="text"
    maxLength={maxLength}
    value={value}
    name={questionId}
    onChange={(e) => handleChange(e, null, null, ['cellphone', 'homephone'].includes(tag))}
  />
);

const DynamicInput = (props) => {
  const maxLength = ['cellphone', 'homephone'].includes(props.tag) ? props.phoneLength : 255;
  const placeholder = ['cellphone', 'homephone'].includes(props.tag) ? '619-123-4567' : '';
  const field = {
    yes_no: <RadioButton {...props} />,
    string: <TextField {...props} maxLength={maxLength} placeholder={placeholder} />,
    currency: <CurrencyField {...props} />,
    integer: <NumberField {...props} />,
    date: <DateField {...props} />,
    enum: <SelectFiled {...props} />,
    text: <MultiLine {...props} />,
    boolean: <Check {...props} />,
  };
  return field[props.type] || null;
};

export default DynamicInput;
