import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormGroup } from 'react-bootstrap';
import constants from '../../Helpers/constants';
import api from '../../Services/Api';
import './TokenexIframe.scss';

export default class TokenexIframe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      isDirty: false,
      isValid: null,
    };
    this.isProcessingToken = false;
    this.invalidated = false;
    // Make the token request available through a ref on the component so that
    // we can call request the token from the parent form.
    const { registerFormSubmitHandler, registerFormResetHandler } = props;

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormReset = this.handleFormReset.bind(this);
    this.handleOnTokenize = this.handleOnTokenize.bind(this);

    // we need to pass a handler to parent component so when form
    // is submitted, we can call tokenize.
    registerFormSubmitHandler(this.handleFormSubmit);
    registerFormResetHandler(this.handleFormReset);
  }

  async componentDidMount() {
    const response = await this.getTokenexConfig();
    this.loadTokenExSource(() => {
      this.TokenEx = window.TokenEx;
      this.createIframe(response.data);
    });
  }

  componentWillUnmount() {
    this.invalidated = true;

    if (this.iframe) {
      this.iframe.remove();
      this.setState({ isValid: null, isDirty: false });
    }
  }

  handleFormSubmit() {
    if (this.iframe && !this.state.loading && !this.isProcessingToken) {
      this.isProcessingToken = true;
      this.props.changeSubmitStatus?.(true);
      this.iframe.tokenize();
    }
  }

  handleFormReset() {
    if (this.iframe) {
      this.iframe.reset();
      this.setState({ isValid: null, isDirty: false });
    }
  }

  handleOnTokenize(data) {
    if (this && !this.invalidated) {
      this.props.onToken(data);
      this.isProcessingToken = false;
    }
  }

  loadTokenExSource = (callback) => {
    const existingScript = document.getElementById('tokenExSource');

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = process.env.REACT_APP_TOKENEX_IFRAME_SOURCE;
      script.id = 'tokenExSource';
      script.onload = () => {
        callback();
      };
      document.body.appendChild(script);
    } else {
      callback();
    }
  };

  async getTokenexConfig() {
    const { iframeType, organizationId, placeholder = '' } = this.props;
    const { data: config } = await api.getTokenExConfiguration({
      iframeType,
      organizationId,
    });
    config.enableValidateOnBlur = true;

    // Add styles - must be passed in as inline styles
    config.styles = {
      base: 'box-sizing: border-box;-moz-box-sizing: border-box; font-family: inherit,sans-serif; border-radius: 4px; border: 1px solid #CCC; margin: 0; width: 100%; font-size: 14px; padding: 9px 10px; color: #555;',
      focus: 'box-shadow: 0 0 6px 0 rgba(0, 132, 255, 0.5);border: 1px solid rgba(0, 132, 255, 0.5);outline: 0;',
      error: 'box-shadow: 0 0 6px 0 rgba(224, 57, 57, 0.5);border: 1px solid rgba(224, 57, 57, 0.5);',
    };

    config.placeholder = placeholder;

    if (iframeType === constants.PAYMENT_METHODS.CC) {
      config.enablePrettyFormat = true;
      config.enableValidateOnBlur = true;
      config.allowUnknownCardTypes = false;
    }

    return config;
  }

  getContainerId() {
    const { iframeType } = this.props;
    return `tokenexIframe__${iframeType.toLowerCase()}`;
  }

  async createIframe() {
    const config = await this.getTokenexConfig();
    const { onValid, iframeType } = this.props;
    const containerId = this.getContainerId();

    this.iframe = new this.TokenEx.Iframe(containerId, config);

    // Add tokenize event listener
    this.iframe.on('tokenize', this.handleOnTokenize);

    // Add validate event listener
    this.iframe.on('validate', (validation) => {
      this.setState({ isValid: validation.isValid });
      onValid(validation);
    });

    // The validate event is only fired when the field is blurred so invalidating the field on focus
    // is necessary to prevent a user from modifying a validated field and immediately trying to submit the form
    this.iframe.on('focus', () => {
      this.setState({ isValid: false });
      onValid(false);
    });

    this.iframe.on('change', () => {
      this.setState({ isDirty: true });
    });

    // Add error event listener
    this.iframe.on('error', (data) => alert(`${iframeType} iFrame error: ${JSON.stringify(data)}`));

    // Add Load listener
    this.iframe.on('load', () => this.setState({ loading: false }));

    // Load iframe onto DOM
    this.iframe.load();
  }

  render() {
    const { iframeType } = this.props;
    const { isDirty, loading } = this.state;
    const containerId = this.getContainerId();
    return (
      <FormGroup
        className={`virtualTerminal__iframe ${loading ? 'loading' : ''}`}
        controlId={`virtualTerminal__${iframeType}`}
        validationState={
          this.state.isValid && this.state.isDirty
            ? constants.VALIDATION_STATES.SUCCESS
            : constants.VALIDATION_STATES.ERROR
        }
      >
        <div id={containerId} />
        {isDirty ? <FormControl.Feedback /> : ''}
      </FormGroup>
    );
  }
}

TokenexIframe.propTypes = {
  iframeType: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  organizationId: PropTypes.number.isRequired,
  onValid: PropTypes.func.isRequired,
  onToken: PropTypes.func.isRequired,
  registerFormSubmitHandler: PropTypes.func.isRequired,
  registerFormResetHandler: PropTypes.func.isRequired,
  changeSubmitStatus: PropTypes.func,
};
