import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

// configures the store
// eslint-disable-next-line import/no-anonymous-default-export
export default (rootReducer) => {
  /* ------------- Redux Configuration ------------- */
  const middleware = [];
  const enhancers = [];

  /* ------------- Middleware ------------- */

  /* ------------- Assemble Middleware ------------- */
  enhancers.push(composeWithDevTools(applyMiddleware(...middleware)));

  const store = createStore(rootReducer, compose(...enhancers));

  return {
    store,
  };
};
