import { useCallback, useEffect, useMemo, useState } from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

import api from '../../../Services/Api';
import ClassyButton from '../../ClassyButton/ClassyButton';
import validators from '../../../Helpers/validators';
import constants from '../../../Helpers/constants';

const parseSettings = (payload) => ({
  minDonationAllowed: payload?.minDonationAllowed || '',
  maxDonationAllowed: payload?.maxDonationAllowed || '',
});

const Settings = () => {
  const [originalSettings, setOriginalSettings] = useState({});
  const [settings, setSettings] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await api.getSettings();
        if (result?.data) {
          const newSettings = parseSettings(result.data);
          setSettings(newSettings);
          setOriginalSettings(newSettings);
        }
      } catch {}
    };

    fetch();
  }, []);

  const isSubmitButtonDisabled = useMemo(() => {
    if (isSaving) {
      return true;
    }

    if (
      Number(settings.minDonationAllowed) === Number(originalSettings.minDonationAllowed) &&
      Number(settings.maxDonationAllowed) === Number(originalSettings.maxDonationAllowed)
    ) {
      return true;
    }

    return (
      validators.getIsValidMinDonationAllowed(settings.minDonationAllowed) !== constants.VALIDATION_STATES.SUCCESS ||
      validators.getIsValidMaxDonationAllowed(settings.minDonationAllowed, settings.maxDonationAllowed) !==
        constants.VALIDATION_STATES.SUCCESS
    );
  }, [isSaving, settings, originalSettings]);

  const handleInputChange = useCallback((event) => {
    event.persist();

    setSettings((current) => ({
      ...current,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      event?.preventDefault?.();

      setIsSaving(true);
      try {
        const result = await api.updateSettings(settings);
        if (result?.data) {
          const newSettings = parseSettings(result.data);
          setSettings(newSettings);
          setOriginalSettings(newSettings);
        }

        setIsSaving(false);
      } catch {
        setIsSaving(false);
      }
    },
    [settings],
  );

  return (
    <div>
      <h2 className="title-text">Settings</h2>
      <p>System wide settings</p>
      <form>
        <FormGroup
          controlId="minDonationAllowed"
          validationState={validators.getIsValidMinDonationAllowed(settings?.minDonationAllowed)}
        >
          <ControlLabel>Minimum Donation Allowed</ControlLabel>
          <FormControl
            name="minDonationAllowed"
            type="text"
            value={settings?.minDonationAllowed?.toString() || ''}
            placeholder="5"
            onChange={handleInputChange}
          />
          <FormControl.Feedback />
        </FormGroup>

        <FormGroup
          controlId="maxDonationAllowed"
          validationState={validators.getIsValidMaxDonationAllowed(
            settings?.minDonationAllowed,
            settings?.maxDonationAllowed,
          )}
        >
          <ControlLabel>Maximum Donation Allowed</ControlLabel>
          <FormControl
            name="maxDonationAllowed"
            type="text"
            value={settings?.maxDonationAllowed?.toString() || ''}
            placeholder="5000"
            onChange={handleInputChange}
          />
          <FormControl.Feedback />
        </FormGroup>
        <br />
        <ClassyButton title="Update settings" disabled={isSubmitButtonDisabled} onClick={handleSubmit} />
      </form>
    </div>
  );
};

export default Settings;
