import { HelpBlock } from 'react-bootstrap';
import React from 'react';
import { SimpleValidationResult } from '../../Helpers/validators';

type ValidationMessageProps = {
  validationError: SimpleValidationResult;
  field: string;
};

export const getValidationState = (validationError: SimpleValidationResult, field: string) =>
  validationError.hasOwnProperty(field) ? 'error' : null;

const ValidationMessage = ({ validationError, field }: ValidationMessageProps) => {
  const hasError = validationError.hasOwnProperty(field);
  return hasError ? <HelpBlock>{validationError[field]}</HelpBlock> : null;
};

export default ValidationMessage;
