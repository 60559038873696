import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import ClassyAlert from '../../ClassyAlert/ClassyAlert';
import ClassyTable from '../../ClassyTable/ClassyTable';
import api from '../../../Services/Api';
import constants from '../../../Helpers/constants';
import utilities from '../../../Helpers/utilities';
import offlineDonationActions from '../../../Redux/OfflineDonations.redux';

export class OfflineDonationsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      alertMessage: '',
    };

    this.getOfflineDonations = this.getOfflineDonations.bind(this);
    this.renderOfflineDonationsTable = this.renderOfflineDonationsTable.bind(this);
  }

  componentDidMount() {
    this.getOfflineDonations();
  }

  componentDidUpdate(prevProps) {
    const { selectedOrganization } = this.props;
    if (prevProps.selectedOrganization.id !== selectedOrganization.id) {
      this.getOfflineDonations();
    }
  }

  setAlert(alertMessage, showAlert = true) {
    this.setState({ alertMessage, showAlert });
  }

  filterOfflineDonationsData() {
    const data = this.props.getOfflineDonationsResponse.length > 0 ? this.props.getOfflineDonationsResponse : [];
    let filteredData = [];
    if (data) {
      filteredData = data.map((offlineDonation, index) => ({
        index: index + 1,
        organizationId: offlineDonation.Organization.classyOrganizationId,
        organizationName: offlineDonation.Organization.name,
        campaignId: offlineDonation.classyCampaignId,
        campaignName: offlineDonation.classyCampaignName,
        totalDonationAmount: offlineDonation.amount,
        isSuccessful: offlineDonation.isSuccessful ? 'TRUE' : 'FALSE',
        loadDateAndTime: moment.utc(offlineDonation.createdAt).local().format('YYYY-MM-DD HH:mm'),
        loadUser: offlineDonation.User.email,
      }));
    }
    return filteredData;
  }

  async getOfflineDonations() {
    const organizationId = this.props.selectedOrganization.id;
    const startDate = constants.DATES.GET_OFFLINE_DONATIONS_START_DATE;
    const endDate = constants.DATES.GET_OFFLINE_DONATIONS_END_DATE;
    let response;
    try {
      const offlineDonationsResponse = await api.getOfflineDonations({
        organizationId,
        startDate,
        endDate,
      });
      if (offlineDonationsResponse.success) {
        const offlineDonationsData = offlineDonationsResponse.data;
        this.props.getOfflineDonationsSuccess(offlineDonationsData);
      } else {
        this.props.getOfflineDonationsFailure(offlineDonationsResponse);
      }
    } catch (exception) {
      this.props.getOfflineDonationsFailure(exception);
      response = exception.errors;
    }
    if (response) {
      this.setAlert(response);
    }
  }

  renderOfflineDonationsTable() {
    const data = this.filterOfflineDonationsData();

    const columns = [
      {
        Header: 'Organization ID',
        accessor: 'organizationId',
      },
      {
        Header: 'Organization Name',
        accessor: 'organizationName',
      },
      {
        Header: 'Campaign ID',
        accessor: 'campaignId',
      },
      {
        Header: 'Campaign Name',
        accessor: 'campaignName',
      },
      {
        Header: 'Total Donation Amount',
        accessor: 'totalDonationAmount',
        sortMethod: utilities.sortCurrencyHelper,
      },
      {
        Header: 'Is Imported',
        accessor: 'isSuccessful',
      },
      {
        Header: 'Load Date and Time',
        accessor: 'loadDateAndTime',
      },
      {
        Header: 'Load User',
        accessor: 'loadUser',
      },
    ];

    return (
      <div>
        <ClassyTable
          showWhenEmpty
          data={data || []}
          columns={columns || []}
          exportFilename={'OfflineDonations'}
          formattedExportData={this.filterOfflineDonationsData()}
          defaultPageSize={15}
          pageSizeOptions={[15, 25, 50, 100]}
        />
        {data && !data.length ? (
          <span>{`No offline donations in last ${process.env.REACT_APP_OFFLINE_DONATIONS_DAYS_BACK_FOR_START_DATE} days.`}</span>
        ) : null}
      </div>
    );
  }

  render() {
    return (
      <div>
        <ClassyAlert
          show={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />
        <h2 className="title-text">Offline Donations Dashboard</h2>

        {this.renderOfflineDonationsTable()}
      </div>
    );
  }
}

OfflineDonationsDashboard.propTypes = {
  getOfflineDonationsFailure: PropTypes.func.isRequired,
  getOfflineDonationsSuccess: PropTypes.func.isRequired,
  getOfflineDonationsResponse: PropTypes.array.isRequired,
  getOfflineDonationsError: PropTypes.object.isRequired,
  selectedOrganization: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { selectedOrganization } = state.login;
  const { getOfflineDonationsError, getOfflineDonationsResponse } = state.offlineDonations;

  return {
    getOfflineDonationsError,
    getOfflineDonationsResponse,
    selectedOrganization,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { getOfflineDonationsFailure, getOfflineDonationsSuccess } = offlineDonationActions;
  return {
    getOfflineDonationsFailure: (getOfflineDonationsError) =>
      dispatch(getOfflineDonationsFailure(getOfflineDonationsError)),
    getOfflineDonationsSuccess: (getOfflineDonationsResponse) =>
      dispatch(getOfflineDonationsSuccess(getOfflineDonationsResponse)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfflineDonationsDashboard);
