import React, { useCallback, useEffect, useState } from 'react';
import { Col, ControlLabel, FormControl, FormGroup, Row } from 'react-bootstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isEqual } from 'lodash';

import validators from '../../../Helpers/validators';
import ClassyButton from '../../ClassyButton/ClassyButton';
import api from '../../../Services/Api';
import OfflineDonationActions from '../../../Redux/OfflineDonations.redux';
import ClassyAlert from '../../ClassyAlert/ClassyAlert';

const initialState = {
  location: null,
  campaign: null,
};

const EditCampaignLocation = ({ selectedOrgId, setCampaigns, campaigns, match }) => {
  const [alert, setAlert] = useState({ alertMessage: '', showAlert: false });
  const [isEditing, setEditing] = useState(false);
  const [campaignLocationData, selectedField] = useState(initialState);
  const [locations, setLocation] = useState([]);
  const [initial, setInitial] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let response;
    try {
      const locationResponse = isEditing
        ? await api.editCampaignLocation({
            organizationId: selectedOrgId,
            locationId: campaignLocationData.location,
            campaignId: campaignLocationData.campaign,
            campaignLocationId: get(match, 'params.id'),
          })
        : await api.createCampaignLocation({
            organizationId: selectedOrgId,
            campaignId: campaignLocationData.campaign,
            locationId: campaignLocationData.location,
          });
      if (locationResponse.success) {
        setAlert({
          alertMessage: isEditing ? locationResponse.data : 'Campaign location created successfully',
          showAlert: true,
        });
      } else {
        response =
          'Unfortunately, we ran into a problem while processing this request - please contact the administrator';
      }
    } catch (exception) {
      response = exception.errors;
    } finally {
      setLoading(false);
    }

    if (response) {
      setAlert({ alertMessage: response.data || response, showAlert: true });
    }
    return response;
  };

  const handleState = (name, e) => {
    selectedField({
      ...campaignLocationData,
      [name]: e.value,
    });
  };

  const getCampaignLocationById = useCallback(async () => {
    let response;
    setLoading(true);
    try {
      const locationResponse = await api.getCampaignLocationById({
        organizationId: selectedOrgId,
        campaignLocationId: get(match, 'params.id'),
      });
      if (locationResponse.success) {
        const data = {
          location: locationResponse.data.LocationId,
          campaign: locationResponse.data.campaignId,
        };
        selectedField(data);
        setInitial(data);
      } else {
        response =
          'Unfortunately, we ran into a problem while processing this request - please contact the administrator';
      }
    } catch (exception) {
      response = exception.errors;
    } finally {
      setLoading(false);
    }

    if (response) {
      setAlert({ alertMessage: response.data || response, showAlert: true });
    }
    return response;
  }, [match, selectedOrgId]);

  const getCampaigns = useCallback(async () => {
    setLoading(true);
    try {
      const campaignsResponse = await api.getCampaigns(selectedOrgId);
      if (campaignsResponse.success) {
        const campaignsData = campaignsResponse.data;
        if (campaignsData && Array.isArray(campaignsData)) {
          setCampaigns(campaignsData);
        }
      }
    } catch (exception) {
      setAlert({
        alertMessage: `Error: ${exception.errors}\nThere was an error retrieving the campaigns for this organization.`,
        showAlert: true,
      });
    } finally {
      setLoading(false);
    }
  }, [selectedOrgId, setCampaigns]);

  const getLocations = useCallback(async () => {
    try {
      const campaignsResponse = await api.getLocations(selectedOrgId);
      if (campaignsResponse.success) {
        const locationData = campaignsResponse.data;
        if (locationData && Array.isArray(locationData)) {
          setLocation(locationData);
        }
      }
    } catch (exception) {
      setAlert({
        alertMessage: `Error: ${exception.errors}\nThere was an error retrieving the campaigns for this organization.`,
        showAlert: true,
      });
    }
  }, [selectedOrgId]);

  useEffect(() => {
    getCampaigns();
    getLocations();
    if (get(match, 'params.id')) {
      getCampaignLocationById();
      setEditing(true);
    }
  }, [getCampaigns, getLocations, match, getCampaignLocationById]);

  const formatCampaigns = () => {
    let formattedCampaigns = [];
    if (campaigns && campaigns.length) {
      formattedCampaigns = campaigns.map((campaign) =>
        (({ id, name, internal_name: internalName }) => ({
          value: id,
          label: name || internalName,
        }))(campaign),
      );
    }
    return formattedCampaigns;
  };

  const formatLocations = () => {
    let formattedLocations = [];
    if (locations && locations.length) {
      formattedLocations = locations.map((location) =>
        (({ id, stateCode, cityName, customLocation }) => ({
          value: id,
          label: (stateCode && `${stateCode}, ${cityName}`) || customLocation,
        }))(location),
      );
    }
    return formattedLocations;
  };

  const isFormValid = () => {
    if (!isEqual(initial, campaignLocationData)) {
      if (campaignLocationData.location && campaignLocationData.campaign) return false;
    }
    return true;
  };

  return (
    <div>
      <ClassyAlert
        show={alert.showAlert}
        alertMessage={alert.alertMessage}
        onHide={() => setAlert({ alertMessage: '', showAlert: false })}
      />

      <h2 className="title-text">{isEditing ? 'Edit' : 'Create'} Campaign Location</h2>

      <form onSubmit={handleSubmit}>
        <fieldset disabled={loading ? 'disabled' : null}>
          <Row className="blog">
            <Col xs={12}>
              <FormGroup controlId="state" validationState={validators.getValidStateId(campaignLocationData.campaign)}>
                <ControlLabel>
                  Campaign<sup className="required-marker">*</sup>
                </ControlLabel>
                <FormControl componentClass="react-select" name="campaignId">
                  <Select
                    isDisabled={loading}
                    menuPortalTarget={document.body}
                    value={formatCampaigns().filter((campaign) => campaign.value === campaignLocationData.campaign)}
                    onChange={(e) => handleState('campaign', e)}
                    options={formatCampaigns()}
                  />
                </FormControl>
              </FormGroup>

              <FormGroup
                controlId="customLocation"
                validationState={validators.getValidStateId(campaignLocationData.location)}
              >
                <ControlLabel>
                  Location <sup className="required-marker">*</sup>
                </ControlLabel>
                <FormControl componentClass="react-select" name="customLocation">
                  <Select
                    isDisabled={loading}
                    menuPortalTarget={document.body}
                    value={formatLocations().filter((location) => location.value === campaignLocationData.location)}
                    onChange={(e) => handleState('location', e)}
                    options={formatLocations()}
                  />
                </FormControl>
              </FormGroup>
            </Col>
          </Row>

          <ClassyButton title="Submit" type="submit" disabled={isFormValid()} />
        </fieldset>
      </form>
    </div>
  );
};

EditCampaignLocation.propTypes = {
  selectedOrgId: PropTypes.number,
  setCampaigns: PropTypes.func,
  campaigns: PropTypes.array,
  match: PropTypes.object,
};

const mapStateToProps = (state) => {
  const {
    selectedOrganization: { id: selectedOrgId },
  } = state.login;
  const { campaigns } = state.offlineDonations;

  return {
    selectedOrgId,
    campaigns,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { setCampaigns } = OfflineDonationActions;
  return {
    setCampaigns: (campaigns) => dispatch(setCampaigns(campaigns)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCampaignLocation);
