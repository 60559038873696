import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import AccountList from './AccountList';
import useSWR from 'swr';
import { APIResponse } from '../../Typings/Services/Api';
import api from '../../Services/Api';
import { RlcOrganization } from '../../Services/Rlc/RlcOrganization';
import { AccountStatus, useRlcAccountStatus } from './RlcHooks';
import history from '../../Router/history';
import constants from '../../Helpers/constants';

const getRlcOrganizationsUrl = (rlcGroupId: number) => `${api.apiUrl}rlc/${rlcGroupId}/organizations`;

const fetcher = (url: string) => api.api.get(url).then((res) => res);

const AccountHome = () => {
  const userRlcGroup = useSelector<any, number | undefined>((state) => state.login?.user?.rlcGroupId);

  const handleManageCampaigns = useCallback(async (organization: RlcOrganization) => {
    history.push(
      `${constants.ROUTES.RLC_ACCOUNT_HOME}/${organization.organizationId}${constants.ROUTES.RLC_CAMPAIGNS}`,
    );
  }, []);

  const { /*error: swrError, */ data: swrData /* isValidating */ } = useSWR<APIResponse<RlcOrganization[]>>(
    userRlcGroup ? getRlcOrganizationsUrl(userRlcGroup) : null,
    fetcher as any,
  );
  const accountStatus = useRlcAccountStatus(swrData?.success ? swrData.data : null);

  if (!userRlcGroup) {
    return <div className="rlcDesignations__error_text">The RLC group is not set for this user.</div>;
  }

  if (accountStatus === AccountStatus.Loading) {
    return null;
  } else {
    return (
      <>
        <h2 className="title-text">Accounts / Campaigns</h2>
        {swrData && swrData.success && <AccountList organizations={swrData.data} onManage={handleManageCampaigns} />}
      </>
    );
  }
};

export default AccountHome;
