import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getRecurringDonationsSuccess: ['recurringDonationsResponse'],
  getRecurringDonationsFailure: ['recurringDonationsError'],
});

export const RecurringDonationTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  recurringDonationsResponse: {},
  recurringDonationsError: {},
};

/* ------------- Reducers ------------- */
const getRecurringDonationsFailure = (state, { recurringDonationsError }) => ({
  ...state,
  recurringDonationsError,
  recurringDonationsResponse: {},
});

const getRecurringDonationsSuccess = (state, { recurringDonationsResponse }) => ({
  ...state,
  recurringDonationsResponse,
  recurringDonationsError: {},
});

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_RECURRING_DONATIONS_FAILURE]: getRecurringDonationsFailure,
  [Types.GET_RECURRING_DONATIONS_SUCCESS]: getRecurringDonationsSuccess,
});
