import React, { useCallback, useMemo } from 'react';
import Select from 'react-select';
import { Col, Row } from 'react-bootstrap';

import ClassyToggle from '../../ClassyToggle/ClassyToggle';
import '../VirtualTerminal.scss';

const MODES = {
  onlyRecurring: 'onlyRecurring',
  oneTimeAndMonthly: 'oneTimeAndMonthly',
  oneTimeAndRecurring: 'oneTimeAndRecurring',
};

const MODE_TOGGLES = {
  [MODES.oneTimeAndMonthly]: { left: 'One time', right: 'Monthly' },
  [MODES.oneTimeAndRecurring]: { left: 'One time', right: 'Recurring' },
};

const ONE_TIME_TYPE = 'one-time';
const SIMPLE_MODE_FREQUENCY_TYPES = [ONE_TIME_TYPE, 'monthly'];

const CampaignFrequency = ({ frequencies, selectedFrequency, onFrequencySelected }) => {
  const enabledFrequencies = useMemo(
    () => frequencies?.filter((frequency) => frequency?.display_on_page) || [],
    [frequencies],
  );

  const mode = useMemo(() => {
    const enabledTypes = enabledFrequencies.map((frequency) => frequency.type);

    if (!enabledTypes.some((type) => type === ONE_TIME_TYPE)) {
      return MODES.onlyRecurring;
    } else if (
      enabledTypes.length === SIMPLE_MODE_FREQUENCY_TYPES.length &&
      SIMPLE_MODE_FREQUENCY_TYPES.every((simpleType) => enabledTypes.includes(simpleType))
    ) {
      return MODES.oneTimeAndMonthly;
    } else {
      return MODES.oneTimeAndRecurring;
    }
  }, [enabledFrequencies]);

  const toggleSide = useMemo(() => (selectedFrequency?.type === ONE_TIME_TYPE ? 'left' : 'right'), [selectedFrequency]);

  const recurringMenu = useMemo(
    () =>
      enabledFrequencies
        .filter((frequency) => frequency.type !== ONE_TIME_TYPE)
        .map((frequency) => ({
          value: frequency.type,
          label: frequency.name,
        })),
    [enabledFrequencies],
  );

  const recurringSelectedItem = useMemo(
    () => recurringMenu.find((item) => item.value === selectedFrequency?.type),
    [recurringMenu, selectedFrequency],
  );

  const handleLeftToggle = useCallback(() => {
    if (selectedFrequency?.type === ONE_TIME_TYPE) {
      return;
    }

    onFrequencySelected(enabledFrequencies.find((frequency) => frequency.type === ONE_TIME_TYPE));
  }, [selectedFrequency, enabledFrequencies, onFrequencySelected]);

  const handleRightToggle = useCallback(() => {
    if (selectedFrequency?.type !== ONE_TIME_TYPE) {
      return;
    }

    onFrequencySelected(enabledFrequencies.find((frequency) => frequency.type !== ONE_TIME_TYPE));
  }, [selectedFrequency, enabledFrequencies, onFrequencySelected]);

  const handleRecurringSelectChange = useCallback(
    (type) => {
      if (selectedFrequency?.type === type.value) {
        return;
      }

      onFrequencySelected(enabledFrequencies.find((frequency) => frequency.type === type.value));
    },
    [selectedFrequency, enabledFrequencies, onFrequencySelected],
  );

  if (enabledFrequencies.length === 1) {
    return (
      <div className="virtualTerminal__frequency">
        <div className="title-container">
          <span className="title">
            Campaign frequency: <span className="stroked">{selectedFrequency?.name || ''}</span>
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="virtualTerminal__frequency">
      <div className="title-container">
        <span className="title">
          Payment frequency<sup className="required-marker">*</sup>
        </span>
      </div>
      {mode !== MODES.onlyRecurring && (
        <Row className="margin-bottom-large">
          <Col xs={12}>
            <ClassyToggle
              defaultActiveSide={toggleSide}
              labelLeft={MODE_TOGGLES[mode].left}
              onClickLeft={handleLeftToggle}
              labelRight={MODE_TOGGLES[mode].right}
              onClickRight={handleRightToggle}
            />
          </Col>
        </Row>
      )}
      {mode !== MODES.oneTimeAndMonthly && selectedFrequency?.type !== ONE_TIME_TYPE && (
        <Row className="margin-bottom-large">
          <Col xs={12}>
            <Select
              menuPortalTarget={document.body}
              defaultValue={recurringSelectedItem}
              value={recurringSelectedItem}
              onChange={handleRecurringSelectChange}
              options={recurringMenu}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default CampaignFrequency;
