const moment = require('moment');

/**
 * Checks whether two arrays contain the same values, regardless of order
 * @param {Array} array1 The first array to compare
 * @param {Array} array2 The second array to compare
 * @returns {boolean} A boolean indicating whether the arrays are equal
 */
const arraysEqual = (array1, array2) => {
  let response = false;
  if (Array.isArray(array1) || Array.isArray(array2)) {
    if (array1.length === array2.length) {
      response = true;
    }

    const array1Sorted = array1.concat().sort();
    const array2Sorted = array2.concat().sort();

    for (let i = 0; i < array1Sorted.length; i += 1) {
      if (array1Sorted[i] !== array2Sorted[i]) {
        response = false;
      }
    }
  }
  return response;
};

/**
 * Specifies the sorting method for sorting currency strings
 * @param {string} a The first element to compare
 * @param {string} b The second element to compare
 * @returns {number} A number indicating a sort priority
 */
const sortCurrencyHelper = (a, b) => {
  const invalidCharacters = /[^\d.,]/;
  let amountA = (a && parseFloat(a.replace(invalidCharacters, ''))) || null;
  let amountB = (b && parseFloat(b.replace(invalidCharacters, ''))) || null;
  // force null and undefined to the bottom
  amountA = amountA === null || amountA === undefined || amountA === Number.isNaN ? -Infinity : amountA;
  amountB = amountB === null || amountB === undefined || amountA === Number.isNaN ? -Infinity : amountB;
  // Return either 1 or -1 to indicate a sort priority
  if (amountA > amountB) {
    return 1;
  }
  if (amountA < amountB) {
    return -1;
  }
  // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
  return 0;
};

/**
 * Specifies the filtering method for filtering strings in a React Table column
 * @param {object} filter The object containing details about the filter query
 * @param {object} row The React Table row object
 * @returns {boolean} A boolean value indicating a match status
 */
const filterStringHelper = (filter, row) => {
  // Make query case-insensitive
  const filterValue = filter.value.toLowerCase();
  const rowValue = row[filter.id] ? row[filter.id].toLowerCase() : null;
  if (!rowValue) {
    return false;
  }

  // Returns true if the rowValue contains the filterValue
  return rowValue.indexOf(filterValue) > -1;
};

/**
 * Takes a moment object and sets the hours, minutes, seconds, and milliseconds. Will throw exception if valid moment not passed in.
 * If moment passed in is in UTC, then uses moment in utc mode. otherwise, uses local implementation.
 * @param {Object} momentDate a moment js object
 * @param {Number} hour [default = 0] the hour to set
 * @param {Number} minute [default = 0] the minute to set
 * @param {Number} second [default = 0] the second to set
 * @param {Number} millisecond [default = 0] the millisecond to set
 * @returns {Object} A moment object
 */
const setDateTime = (momentDate, hour = 0, minute = 0, second = 0, millisecond = 0) => {
  if (!momentDate || !moment.isMoment(momentDate)) {
    throw new Error('a valid moment object is required');
  }
  let responseDate = momentDate;

  // Clone object to not modify the moment passed in.
  // Per momentjs docs, simply passing in a current moment object into their moment function will clone.
  if (momentDate.isUTC()) {
    responseDate = moment.utc(momentDate);
  } else {
    responseDate = moment(momentDate);
  }

  responseDate.set({
    hour,
    minute,
    second,
    millisecond,
  });

  return responseDate;
};

/**
 * Takes a moment object and sets it to UTC.  Will throw exception if valid moment not passed in.
 * @param {Object} momentDate a moment js object
 * @returns {Object} A moment object
 */
const setUTC = (momentDate) => {
  if (!momentDate || !moment.isMoment(momentDate)) {
    throw new Error('a valid moment object is required');
  }

  return moment.utc(momentDate);
};

// eslint-disable-next-line import/no-anonymous-default-export
module.exports = {
  arraysEqual,
  filterStringHelper,
  sortCurrencyHelper,
  setDateTime,
  setUTC,
};
