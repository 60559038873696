import { createActions, createReducer } from 'reduxsauce';

interface OrganizationIdInputState {
  organizationId: string | number;
  servicesOrganizationId: string | number;
}

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getOrganizationId: ['getOrganizationId'],
});

export default Creators;
/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  getOrganizationId: {},
  organizationId: '',
  servicesOrganizationId: '',
};

/* ------------- Reducers ------------- */

const getOrganizationId = (state: OrganizationIdInputState, { getOrganizationId }: any) => ({
  ...state,
  organizationId: '',
  servicesOrganizationId: '',
  getOrganizationId,
});

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ORGANIZATION_ID]: getOrganizationId,
});
