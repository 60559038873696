import axios, { AxiosRequestConfig } from 'axios';
import httpStatusCodes from 'http-status-codes';
import constants from '../Helpers/constants';
import validators from '../Helpers/validators';
import LoginActions from '../Redux/Login.redux';
import history from '../Router/history';
import { APIResponse } from '../Typings/Services/Api';
import { ClassyPayProcessor } from './Processors';
import { CampaignDropDown } from './Campaigns';
import { FundraisingPageDropDown } from './FundraisingPages';
import { ProgramDesignationDropDown } from './ProgramDesignations';
import { OfflineDonationBulkImportResult } from './OfflineDonations';
import { FundraisingTeamDropDown } from './FundraisingTeams';
import { withDateStringsAsDates } from '../Helpers/dateUtils';
import {
  CreateProgramDesignationRequest,
  RlcParentDesignation,
  UpdateProgramDesignationRequest,
} from './Rlc/RlcParentDesignation';
import { RlcOrganization } from './Rlc/RlcOrganization';
import { RlcGroup } from './Rlc/RlcGroup';

import { Store } from 'redux';

const { dispatch, getState } = require('../Redux/store').store;

let store: Store;

// @see {@link https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files}
export const injectStore = (_store: Store) => {
  store = _store;
};

export interface Pagination {
  page: number;
  pageSize: number;
  total: {
    pages: number;
    records: number;
  };
}

const apiUrl: string =
  process.env.REACT_APP_CLASSY_SERVICES_API_URL || 'ERROR: REACT_APP_CLASSY_SERVICES_API_URL is not defined';
const webAppUrl = typeof window !== 'undefined' && window.location.origin;

const api = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  // apiUrl, // TODO: I think this is incorrect
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const selectedOrgId = store.getState().login?.selectedOrganization?.id;

    return {
      ...config,
      headers: {
        ...config.headers,
        ...(selectedOrgId ? { 'x-organization-id': selectedOrgId } : null),
      },
    };
  },
  (error) => Promise.reject(error),
);

interface StandardizedResponse<T> {
  success: boolean;
  data: T | {}; // createStandardizedObject seems to create an empty object by default
  errors: Array<string>;
  metaData: Record<string, any>;
  pagination?: Pagination;
  statusCode: number | null;
  isCancelled: boolean;
}

const createStandardizedResponse = <T>(): StandardizedResponse<T> => ({
  success: false,
  data: {},
  errors: [],
  metaData: {},
  statusCode: null,
  isCancelled: false,
});

const xsrfTokenHeader = 'xsrf-token';

api.interceptors.response.use(
  (response) => {
    const standardizedResponse = createStandardizedResponse();
    if (response.headers[xsrfTokenHeader]) {
      api.defaults.headers.common[xsrfTokenHeader] = response.headers[xsrfTokenHeader];
    }

    if (validators.isValidResponse(response)) {
      if (response.data.status !== 'error') {
        standardizedResponse.data = withDateStringsAsDates(response.data.data);
        standardizedResponse.success = true;
        standardizedResponse.statusCode = response.status;
        if (response.data.pagination) {
          standardizedResponse.pagination = response.data.pagination;
        }
      } else {
        // A streaming response may return a status='error'
        // if it has already started streaming but then
        // encounters an error.
        standardizedResponse.success = false;
        standardizedResponse.data = response.data.data;
        standardizedResponse.errors = response.data.errors;
        return Promise.reject(standardizedResponse);
      }
    } else {
      // The server did not format the response correctly.
      console.error('Unable to process the server response');
      console.error(response);
    }
    return standardizedResponse;
  },
  (error) => {
    const standardizedError = createStandardizedResponse();
    if (error?.response?.headers?.[xsrfTokenHeader]) {
      api.defaults.headers.common[xsrfTokenHeader] = error.response.headers[xsrfTokenHeader];
    }

    if (error && error.response) {
      if (error.response.data) {
        const payload = error.response.data.data || error.response.data.payload;
        if (Array.isArray(payload)) {
          standardizedError.errors = payload;
        } else if (typeof payload === 'string') {
          standardizedError.errors.push(payload);
        } else if (error.response.data.message) {
          standardizedError.errors.push(error.response.data.message);
        }
      }
      if (validators.isUnauthorizedRequest(error.response) && !error.response.config.url.includes('auth/logout')) {
        removeUserInfo();
      }
    } else {
      if (axios.isCancel(error)) {
        standardizedError.errors.push(error.message);
        // Note: There is no status code for a cancelled request.
        standardizedError.isCancelled = true;
      } else {
        if (
          error &&
          error.toJSON() &&
          error.toJSON().message &&
          error.toJSON().message === 'Network Error' &&
          // The access issue only exists for Virtual Terminal, for
          // any other requests we'll show the gateway timeout.
          /virtual-terminal/.test(error?.config?.url)
        ) {
          standardizedError.errors.push('Network Error: You do not have access to this server.');

          standardizedError.statusCode = httpStatusCodes.FORBIDDEN;
        } else {
          standardizedError.errors.push(
            'The server did not respond in time, but your operation may have completed successfully. Please verify the status of your job before retrying.',
          );
          standardizedError.statusCode = httpStatusCodes.GATEWAY_TIMEOUT;
        }
      }
    }
    return Promise.reject(standardizedError);
  },
);

const removeUserInfo = () => {
  const { logout } = LoginActions;

  const state = getState();

  if (state?.login?.user?.id) {
    dispatch(logout());
    history.push('/');
  }
};

const authenticateUser = ({ email, password }: any) => api.post(`${apiUrl}auth/login`, { email, password });
const getUserSession = () => api.get(`auth/session`);
const logoutUser = () => api.post(`auth/logout`);
const createCompany = (company: any) => api.post(`${apiUrl}companies`, company);
const createOrganization = (organization: any) => api.post(`${apiUrl}organizations`, organization);
const createOfflineDonations = (
  offlineDonations: Array<any>,
  organizationId: number,
  axiosRequestConfig?: AxiosRequestConfig,
): Promise<APIResponse<OfflineDonationBulkImportResult>> =>
  api.post(`${apiUrl}organizations/${organizationId}/bulk-upload-offline`, offlineDonations, axiosRequestConfig);
const createUser = ({ user, url }: any) => api.post(`${apiUrl}users`, { user, url });
const createVirtualTerminalDonation = (donation: any) => api.post(`${apiUrl}donations/virtual-terminal`, donation);
const editOrganizationById = ({ organization, organizationId }: any) =>
  api.put(`${apiUrl}organizations/${organizationId}`, organization);
const editOrganizationCredentialsById = ({ organization, organizationId }: any) =>
  api.put(`${apiUrl}organizations/${organizationId}/credentials`, organization);
const editUser = (user: any, userId: any) => api.put(`${apiUrl}users/${userId}`, user);
const getHealthCheck = () => api.get('/health-check');
const getAllCompanies = () => api.get(`${apiUrl}companies`);
const getAllCredentialTypes = () => api.get(`${apiUrl}credential-types`);
const getAllFeatures = (organizationId?: number) => api.get(`${apiUrl}features/${organizationId}`);
const getAllOrganizations = (axiosRequestConfig?: AxiosRequestConfig) =>
  api.get(`${apiUrl}organizations`, axiosRequestConfig);
const getAllRoles = () => api.get(`${apiUrl}roles`);

const getCampaigns = (
  organizationId: number,
  isVirtualTerminalRequest: boolean = false,
  axiosRequestConfig?: AxiosRequestConfig,
): Promise<APIResponse<CampaignDropDown>> =>
  api.get(
    `${apiUrl}organizations/${organizationId}/campaigns?isVirtualTerminalRequest=${isVirtualTerminalRequest}`,
    axiosRequestConfig,
  );

const getProcessors = (
  organizationId: number,
  axiosRequestConfig?: AxiosRequestConfig,
): Promise<APIResponse<ClassyPayProcessor>> =>
  api.get(`${apiUrl}organizations/${organizationId}/processors`, axiosRequestConfig);
const getFundraiserPages = (
  organizationId: number,
  axiosRequestConfig?: AxiosRequestConfig,
): Promise<APIResponse<FundraisingPageDropDown>> =>
  api.get(`${apiUrl}organizations/${organizationId}/fundraiser-pages`, axiosRequestConfig);
const getFundraiserTeams = (
  organizationId: number,
  axiosRequestConfig?: AxiosRequestConfig,
): Promise<APIResponse<FundraisingTeamDropDown>> =>
  api.get(`${apiUrl}organizations/${organizationId}/fundraiser-teams`, axiosRequestConfig);
const getProgramDesignations = ({ organizationId, startDate, endDate }: any) =>
  api.get(`${apiUrl}organizations/${organizationId}/program-designations?startDate=${startDate}&endDate=${endDate}`);
/**
 * @template T
 * @param {number} organizationId
 * @param {AxiosRequestConfig<any>} [axiosRequestConfig]
 * @returns {Promise<APIResponse<T>>}
 */
const getClassyProgramDesignations = (
  organizationId: number,
  axiosRequestConfig?: AxiosRequestConfig,
): Promise<APIResponse<Array<ProgramDesignationDropDown>>> =>
  api.get(
    `${apiUrl}organizations/${organizationId}/get-all-classy-org-designations?isDropdown=true`,
    axiosRequestConfig,
  );
const getOfflineDonations = ({ organizationId, startDate, endDate }: any) =>
  api.get(`${apiUrl}donations/offline?organizationId=${organizationId}&startDate=${startDate}&endDate=${endDate}`);
const getOrganizationById = ({ organizationId }: any) => api.get(`${apiUrl}organizations/${organizationId}`);
const searchOrganizationById = ({ organizationId }: any) => api.get(`${apiUrl}organizations/${organizationId}/search`);
const getOrganizationsByUser = ({ userId }: any) => api.get(`${apiUrl}users/${userId}/organizations`);
const getOrganizationAdmins = (organizationId: any) =>
  api.get(
    `${apiUrl}organizations/${organizationId}/users?roleId=${constants.ROLES.ADMIN}&roleId=${constants.ROLES.OPERATOR}&roleIdOperator=${constants.LOGICAL_OPERATORS.OR}`,
  );
const getTokenExConfiguration = ({ iframeType, organizationId }: any) =>
  api.get(
    `${apiUrl}configurations/tokenex?origin=${webAppUrl}&iframeType=${iframeType}&organizationId=${organizationId}`,
  );
const getUsers = () => api.get(`${apiUrl}users`);
const getUserById = (userId: number) => api.get(`${apiUrl}users/${userId}`);
const importProgramDesignations = ({ programDesignations, organizationId }: any) =>
  api.post(`${apiUrl}organizations/${organizationId}/import-program-designations`, programDesignations);
const resetUserPassword = ({ email, url }: any): any => api.post(`${apiUrl}auth/password-reset`, { email, url });
const setNewUserPassword = (requestBody: any) => api.post(`${apiUrl}auth/new-password`, requestBody);

const validateMigrations = (files: any, organizationId: any) =>
  api.post(`${apiUrl}organizations/${organizationId}/classic-migrations`, files);
const downloadDesignations = (organizationId: any) =>
  api.get(`${apiUrl}organizations/${organizationId}/download-classy-org-designations`);
const createScheduledReport = ({ organizationId, data }: any) =>
  api.post(`${apiUrl}organizations/${organizationId}/addScheduledReport`, data);
const editScheduledReport = ({ organizationId, reportId, data }: any) =>
  api.put(`${apiUrl}organizations/${organizationId}/scheduledReport/${reportId}`, data);
const deleteScheduledReport = ({ organizationId, reportId }: any) =>
  api.delete(`${apiUrl}organizations/${organizationId}/scheduledReport/${reportId}`);
const testFtpConnection = ({ data }: any) => api.post(`${apiUrl}scheduledReports/testFtpConnection`, data);
const getScheduledReports = ({ organizationId }: any) =>
  api.get(`${apiUrl}organizations/${organizationId}/scheduledReports`);
const getReport = ({ organizationId, reportId }: any) =>
  api.get(`${apiUrl}/organizations/${organizationId}/scheduledReport/${reportId}`);

const getLocations = (organizationId: number) => api.get(`${apiUrl}locations/location/${organizationId}`);
const getLocationById = ({ organizationId, locationId }: any) =>
  api.get(`${apiUrl}locations/location/${organizationId}/${locationId}`);
const createLocation = ({ organizationId, locationObj }: any) =>
  api.post(`${apiUrl}locations/location/${organizationId}`, {
    stateCode: locationObj.stateCode || '',
    cityName: locationObj.cityName || '',
    customLocation: locationObj.customLocation || '',
  });
const editLocation = ({ organizationId, locationObj, locationId }: any) =>
  api.put(`${apiUrl}locations/location/${organizationId}/${locationId}`, {
    stateCode: locationObj.stateCode || '',
    cityName: locationObj.cityName || '',
    customLocation: locationObj.customLocation || '',
  });
const deleteLocation = ({ organizationId, locationId }: any) =>
  api.delete(`${apiUrl}locations/location/${organizationId}/${locationId}`);

const getCampaignLocations = (organizationId: number) => api.get(`${apiUrl}locations/campaign/${organizationId}`);
const getCampaignLocationById = ({ organizationId, campaignLocationId }: any) =>
  api.get(`${apiUrl}locations/campaign/${organizationId}/${campaignLocationId}`);
const createCampaignLocation = ({ organizationId, campaignId, locationId }: any) =>
  api.post(`${apiUrl}locations/campaign/${organizationId}`, {
    campaignId,
    locationId,
  });
const editCampaignLocation = ({ organizationId, campaignId, locationId, campaignLocationId }: any) =>
  api.put(`${apiUrl}locations/campaign/${organizationId}/${campaignLocationId}`, { campaignId, locationId });
const deleteCampaignLocation = ({ organizationId, campaignLocationId }: any) =>
  api.delete(`${apiUrl}locations/campaign/${organizationId}/${campaignLocationId}`);

const getCampaignQuestions = ({ servicesOrganizationId, campaignId }: any) =>
  api.get(`${apiUrl}organizations/${servicesOrganizationId}/campaign-questions/${campaignId}`);

const getUserLocations = (organizationId: number) => api.get(`${apiUrl}locations/user/${organizationId}`);
const getUserLocationById = ({ organizationId, userLocationId }: any) =>
  api.get(`${apiUrl}locations/user/${organizationId}/${userLocationId}`);
const createUserLocation = ({ organizationId, userId, locationId }: any) =>
  api.post(`${apiUrl}locations/user/${organizationId}`, { userId, locationId });
const editUserLocation = ({ organizationId, userId, locationId, userLocationId }: any) =>
  api.put(`${apiUrl}locations/user/${organizationId}/${userLocationId}`, {
    userId,
    locationId,
  });
const deleteUserLocation = ({ organizationId, userLocationId }: any) =>
  api.delete(`${apiUrl}locations/user/${organizationId}/${userLocationId}`);

const getVTInfo = ({ organizationId, campaignId }: any) =>
  api.get(`${apiUrl}organizations/${organizationId}/campaigns/${campaignId}`);
const getVTDonationFrequencies = ({ organizationId, campaignId }: any) =>
  api.get(`${apiUrl}organizations/${organizationId}/campaigns/${campaignId}/frequencies`);
const getVTProgramDesignations = ({ organizationId, campaignId }: any) =>
  api.get(`${apiUrl}organizations/${organizationId}/campaign-designations/${campaignId}`);
const getVTCustomQuestions = ({ organizationId, campaignId }: any) =>
  api.get(`${apiUrl}organizations/${organizationId}/campaign-questions/${campaignId}?location=donation_page`);
const getVTSupporterSearch = ({ firstName, lastName, email, zip, organizationId }: any) =>
  api.get(
    `${apiUrl}organizations/${organizationId}/supporter-search?firstName=${encodeURIComponent(
      firstName,
    )}&lastName=${encodeURIComponent(lastName)}&email=${encodeURIComponent(email)}&zip=${encodeURIComponent(zip)}`,
  );

const getAllRecurringDonationMigrationJobs = (
  page = constants.PAGINATION.PAGE_DEFAULT,
  pageSize = constants.PAGINATION.SIZE_DEFAULT,
  sort = 'createdAt.desc',
) => api.get(`${apiUrl}recurring-donation-migrations/jobs?page=${page}&pageSize=${pageSize}&sort=${sort}`);
const createRecurringDonationMigrationJob = (data: any) =>
  api.post(`${apiUrl}recurring-donation-migrations/jobs`, data);
const downloadDonorFileUrl = (jobId: any, jwtToken: any) =>
  `${apiUrl}recurring-donation-migrations/jobs/${jobId}/donors?csvtoken=${jwtToken}`;
const downloadTokenFileUrl = (jobId: any, jwtToken: any) =>
  `${apiUrl}recurring-donation-migrations/jobs/${jobId}/tokens?csvtoken=${jwtToken}`;

const getSettings = () => api.get(`${apiUrl}settings`);
const updateSettings = (settings: any) => api.put(`${apiUrl}settings`, settings);

const getRlcGroups = (axiosRequestConfig?: AxiosRequestConfig): Promise<APIResponse<Array<RlcGroup>>> =>
  api.get(`${apiUrl}rlc/groups`, axiosRequestConfig);

const getRlcOrganizations = (
  rlcGroupId: number,
  axiosRequestConfig?: AxiosRequestConfig,
): Promise<APIResponse<Array<RlcOrganization>>> =>
  api.get(`${apiUrl}rlc/${rlcGroupId}/organizations/`, axiosRequestConfig);
const getRlcParentDesignations = (
  rlcGroupId: number,
  axiosRequestConfig?: AxiosRequestConfig,
): Promise<APIResponse<Array<RlcParentDesignation>>> =>
  api.get(`${apiUrl}rlc/${rlcGroupId}/designations/`, axiosRequestConfig);

const getRlcParentDesignationCount = (
  rlcGroupId: number,
  isPublic?: boolean,
  axiosRequestConfig?: AxiosRequestConfig,
): Promise<APIResponse<{ count: number }>> => {
  const qs = isPublic !== undefined ? `?isPublic=${isPublic}` : '';
  return api.get(`${apiUrl}rlc/${rlcGroupId}/countdesignations/${qs}`, axiosRequestConfig);
};

const createOrUpdateRlcParentDesignation = (
  rlcDesignation: CreateProgramDesignationRequest | UpdateProgramDesignationRequest,
  axiosRequestConfig?: AxiosRequestConfig,
): Promise<APIResponse<Array<RlcParentDesignation>>> =>
  api.post(`${apiUrl}rlc/${rlcDesignation.rlcGroupId}/designations/`, rlcDesignation, axiosRequestConfig);

const addOrganizationToRlcGroup = (
  organizationId: number,
  rlcGroupId: number,
  axiosRequestConfig?: AxiosRequestConfig,
): Promise<APIResponse<Array<RlcParentDesignation>>> =>
  api.post(`${apiUrl}rlc/${rlcGroupId}/organizations/`, { organizationId }, axiosRequestConfig);

interface RlcCampaignPayload {
  classyCampaignId: number;
  usesZipCode: boolean;
  usesActiveDesignations: boolean;
}

const addRlcCampaign = (
  organizationId: number,
  rlcGroupId: number,
  payload: RlcCampaignPayload,
  axiosRequestConfig?: AxiosRequestConfig,
): Promise<APIResponse<unknown>> =>
  api.post(`${apiUrl}rlc/${rlcGroupId}/organizations/${organizationId}/campaigns`, payload, axiosRequestConfig);

const modifyRlcCampaign = (
  organizationId: number,
  rlcGroupId: number,
  rlcCampaignId: number,
  payload: RlcCampaignPayload,
  axiosRequestConfig?: AxiosRequestConfig,
): Promise<APIResponse<unknown>> =>
  api.put(
    `${apiUrl}rlc/${rlcGroupId}/organizations/${organizationId}/campaigns/${rlcCampaignId}`,
    payload,
    axiosRequestConfig,
  );

const deleteRlcCampaign = (
  organizationId: number,
  rlcGroupId: number,
  rlcCampaignId: number,
  axiosRequestConfig?: AxiosRequestConfig,
): Promise<APIResponse<unknown>> =>
  api.delete(
    `${apiUrl}rlc/${rlcGroupId}/organizations/${organizationId}/campaigns/${rlcCampaignId}`,
    axiosRequestConfig,
  );

const setQueueIsPaused = (
  queueName: string,
  isPaused: boolean,
  axiosRequestConfig?: AxiosRequestConfig,
): Promise<APIResponse<unknown>> => {
  return api.post(
    `${apiUrl}queues/${encodeURIComponent(queueName)}`,
    {
      isPaused,
    },
    axiosRequestConfig,
  );
};

const clearQueue = (
  queueName: string,
  force: boolean,
  axiosRequestConfig?: AxiosRequestConfig,
): Promise<APIResponse<unknown>> => {
  return api.post(`${apiUrl}queues/${encodeURIComponent(queueName)}/clear`, { force }, axiosRequestConfig);
};

const createAbortController = () => new AbortController();

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  api,
  xsrfTokenHeader,
  apiUrl,
  addOrganizationToRlcGroup,
  addRlcCampaign,
  authenticateUser,
  getUserSession,
  removeUserInfo,
  createAbortController,
  createCampaignLocation,
  createCompany,
  createLocation,
  createOfflineDonations,
  createOrganization,
  createOrUpdateRlcParentDesignation,
  createRecurringDonationMigrationJob,
  createScheduledReport,
  createUser,
  createUserLocation,
  createVirtualTerminalDonation,

  deleteCampaignLocation,

  deleteLocation,
  deleteRlcCampaign,
  deleteScheduledReport,
  deleteUserLocation,
  downloadDesignations,
  downloadDonorFileUrl,
  downloadTokenFileUrl,
  editCampaignLocation,

  editLocation,
  editOrganizationById,
  editOrganizationCredentialsById,
  editScheduledReport,
  editUser,
  editUserLocation,
  getAllCompanies,
  getAllCredentialTypes,
  getAllFeatures,
  getAllOrganizations,
  getAllRecurringDonationMigrationJobs,
  getAllRoles,
  getCampaignQuestions,
  getCampaignLocationById,
  getCampaignLocations,
  getCampaigns,
  getClassyProgramDesignations,
  getFundraiserPages,
  getFundraiserTeams,
  getHealthCheck,
  getLocationById,
  getLocations,
  getOfflineDonations,
  getOrganizationAdmins,
  getOrganizationById,
  getOrganizationsByUser,
  getProcessors,
  getProgramDesignations,
  getReport,
  getRlcGroups,
  getRlcOrganizations,
  getRlcParentDesignations,
  getRlcParentDesignationCount,
  getScheduledReports,
  getSettings,
  getTokenExConfiguration,
  getUserById,
  getUserLocationById,
  getUserLocations,
  getUsers,
  getVTCustomQuestions,
  getVTInfo,
  getVTDonationFrequencies,
  getVTProgramDesignations,
  getVTSupporterSearch,
  importProgramDesignations,
  logoutUser,
  modifyRlcCampaign,
  resetUserPassword,
  searchOrganizationById,
  setNewUserPassword,
  setQueueIsPaused,
  clearQueue,
  testFtpConnection,
  updateSettings,
  validateMigrations,
};
