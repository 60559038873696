import { FormattedCampaign, OfflineDonation, RowValidationError } from './Types';
import moment from 'moment';
import constants from '../../Helpers/constants';
import validators from '../../Helpers/validators';
import { CellInfo } from 'react-table';
import { csvRowOffset } from './Utils';

const findRows = (
  data: Array<Partial<OfflineDonation>>,
  test: (row: Partial<OfflineDonation>) => boolean,
): Array<number> =>
  data
    .map((row, index) => ({
      data: row,
      index: index + 1,
    }))
    .filter((indexedData) => test(indexedData.data))
    .map((indexedData) => indexedData.index);

const findErrorRows = (
  data: Array<Partial<OfflineDonation>>,
  test: (record: Partial<OfflineDonation>) => boolean,
  amountError: string,
): RowValidationError | undefined => {
  const invalidAmountRows = findRows(data, test);
  return invalidAmountRows.length > 0 ? { rows: invalidAmountRows, error: amountError } : undefined;
};

export const findInvalidAmountRows = (data: Array<Partial<OfflineDonation>>) => {
  const amountError = 'Please do not include a "$" in the amount';
  return findErrorRows(data, (record) => !!(record.amount && record.amount.includes('$')), amountError);
};

export const findInvalidPaymentTypeRows = (data: Array<Partial<OfflineDonation>>) => {
  const paymentTypes: Array<string> = constants.OFFLINE_DONATIONS.PAYMENT_TYPES.reduce(
    (array, object) => array.concat(object.value as any),
    [],
  );
  const paymentTypeError = `The payment type must be one of "${paymentTypes.join(', ')}"`;
  return findErrorRows(
    data,
    (record) =>
      !!(record.paymentType && !paymentTypes.includes(record.paymentType ? record.paymentType.toLowerCase() : '')),
    paymentTypeError,
  );
};

export const findInvalidDateRows = (data: Array<Partial<OfflineDonation>>) => {
  const dateFormatError = 'The date must be in the format "YYYY-MM-DD"';
  return findErrorRows(
    data,
    (record) => !!(record.date && !moment(record.date, 'YYYY-MM-DD', true).isValid()),
    dateFormatError,
  );
};

export const findInvalidEmailRows = (data: Array<Partial<OfflineDonation>>) => {
  const emailError = 'Invalid email addresses';
  return findErrorRows(data, (record) => !!(record.email && !validators.isValidEmail(record.email)), emailError);
};

export const findInvalidCampaignRows = (
  data: Array<Partial<OfflineDonation>>,
  formattedCampaigns: Array<FormattedCampaign>,
) => {
  const campaignsError = 'Invalid campaign ids';
  return findErrorRows(
    data,
    (record) =>
      !!(
        record.campaignId &&
        !formattedCampaigns.find((campaign: FormattedCampaign) => campaign.value === Number(record.campaignId))
      ),
    campaignsError,
  );
};

export const findInvalidCountryRows = (data: Array<Partial<OfflineDonation>>) => {
  const countryError = 'The country must be 2 letters in length';
  return findErrorRows(
    data,
    (record) => Boolean(record.country && !validators.isValidCountry(record.country)),
    countryError,
  );
};

export const formatRowError = (rowError: RowValidationError) => {
  const maxRows = 30;
  const rowSummary = rowError.rows.length === 1 ? '1 row' : `${rowError.rows.length} rows`;
  let message = rowError.rows.slice(0, 30).map(csvRowOffset).join(', ');
  if (rowError.rows.length > maxRows) {
    message = `${message} and ${rowError.rows.length - maxRows} more rows`;
  }
  return `${rowError.error}. (${rowSummary}): ${message}`;
};

export const validateFileImport = (data: Array<Partial<OfflineDonation>>, templateRow: CellInfo): Array<string> => {
  let errors: Array<string> = [];

  if (data && Array.isArray(data)) {
    if (data.length) {
      // Grab a sample record so we can make sure the columns are correct
      const firstRecord = data[0];

      // Construct an array of the complete list of column names
      const masterColumnList = Object.keys(templateRow);

      // Construct an array of the columns in the file
      const fileColumnList = Object.keys(firstRecord);

      if (fileColumnList.length !== masterColumnList.length) {
        errors = [...errors, 'The number of columns in this file do not match the template'];
      }

      // Validate that we recognize all the column names
      fileColumnList.forEach((column) => {
        if (!masterColumnList.includes(column)) {
          errors = [...errors, `The column "${column}" is not recognized`];
        }
      });
    }
  }

  return errors;
};

export const validateRows = (
  data: Array<Partial<OfflineDonation>>,
  formattedCampaigns: Array<FormattedCampaign>,
): Array<RowValidationError> => {
  return [
    findInvalidAmountRows(data),
    findInvalidPaymentTypeRows(data),
    findInvalidDateRows(data),
    findInvalidEmailRows(data),
    findInvalidCampaignRows(data, formattedCampaigns),
    findInvalidCountryRows(data),
  ].filter((x): x is RowValidationError => !!x);
};
