import React, { Component } from 'react';
import './OrganizationIdInput.scss';
import api from '../../Services/Api';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import { connect } from 'react-redux';

class OrganizationIdInput extends Component {
  static defaultProps = {
    setServicesOrganizationId: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      servicesOrganizationId: '',
      organizationId: '',
      organizationName: '',
      organizationNameError: '',
      organizationIdError: null,
      organizationInputDebounce: null,
      organizationInputLoading: false,
      organizationInputFocus: false,
      organizationRequestTick: 0,
    };

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  // Regex explanation: https://regex101.com/r/K2Z9e4/1
  orgIdOnlyRegex = /[0-9][^\s]+/;

  handleOrganizationChange = (event) => {
    /*
    Value variable will check if event exists. If so, target and value will also be evaluated for validitiy.
    If all exist, then current value will  remove whitespace and matched based on 'orgIdOnlyRegex'(Regex is checking for numerical values only)
    and be trimmed of whitespeace.
    */

    const value = event?.target?.value?.trim();

    if (this.state.organizationInputDebounce) {
      clearTimeout(this.state.organizationInputDebounce);
    }

    if (value) {
      const matchedValue = value?.match(this?.orgIdOnlyRegex)?.[0] || value;
      const debounce = setTimeout(() => this.searchOrganizationById(matchedValue), 500);

      this.setState({
        organizationId: value,
        organizationInputDebounce: debounce,
        organizationName: '',
        organizationNameError: '',
        organizationInputLoading: false,
      });

      this.props.setOrganizationIdLoading(false);
      this.props.setOrganizationId(undefined);
      this.props.setServicesOrganizationId(undefined);
    } else {
      this.setState({
        organizationId: '',
        organizationInputDebounce: null,
        organizationName: '',
        organizationNameError: '',
        organizationInputLoading: false,
      });
      this.props.setOrganizationIdLoading(false);
      this.props.setOrganizationId(null);
      this.props.setServicesOrganizationId(null);
    }
  };

  handleOrganizationFocus = () => {
    const { organizationId, organizationName, servicesOrganizationId } = this.state;
    if (organizationId && organizationName) {
      const suffix = ` - ${organizationName}`;
      const value = organizationId.replace(suffix, '');

      this.setState({ organizationId: value, servicesOrganizationId: servicesOrganizationId });
    }
    this.setState({ organizationInputFocus: true });
  };

  handleOrganizationBlur = () => {
    const { organizationId, organizationName, servicesOrganizationId } = this.state;
    if (organizationId && organizationName) {
      const suffix = ` - ${organizationName}`;
      const prefix = organizationId.replace(suffix, '');

      this.setState({ organizationId: `${prefix}${suffix}`, servicesOrganizationId: servicesOrganizationId });
    }

    this.setState({ organizationInputFocus: false });
  };

  searchOrganizationById = (id) => {
    if (isNaN(id)) {
      this.setState({ organizationNameError: 'Invalid Organization Id' });
      return;
    }

    const { organizationRequestTick } = this.state;
    const currentRequestTick = organizationRequestTick + 1;

    this.props.setOrganizationIdLoading(true);
    this._isMounted &&
      this.setState({
        organizationRequestTick: currentRequestTick,
        organizationInputLoading: true,
      });

    api
      .searchOrganizationById({ organizationId: id })
      .then((resp) => {
        if (this.state.organizationRequestTick !== currentRequestTick || !resp || !resp.data) {
          return;
        }

        let organizationName = '';
        let organizationNameError = '';
        // let organizationIdError = '';

        if (resp.data.status === 'active') {
          this.props.setOrganizationId(id);
          //TO DO: throw error to let user know that if org does not exist in classy services
          this.props.setServicesOrganizationId(resp?.data?.servicesOrganizationId);
          organizationName = resp.data.name || '';
        } else {
          this.props.setOrganizationId(undefined);
          this.props.setServicesOrganizationId(undefined);
          organizationNameError = `Organization ID ${id} is in an invalid status, please select an active Organization`;
          //   organizationIdError = `Organization ID ${id} is not a valid id. Please enter a valid organization id and try again`;
        }

        this.setOrganizationState({
          organizationName: organizationName,
          organizationNameError: organizationNameError,
        });
      })
      .catch((err) => {
        this.props.setOrganizationId(undefined);
        this.props.setServicesOrganizationId(undefined);
        if (this.state.organizationRequestTick !== currentRequestTick) {
          return;
        }

        let organizationNameError = `Could not retrieve data for Organization ID ${id}, please try again`;
        if (err.errors && err.errors[0] && err.errors[0].includes('not found')) {
          organizationNameError = `Organization ID ${id} was not found, please select an active Organization`;
        }

        this.setOrganizationState({
          organizationName: '',
          organizationNameError: organizationNameError,
        });
      });
  };

  setOrganizationState(data) {
    const { organizationName, organizationNameError } = data;

    this.props.setOrganizationIdLoading(false);
    this.setState({
      organizationName: organizationName,
      organizationNameError: organizationNameError,
      organizationInputLoading: false,
    });

    const { organizationInputFocus } = this.state;
    if (!organizationInputFocus) {
      this.handleOrganizationBlur();
    }
  }

  render() {
    const { organizationId, organizationName, organizationNameError, organizationInputLoading } = this.state;

    let validationState = this.props.organizationIdError;
    if (organizationInputLoading) {
      validationState = null;
    }
    if (!!organizationName) {
      validationState = 'success';
    }

    return (
      <FormGroup controlId="organizationId" validationState={validationState} className="organizationId">
        <ControlLabel>{this.props.label || 'Organization Id'}</ControlLabel>
        <FormControl
          required
          name="organizationId"
          className={`${!!organizationName ? 'valid-org-id' : ''}`}
          onChange={this.handleOrganizationChange}
          onFocus={this.handleOrganizationFocus}
          onBlur={this.handleOrganizationBlur}
          placeholder="Organization ID"
          type="text"
          value={organizationId}
        />

        {!!organizationNameError && <div className="invalid-message">{organizationNameError}</div>}
        {organizationInputLoading && <div className="tiny-loader" data-testid="organization-input-loader"></div>}

        <HelpBlock>
          {this.props.helpBlock ||
            'The Organization Id is required and must be a number. The Id must belong to an active and valid Organization.'}
        </HelpBlock>
        <FormControl.Feedback />
      </FormGroup>
    );
  }
}

OrganizationIdInput.propTypes = {
  label: PropTypes.string,
  helpBlock: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  organizationIdError: PropTypes.string,
  setOrganizationId: PropTypes.func,
  setServicesOrganizationId: PropTypes.func,
  setOrganizationIdLoading: PropTypes.func,
  store: PropTypes.object,
  searchOrganizationById: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { organizationId, servicesOrganizationId } = state.organizationIdInput;

  return {
    organizationId,
    servicesOrganizationId,
  };
};
export default connect(mapStateToProps)(OrganizationIdInput);
