import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  clearVirtualTerminalDonationResponse: null,
  setLoadingVirtualTerminal: null,
  virtualTerminalDonationFailure: ['virtualTerminalDonationErrors'],
  virtualTerminalDonationSuccess: ['virtualTerminalDonationResponse'],
});

export const VirtualTerminalTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  loadingVirtualTerminal: false,
  virtualTerminalDonationResponse: {},
  virtualTerminalDonationErrors: [],
};

/* ------------- Reducers ------------- */

const clearVirtualTerminalDonationResponse = (state) => ({
  ...state,
  virtualTerminalDonationResponse: {},
});

const setLoadingVirtualTerminal = (state) => ({
  ...state,
  loadingVirtualTerminal: true,
});

const virtualTerminalDonationFailure = (state, { virtualTerminalDonationErrors }) => ({
  ...state,
  virtualTerminalDonationErrors,
  virtualTerminalDonationResponse: {},
  loadingVirtualTerminal: false,
});

const virtualTerminalDonationSuccess = (state, { virtualTerminalDonationResponse }) => ({
  ...state,
  virtualTerminalDonationResponse,
  virtualTerminalDonationErrors: [],
  loadingVirtualTerminal: false,
});

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.CLEAR_VIRTUAL_TERMINAL_DONATION_RESPONSE]: clearVirtualTerminalDonationResponse,
  [Types.SET_LOADING_VIRTUAL_TERMINAL]: setLoadingVirtualTerminal,
  [Types.VIRTUAL_TERMINAL_DONATION_FAILURE]: virtualTerminalDonationFailure,
  [Types.VIRTUAL_TERMINAL_DONATION_SUCCESS]: virtualTerminalDonationSuccess,
});
