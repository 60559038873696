import React from 'react';
import PropTypes from 'prop-types';
import { Col, Checkbox, FormControl, Row } from 'react-bootstrap';
import constants from '../../Helpers/constants';
import validators from '../../Helpers/validators';
import TokenexIframe from '../TokenexIframe';
import { RequiredField } from '../VirtualTerminal/RequiredField/RequiredField';

export class TokenexAch extends React.Component {
  constructor(props) {
    super(props);
    this.handleAchValidate = this.handleAchValidate.bind(this);
    this.handleTokenize = this.handleTokenize.bind(this);
    this.handleRoutingNumberChange = this.handleRoutingNumberChange.bind(this);
    this.handleAchTermsChange = this.handleAchTermsChange.bind(this);
  }

  handleAchValidate(token) {
    this.props.handleAchValidate({ isAccountNumberValid: token.isValid });
  }

  handleTokenize(accountNumber) {
    const { token, characterCount } = accountNumber;
    this.props.handleTokenize({ token, characterCount });
  }

  handleRoutingNumberChange(event) {
    this.props.handleRoutingNumberChange(event);
  }

  handleAchTermsChange(event) {
    this.props.handleAchTermsChange(event.target.checked);
  }

  render() {
    const {
      organizationId,
      changeSubmitStatus,
      registerFormResetHandler,
      registerFormSubmitHandler,
      routingNumber,
      achTerms,
      isValidated,
      isAccountNumberValid,
    } = this.props;
    return (
      <Col>
        <Row>
          <Col xs={6}>
            <span>
              ABA/Routing Number<sup className="required-marker">*</sup>
            </span>
            <RequiredField
              id="virtualTerminal__ach"
              isValidated={isValidated}
              message="Please enter a valid ABA/Routing Number"
              hasError={isValidated && !validators.isValidRoutingNumber(routingNumber)}
            >
              <FormControl
                name="routingNumber"
                type="text"
                value={routingNumber}
                onChange={this.handleRoutingNumberChange}
              />
            </RequiredField>
          </Col>
          <Col xs={6}>
            <span>
              Account Number<sup className="required-marker">*</sup>
            </span>
            <RequiredField
              id="virtualTerminal__ac-number"
              isValidated={isValidated}
              message="Please enter a valid account number"
              hasError={isValidated && !isAccountNumberValid}
            >
              <div className={`form-control form-control__account-number control-highlight`}>
                <TokenexIframe
                  iframeType={constants.PAYMENT_METHODS.ACH}
                  organizationId={organizationId}
                  changeSubmitStatus={changeSubmitStatus}
                  onValid={this.handleAchValidate}
                  onToken={this.handleTokenize}
                  registerFormSubmitHandler={registerFormSubmitHandler}
                  registerFormResetHandler={registerFormResetHandler}
                />
              </div>
            </RequiredField>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RequiredField
              id="virtualTerminal__achTermsContainer"
              isValidated={isValidated}
              message="Authorization required"
              hasError={isValidated && !achTerms}
            >
              <Checkbox
                id="virtualTerminal__achTerms"
                name="achTerms"
                checked={achTerms}
                required
                onChange={this.handleAchTermsChange}
              >
                I authorize Classy to electronically debit my account and, if necessary, electronically credit my
                account to correct erroneous debits.<sup className="required-marker">*</sup>
              </Checkbox>
            </RequiredField>
          </Col>
        </Row>
      </Col>
    );
  }
}

TokenexAch.propTypes = {
  isValidated: PropTypes.bool,
  handleTokenize: PropTypes.func.isRequired,
  handleAchValidate: PropTypes.func.isRequired,
  changeSubmitStatus: PropTypes.func,
  handleRoutingNumberChange: PropTypes.func.isRequired,
  handleAchTermsChange: PropTypes.func.isRequired,
  registerFormSubmitHandler: PropTypes.func.isRequired,
  registerFormResetHandler: PropTypes.func.isRequired,
  isAccountNumberValid: PropTypes.bool,
  organizationId: PropTypes.number.isRequired,
  routingNumber: PropTypes.string,
  achTerms: PropTypes.bool,
};

export default TokenexAch;
