import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ClassyAlert from '../ClassyAlert/ClassyAlert';
import ClassyButton from '../ClassyButton/ClassyButton';
import LoginActions from '../../Redux/Login.redux';
import './ProgramDesignations.scss';

const INITIAL_STATE = {
  showAlert: false,
  alertMessage: '',
};

class ProgramDesignations extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidUpdate(prevProps) {
    const { selectOrganization, selectedOrganization } = this.props;
    if (prevProps.selectedOrganization !== selectedOrganization) {
      selectOrganization(selectedOrganization);
    }
  }
  setAlert = (alertMessage, showAlert = true) => {
    this.setState({ alertMessage, showAlert });
  };

  onDownloadDesignationsTemplate = () => {
    const {
      selectedOrganization: { id: organizationId },
    } = this.props;

    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = `${process.env.REACT_APP_CLASSY_SERVICES_API_URL}organizations/${organizationId}/download-classy-org-designations`;
    link.click();
  };

  render() {
    return (
      <div>
        <ClassyAlert
          show={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />
        <h2 className="title-text">Program Designations</h2>
        <div className="program-designations__table-header-button-group">
          <div className="flexRow">
            <ClassyButton
              className="secondary-button program-designations__table-header-button"
              title={'Download Designations'}
              onClick={this.onDownloadDesignationsTemplate}
            />
          </div>
        </div>
      </div>
    );
  }
}

ProgramDesignations.propTypes = {
  selectedOrganization: PropTypes.object.isRequired,
  selectOrganization: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { selectedOrganization } = state.login;
  const { programDesignations } = state.programDesignations;

  return {
    programDesignations,
    selectedOrganization,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { selectOrganization } = LoginActions;
  return {
    selectOrganization: (organization) => dispatch(selectOrganization(organization)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramDesignations);
