import React, { useEffect, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import Select from 'react-select';
import formatters from '../../../Helpers/formatters';
import api from '../../../Services/Api';

interface RlcGroupSelectorProps {
  value: number;
  onChanged: (rlcGroupId: number) => void;
}

interface RlcGroup {
  id: number;
  name: string;
}

const RlcGroupSelector = ({ value, onChanged }: RlcGroupSelectorProps) => {
  const [selectedRlcGroupId, setSelectedRlcGroupId] = useState<number>(value);
  const [rlcGroups, setRlcGroups] = useState<Array<RlcGroup> | undefined>(undefined);

  useEffect(() => {
    api
      .getRlcGroups()
      .then((rlcGroupsResponse) => {
        const rlcGroups = rlcGroupsResponse.success ? rlcGroupsResponse.data : [];
        setRlcGroups(formatters.formatSelectOptions(rlcGroups));
      })
      .catch((e) => {
        console.error(e);
      });
  }, [onChanged]);

  const handleRlcGroupIdChange = ({ value }: { value: string }) => {
    const val = Number(value);
    setSelectedRlcGroupId(val);
    onChanged(val);
  };

  return (
    <>
      {rlcGroups && (
        <FormControl componentClass={'react-select' as any} name="rlcGroup">
          <Select
            options={rlcGroups}
            value={formatters.getSingleOptionByValue(rlcGroups, selectedRlcGroupId)}
            onChange={handleRlcGroupIdChange}
          />
        </FormControl>
      )}
    </>
  );
};

export default RlcGroupSelector;
