import React from 'react';
import propTypes from 'prop-types';
import './ClassyRadio.scss';
import { FormGroup } from 'react-bootstrap';

const ClassyRadio = ({ name, options, onChange, defaultChecked }) => {
  const radioInputs = options.map((option) => {
    const key = `classyradio-${name}-${option.value}`;
    return (
      <div className="classyradio" key={key}>
        <input
          type="radio"
          name={name}
          value={option.value}
          onChange={() => {
            onChange(option.value);
          }}
          id={key}
          defaultChecked={option.value === defaultChecked}
        />
        <label htmlFor={key}>{option.label}</label>
      </div>
    );
  });

  return <FormGroup className="classyradio-container">{radioInputs}</FormGroup>;
};

ClassyRadio.propTypes = {
  name: propTypes.string,
  defaultChecked: propTypes.string,
  options: propTypes.arrayOf(propTypes.object),
  onChange: propTypes.func,
};

export default ClassyRadio;
