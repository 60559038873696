import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getProgramDesignationsFailure: ['getProgramDesignationsError'],
  getProgramDesignationsSuccess: ['getProgramDesignationsResponse'],
  addProgramDesignationsTemplateRow: ['row'],
  clearProgramDesignationsTemplateData: null,
  toggleLoadingProgramDesignations: ['isLoading'],
  updateProgramDesignationsTemplate: ['programDesignationsTemplateData'],
});

export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  getProgramDesignationsError: {},
  getProgramDesignationsResponse: [],
  loadingProgramDesignations: false,
  programDesignationsTemplateData: [],
  programDesignationsTemplateRow: {
    programDesignationId: '',
    programDesignation: '',
    description: '',
    externalReferenceId: '',
    goal: '',
    displayStatus: false,
    city: '',
    state: '',
    zip: '',
    isUpdate: false,
  },
};

/* ------------- Reducers ------------- */
const getProgramDesignationsFailure = (state, { getProgramDesignationsError }) => ({
  ...state,
  getProgramDesignationsError,
  getProgramDesignationsResponse: [],
  loadingProgramDesignations: false,
});

const getProgramDesignationsSuccess = (state, { getProgramDesignationsResponse }) => ({
  ...state,
  getProgramDesignationsResponse,
  getProgramDesignationsError: {},
  loadingProgramDesignations: false,
});

const addProgramDesignationsTemplateRow = (state, { row }) => ({
  ...state,
  programDesignationsTemplateData: [...state.programDesignationsTemplateData, row],
});

const clearProgramDesignationsTemplateData = (state) => ({
  ...state,
  programDesignationsTemplateData: [],
  loadingProgramDesignations: false,
});

const updateProgramDesignationsTemplate = (state, { programDesignationsTemplateData }) => ({
  ...state,
  programDesignationsTemplateData,
});

const toggleLoadingProgramDesignations = (state, { isLoading = true }) => ({
  ...state,
  loadingProgramDesignations: isLoading,
});

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PROGRAM_DESIGNATIONS_FAILURE]: getProgramDesignationsFailure,
  [Types.GET_PROGRAM_DESIGNATIONS_SUCCESS]: getProgramDesignationsSuccess,
  [Types.CLEAR_PROGRAM_DESIGNATIONS_TEMPLATE_DATA]: clearProgramDesignationsTemplateData,
  [Types.ADD_PROGRAM_DESIGNATIONS_TEMPLATE_ROW]: addProgramDesignationsTemplateRow,
  [Types.UPDATE_PROGRAM_DESIGNATIONS_TEMPLATE]: updateProgramDesignationsTemplate,
  [Types.TOGGLE_LOADING_PROGRAM_DESIGNATIONS]: toggleLoadingProgramDesignations,
});
