import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'react-bootstrap';

class ClassyTableCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnId: this.props.row.column.id,
      isChecked: this.getBooleanValue(this.props.checked),
    };
  }

  componentDidUpdate(prevProps) {
    const { columnId } = this.state;
    if (prevProps.row.original[columnId] !== this.props.row.original[columnId]) {
      this.setState({ isChecked: this.props.row.original[columnId] });
    }
  }

  getBooleanValue(value) {
    let boolean;
    if (typeof value === 'boolean') {
      boolean = value;
    } else if (value && (value.toUpperCase() === 'TRUE' || value.toUpperCase() === 'YES')) {
      boolean = true;
    } else {
      boolean = false;
    }
    return boolean;
  }

  render() {
    const { onChange, row, checked } = this.props;

    return (
      <div className="classy-table__checkbox">
        <Checkbox
          bsClass="flexRow centered"
          checked={this.getBooleanValue(checked)}
          onChange={(e) => {
            const value = e.target.checked;
            this.setState({ isChecked: value });
            onChange(row, value);
          }}
        />
      </div>
    );
  }
}

ClassyTableCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default ClassyTableCheckbox;
