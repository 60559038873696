import React, { ChangeEvent, FormEvent, FormEventHandler, useCallback, useEffect, useState } from 'react';
import {
  ButtonToolbar,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Modal,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import ClassyButton from '../ClassyButton/ClassyButton';
import './Designations.scss';
import {
  convertJoiToSimpleValidationResult,
  isValid,
  joiValidate,
  SimpleValidationResult,
  ValidationResult,
} from '../../Helpers/validators';
import joi from 'joi';
import ValidationMessage, { getValidationState } from './ValidationMessage';
import { BaseProgramDesignation } from '../../Services/Rlc/RlcParentDesignation';
import Throbber from '../Throbber/Throbber';

export interface EditDesignationProps {
  designation: BaseProgramDesignation;
  onCancel: () => void;
  onSave: (designation: BaseProgramDesignation) => void;
  confirmationMessage: string;
  error?: string;
  isInFlight: boolean;
}

// Description is the only field that is not required - all
// other fields are required.
const validateBaseDesignationPayload = (
  obj: BaseProgramDesignation,
): ValidationResult<BaseProgramDesignation, joi.ValidationError> => {
  const schema = joi.object({
    isPublic: joi.boolean().required(),
    pdName: joi.string().required().max(127).messages({
      'string.empty': 'The program name is required.',
      'string.max': 'The program name must not exceed 127 characters.',
    }),
    pdDescription: joi.string().max(256).allow(null, '').messages({
      'string.max': 'The description must not exceed 256 characters.',
    }),
    pdExternalReferenceId: joi.string().max(100).required().messages({
      'string.empty': 'The external id is required.',
      'string.max': 'The external id must not exceed 100 characters.',
    }),
    pdGoal: joi.number().min(0).allow(null).messages({
      'number.base': 'The goal, if provided, must be a valid number.',
      'any.invalid': 'The goal must be a valid number.',
      'string.empty': 'The goal amount is required.',
      'number.min': 'The goal amount must be equal or greater than 0.',
    }),
    pdStartDate: joi.date().allow(null, ''),
    pdEndDate: joi.date().allow(null, ''),
    pdPostalCode: joi.string().max(50).required().messages({
      'string.empty': 'The zip code is required.',
      'string.max': 'The zip code must not exceed 50 characters.',
    }),
    pdCity: joi.string().max(100).required().messages({
      'string.empty': 'The city is required.',
      'string.max': 'The city name must not exceed 100 characters.',
    }),
    pdState: joi.string().length(2).required().messages({
      'string.null': 'The state is required.',
      'string.empty': 'The state is required.',
      'string.length': 'The state name must be 2 characters long.',
    }),
    pdIsActive: joi.boolean().required(),
    pdIsComplete: joi.boolean().required(),
  });
  return joiValidate(obj, schema);
};

const EditDesignation = ({
  designation,
  onCancel,
  onSave,
  confirmationMessage,
  error,
  isInFlight,
}: EditDesignationProps) => {
  const [editedDesignation, setEditedDesignation] = useState<BaseProgramDesignation>(designation);
  const [confirmation, setConfirmation] = useState<string | null>(null);
  const [unboundError, setUnboundError] = useState<string | null>(null);
  const [validationError, setValidationError] = useState<SimpleValidationResult>({});

  const onSaveConfirmed = useCallback(() => {
    setConfirmation(null);
    const validation = validateBaseDesignationPayload(editedDesignation);
    if (isValid(validation)) {
      setValidationError({});
      setConfirmation(null);
      onSave(editedDesignation);
    } else {
      setValidationError(convertJoiToSimpleValidationResult(validation.error));
    }
  }, [onSave, editedDesignation]);

  useEffect(() => {
    // if we get an unexpected validation from the server, we might not
    // have a field to put it by.
    //"id" is not allowed. "rlcGroupId" is not allowed. "createdBy" is not allowed. "updatedBy" is not allowed. "createdAt" is not allowed. "updatedAt" is not allowed
    const {
      pdDescription,
      pdName,
      pdIsActive,
      pdState,
      isPublic,
      pdExternalReferenceId,
      pdGoal,
      pdCity,
      pdPostalCode,
      ...unDisplayedErrors
    } = validationError;
    if (unDisplayedErrors) {
      setUnboundError(Object.values(unDisplayedErrors).join('. '));
    }
  }, [validationError]);

  const handleBack = useCallback(() => {
    setUnboundError(null);
    setConfirmation(null);
  }, []);

  const onSaveRequested = useCallback(() => {
    setUnboundError(null);
    const validation = validateBaseDesignationPayload(editedDesignation);
    if (isValid(validation)) {
      setValidationError({});
      setConfirmation(confirmationMessage);
    } else {
      console.error('Validation error...');
      console.error(validation.error);
      setValidationError(convertJoiToSimpleValidationResult(validation.error));
    }
  }, [confirmationMessage, editedDesignation]);

  const onFormSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  }, []);

  const handleCancel = useCallback(() => {
    setUnboundError(null);
    onCancel();
  }, [onCancel]);

  const onCheckedChanged: FormEventHandler<FormControl> = useCallback((event) => {
    // not sure why the callback isn't for ChangeEvent
    const e = event as unknown as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
    const field = e?.currentTarget?.name || '';
    const value = e?.target?.value === 'true';
    if (field) {
      setEditedDesignation((orig) => ({
        ...orig,
        [field]: value,
      }));
    }
  }, []);
  const onInputChange: FormEventHandler<FormControl> = useCallback((event) => {
    // not sure why the callback isn't for ChangeEvent
    const e = event as unknown as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
    const field = e?.currentTarget?.name || '';
    let value: string | null = e?.target?.value || '';

    if (field === 'pdGoal') {
      value = value || null;
    }

    if (field) {
      setEditedDesignation((orig) => ({
        ...orig,
        [field]: value,
      }));
    }
  }, []);
  return (
    <>
      <form onSubmit={onFormSubmit} className="rlcDesignations__form">
        {error && <div className="rlcDesignations__error_text">{error}</div>}
        {unboundError && <div className="rlcDesignations__error_text">{unboundError}</div>}
        <fieldset className="blog">
          <Row>
            <Col xs={6}>
              {/*<FormGroup controlId="pdName" validationState={validators.getValidStateName(badge.name)}>*/}
              <FormGroup controlId="pdName" validationState={getValidationState(validationError, 'pdName')}>
                <ControlLabel>
                  Name <sup className="required-marker">*</sup>
                </ControlLabel>
                <FormControl
                  name="pdName"
                  type="text"
                  value={editedDesignation.pdName}
                  placeholder="Name"
                  onChange={onInputChange}
                  tabIndex={1}
                />
                <FormControl.Feedback />
                <ValidationMessage field="pdName" validationError={validationError} />
              </FormGroup>
              <FormGroup
                controlId="pdDescription"
                validationState={getValidationState(validationError, 'pdDescription')}
              >
                <ControlLabel>Description</ControlLabel>
                <FormControl
                  name="pdDescription"
                  type="text"
                  value={editedDesignation.pdDescription || ''}
                  placeholder=""
                  onChange={onInputChange}
                  tabIndex={3}
                />
                <FormControl.Feedback />
                <ValidationMessage field="pdDescription" validationError={validationError} />
              </FormGroup>
              <FormGroup controlId="pdIsActive" validationState={getValidationState(validationError, 'pdIsActive')}>
                <ControlLabel>Display Status</ControlLabel>
                <ButtonToolbar>
                  <ToggleButtonGroup
                    type="radio"
                    name="pdIsActive"
                    defaultValue={designation.pdIsActive.toString()}
                    tabIndex={5}
                  >
                    <ToggleButton value="true" width="150" style={{ width: 100 }} onChange={onCheckedChanged}>
                      Enabled
                    </ToggleButton>
                    <ToggleButton value="false" width="150" style={{ width: 100 }} onChange={onCheckedChanged}>
                      Disabled
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ButtonToolbar>
                <FormControl.Feedback />
                <ValidationMessage field="pdIsActive" validationError={validationError} />
              </FormGroup>
              <FormGroup controlId="pdState" validationState={getValidationState(validationError, 'pdState')}>
                <ControlLabel>
                  State <sup className="required-marker">*</sup>
                </ControlLabel>
                <FormControl
                  name="pdState"
                  type="text"
                  value={editedDesignation.pdState || ''}
                  placeholder='enter the two-letter state name, e.g. "CA"'
                  onChange={onInputChange}
                  tabIndex={7}
                />
                <FormControl.Feedback />
                <ValidationMessage field="pdState" validationError={validationError} />
              </FormGroup>
              <FormGroup controlId="isPublic" validationState={getValidationState(validationError, 'isPublic')}>
                <ControlLabel>Public Facing</ControlLabel>
                <ButtonToolbar>
                  <ToggleButtonGroup
                    type="radio"
                    name="isPublic"
                    defaultValue={designation.isPublic.toString()}
                    tabIndex={9}
                  >
                    <ToggleButton value="true" style={{ width: 100 }} onChange={onCheckedChanged}>
                      Yes
                    </ToggleButton>
                    <ToggleButton value="false" style={{ width: 100 }} onChange={onCheckedChanged}>
                      No
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ButtonToolbar>
                <FormControl.Feedback />
                <ValidationMessage field="isPublic" validationError={validationError} />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup
                controlId="pdExternalReferenceId"
                validationState={getValidationState(validationError, 'pdExternalReferenceId')}
              >
                <ControlLabel>
                  External Id <sup className="required-marker">*</sup>
                </ControlLabel>
                <FormControl
                  name="pdExternalReferenceId"
                  type="text"
                  value={editedDesignation.pdExternalReferenceId || ''}
                  placeholder=""
                  onChange={onInputChange}
                  tabIndex={2}
                />
                <FormControl.Feedback />
                <ValidationMessage field="pdExternalReferenceId" validationError={validationError} />
              </FormGroup>

              <FormGroup controlId="pdGoal" validationState={getValidationState(validationError, 'pdGoal')}>
                <ControlLabel>Goal</ControlLabel>
                <FormControl
                  name="pdGoal"
                  type="text"
                  value={editedDesignation.pdGoal ? editedDesignation.pdGoal.toString() : ''}
                  placeholder='Enter a monetary value, e.g. "1000.00"'
                  onChange={onInputChange}
                  tabIndex={4}
                />
                <FormControl.Feedback />
                <ValidationMessage field="pdGoal" validationError={validationError} />
              </FormGroup>
              <FormGroup controlId="pdCity" validationState={getValidationState(validationError, 'pdCity')}>
                <ControlLabel>
                  City <sup className="required-marker">*</sup>
                </ControlLabel>
                <FormControl
                  name="pdCity"
                  type="text"
                  value={editedDesignation.pdCity || ''}
                  placeholder=""
                  onChange={onInputChange}
                  tabIndex={6}
                />
                <FormControl.Feedback />
                <ValidationMessage field="pdCity" validationError={validationError} />
              </FormGroup>

              <FormGroup controlId="pdPostalCode" validationState={getValidationState(validationError, 'pdPostalCode')}>
                <ControlLabel>
                  Zip Code <sup className="required-marker">*</sup>
                </ControlLabel>
                <FormControl
                  name="pdPostalCode"
                  type="text"
                  value={editedDesignation.pdPostalCode || ''}
                  placeholder="Zip Code"
                  onChange={onInputChange}
                  tabIndex={8}
                />
                <FormControl.Feedback />
                <ValidationMessage field="pdPostalCode" validationError={validationError} />
              </FormGroup>
            </Col>
          </Row>
          {confirmation && (
            <Row>
              <Col span={2} className={'text-center'}>
                <div className={'rlcDesignations__confirmation'}>{confirmation}</div>
              </Col>
            </Row>
          )}
          {isInFlight && (
            <div className="rlcDesignations__throbber_container">
              <Throbber loading={isInFlight} />
            </div>
          )}
          {!isInFlight && !confirmation && (
            <Row>
              <Col span={2} className={'text-center'}>
                <div className="submit-button">
                  <ClassyButton title="Cancel" className={'secondary-button'} onClick={handleCancel} tabIndex={9} />
                  {/* TODO: isValid seems incorrect */}
                  <ClassyButton title="Save" disabled={!isValid} onClick={onSaveRequested} tabIndex={10} />
                </div>
              </Col>
            </Row>
          )}
          {!isInFlight && confirmation && (
            <>
              <Row>
                <Col span={2} className={'text-center'}>
                  <div className="submit-button">
                    <ClassyButton title="Back" className={'secondary-button'} onClick={handleBack} />
                    {/* TODO: isValid seems incorrect */}
                    <ClassyButton title="Confirm" disabled={!isValid} onClick={onSaveConfirmed} />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </fieldset>
      </form>
    </>
  );
};

interface EditDesignationModalProps {
  isOpen: boolean;
  isInFlight: boolean;
  onCancel: () => void;
  onSave: (designation: BaseProgramDesignation) => void;
  designation: BaseProgramDesignation;
  confirmationMessage: string;
  title: string;
  error?: string;
}

const EditDesignationModal = ({
  isOpen,
  onCancel,
  onSave,
  designation,
  confirmationMessage,
  title,
  error,
  isInFlight,
}: EditDesignationModalProps) => {
  return (
    <>
      {/* https://react-bootstrap-v3.netlify.app/components/modal/ */}
      <Modal show={isOpen} onHide={onCancel} bsSize="large">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditDesignation
            designation={designation}
            onCancel={onCancel}
            onSave={onSave}
            confirmationMessage={confirmationMessage}
            error={error}
            isInFlight={isInFlight}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditDesignationModal;
