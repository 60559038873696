import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassyButton from '../ClassyButton/ClassyButton';
import './FileInput.scss';

class FileInput extends Component {
  constructor(props) {
    super(props);

    this.fileInput = React.createRef();
    this.onClickBrowseFiles = this.onClickBrowseFiles.bind(this);
  }

  onClickBrowseFiles(e) {
    e.preventDefault();
    this.fileInput.current.click();
  }

  render() {
    const { buttonLabel, file, hideInput, inputId, inputLabel, onOpenFileBrowser, accept } = this.props;

    return (
      <div className="flexColumn">
        <span>{inputLabel}</span>
        <div className="flexRow">
          <div className="file-input__wrapper">
            <div className={`file-input__value-container ${hideInput ? 'hide' : null}`}>
              <span className="file-input__value" onClick={this.onClickBrowseFiles}>
                {file ? file.name : null}
              </span>
              <input
                className="file-input__input"
                type="file"
                id={inputId}
                name={inputId}
                onChange={onOpenFileBrowser}
                ref={this.fileInput}
                accept={accept}
              />
            </div>
          </div>
          <ClassyButton
            className="secondary-button file-input__button"
            type="button"
            title={buttonLabel}
            onClick={this.onClickBrowseFiles}
          />
        </div>
      </div>
    );
  }
}

FileInput.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  hideInput: PropTypes.bool,
  file: PropTypes.object,
  inputLabel: PropTypes.string,
  accept: PropTypes.string,
  inputId: PropTypes.string,
  onOpenFileBrowser: PropTypes.func.isRequired,
};

export default FileInput;
