import React, { Component } from 'react';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import api from '../../Services/Api';
import history from '../../Router/history';
import ClassyAlert from '../ClassyAlert/ClassyAlert';
import ClassyButton from '../ClassyButton/ClassyButton';
import './ResetPassword.scss';

export class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      isValidated: false,
      emailSentMessage: '',
      showAlert: false,
      alertMessage: '',
    };

    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onSubmitResetPassword = this.onSubmitResetPassword.bind(this);
    this.setAlert = this.setAlert.bind(this);
  }

  setAlert(alertMessage, showAlert = true) {
    this.setState({ alertMessage, showAlert });
  }

  onChangeEmail(e) {
    this.setState({ email: e.target.value });
  }

  async onSubmitResetPassword(event) {
    event.preventDefault();
    const { email } = this.state;

    try {
      const passwordResetResponse = await api.resetUserPassword({
        email,
      });
      if (passwordResetResponse.success) {
        const responseMessage = passwordResetResponse.data;
        this.setState({
          email: '',
          emailSentMessage: responseMessage,
          isValidated: true,
        });
      }
    } catch (exception) {
      const message = exception.errors;
      this.setAlert(message);
    }
  }

  render() {
    const { email, emailSentMessage, isValidated } = this.state;

    return (
      <div id="resetPassword">
        <ClassyAlert
          show={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />

        <h2 className="title-text">Reset Password</h2>

        <span>Enter the email address associated with your account.</span>
        <Form onSubmit={(e) => e.preventDefault()} className="password-form">
          <FormGroup>
            <FormControl onChange={this.onChangeEmail} placeholder="Email" type="text" value={email} />
            <ClassyButton disabled={!email} title="Submit" onClick={this.onSubmitResetPassword} />
          </FormGroup>
        </Form>

        {isValidated ? <div className="margin-vertical-large">{emailSentMessage}</div> : null}

        <div>
          <i className="fa fa-arrow-left padding-medium" />
          <button className="btn-link" onClick={() => history.push('/')}>
            Back to Login Page
          </button>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
