export enum RlcCommandLogStatus {
  Pending = 'pending',
  Active = 'active',
  Failed = 'failed',
  Completed = 'completed',
}

// defined in api
export interface SuccessChildRlcTaskResult<T> {
  success: true;
  payload: T;
}

// defined in api
export interface FailedChildRlcTaskResult<TaskData, ErrorPayload = unknown> {
  success: false;
  message: string;
  taskData: TaskData;
  payload?: ErrorPayload;
}

// defined in api
export type ChildRlcTaskResult<Result, TaskData, ErrorPayload = unknown> =
  | SuccessChildRlcTaskResult<Result>
  | FailedChildRlcTaskResult<TaskData, ErrorPayload>;

interface AddDesignationTaskData {
  childDesignationId: number;
  allowExisting: boolean;
}
interface AddDesignationErrorPayload {
  childDesignationFound: true;
  message: string;
}

export interface GenericFailedTaskLogEntry {
  status?: string;
}

export interface AddDesignationTaskLogEntry {
  designations: Array<ChildRlcTaskResult<unknown, AddDesignationTaskData, AddDesignationErrorPayload>>;
  designations_failureCount?: number;
  status: RlcCommandLogStatus;
}

export interface AddCampaignTaskLogEntry {
  campaigns: Array<ChildRlcTaskResult<unknown, unknown, unknown>>;
  status: RlcCommandLogStatus;
}

export type LogEntry = GenericFailedTaskLogEntry | AddDesignationTaskLogEntry | AddCampaignTaskLogEntry;

export interface RlcCommandExecutionLog {
  id: number;
  status: RlcCommandLogStatus;
  log?: LogEntry;
  createdAt: Date;
  updatedAt: Date;
}

export interface RlcCommandLog {
  id: number;
  rlcGroupId: number;
  jobId: string;
  command: string;
  status: RlcCommandLogStatus;
  entries: Array<RlcCommandExecutionLog>;
  createdAt: Date;
  updatedAt: Date;
}
