import React from 'react';

// Locations
const COUNTRIES = [
  {
    code: 'AF',
    name: 'Afghanistan',
  },
  {
    code: 'AX',
    name: 'Aland Islands',
  },
  {
    code: 'AL',
    name: 'Albania',
  },
  {
    code: 'DZ',
    name: 'Algeria',
  },
  {
    code: 'AS',
    name: 'American Samoa',
  },
  {
    code: 'AD',
    name: 'Andorra',
  },
  {
    code: 'AO',
    name: 'Angola',
  },
  {
    code: 'AI',
    name: 'Anguilla',
  },
  {
    code: 'AQ',
    name: 'Antarctica',
  },
  {
    code: 'AG',
    name: 'Antigua and Barbuda',
  },
  {
    code: 'AR',
    name: 'Argentina',
  },
  {
    code: 'AM',
    name: 'Armenia',
  },
  {
    code: 'AW',
    name: 'Aruba',
  },
  {
    code: 'AU',
    name: 'Australia',
  },
  {
    code: 'AT',
    name: 'Austria',
  },
  {
    code: 'AZ',
    name: 'Azerbaijan',
  },
  {
    code: 'BS',
    name: 'Bahamas',
  },
  {
    code: 'BH',
    name: 'Bahrain',
  },
  {
    code: 'BD',
    name: 'Bangladesh',
  },
  {
    code: 'BB',
    name: 'Barbados',
  },
  {
    //   code: 'BY',
    //   name: 'Belarus',
    // }, {
    code: 'BE',
    name: 'Belgium',
  },
  {
    code: 'BZ',
    name: 'Belize',
  },
  {
    code: 'BJ',
    name: 'Benin',
  },
  {
    code: 'BM',
    name: 'Bermuda',
  },
  {
    code: 'BT',
    name: 'Bhutan',
  },
  {
    code: 'BO',
    name: 'Bolivia',
  },
  {
    code: 'BQ',
    name: 'Bonaire, Saint Eustatius and Saba ',
  },
  {
    code: 'BA',
    name: 'Bosnia and Herzegovina',
  },
  {
    code: 'BW',
    name: 'Botswana',
  },
  {
    code: 'BV',
    name: 'Bouvet Island',
  },
  {
    code: 'BR',
    name: 'Brazil',
  },
  {
    code: 'IO',
    name: 'British Indian Ocean Territory',
  },
  {
    code: 'VG',
    name: 'British Virgin Islands',
  },
  {
    code: 'BN',
    name: 'Brunei',
  },
  {
    code: 'BG',
    name: 'Bulgaria',
  },
  {
    code: 'BF',
    name: 'Burkina Faso',
  },
  {
    code: 'BI',
    name: 'Burundi',
  },
  {
    code: 'KH',
    name: 'Cambodia',
  },
  {
    code: 'CM',
    name: 'Cameroon',
  },
  {
    code: 'CA',
    name: 'Canada',
  },
  {
    code: 'CV',
    name: 'Cape Verde',
  },
  {
    code: 'KY',
    name: 'Cayman Islands',
  },
  {
    code: 'CF',
    name: 'Central African Republic',
  },
  {
    code: 'TD',
    name: 'Chad',
  },
  {
    code: 'CL',
    name: 'Chile',
  },
  {
    code: 'CN',
    name: 'China',
  },
  {
    code: 'CX',
    name: 'Christmas Island',
  },
  {
    code: 'CC',
    name: 'Cocos Islands',
  },
  {
    code: 'CO',
    name: 'Colombia',
  },
  {
    code: 'KM',
    name: 'Comoros',
  },
  {
    code: 'CK',
    name: 'Cook Islands',
  },
  {
    code: 'CR',
    name: 'Costa Rica',
  },
  {
    code: 'HR',
    name: 'Croatia',
  },
  {
    code: 'CU',
    name: 'Cuba',
  },
  {
    code: 'CW',
    name: 'Curacao',
  },
  {
    code: 'CY',
    name: 'Cyprus',
  },
  {
    code: 'CZ',
    name: 'Czech Republic',
  },
  {
    //   code: 'CD',
    //   name: 'Democratic Republic of the Congo',
    // }, {
    code: 'DK',
    name: 'Denmark',
  },
  {
    code: 'DJ',
    name: 'Djibouti',
  },
  {
    code: 'DM',
    name: 'Dominica',
  },
  {
    code: 'DO',
    name: 'Dominican Republic',
  },
  {
    code: 'TL',
    name: 'East Timor',
  },
  {
    code: 'EC',
    name: 'Ecuador',
  },
  {
    code: 'EG',
    name: 'Egypt',
  },
  {
    code: 'SV',
    name: 'El Salvador',
  },
  {
    code: 'GQ',
    name: 'Equatorial Guinea',
  },
  {
    code: 'ER',
    name: 'Eritrea',
  },
  {
    code: 'EE',
    name: 'Estonia',
  },
  {
    code: 'ET',
    name: 'Ethiopia',
  },
  {
    code: 'FK',
    name: 'Falkland Islands',
  },
  {
    code: 'FO',
    name: 'Faroe Islands',
  },
  {
    code: 'FJ',
    name: 'Fiji',
  },
  {
    code: 'FI',
    name: 'Finland',
  },
  {
    code: 'FR',
    name: 'France',
  },
  {
    code: 'GF',
    name: 'French Guiana',
  },
  {
    code: 'PF',
    name: 'French Polynesia',
  },
  {
    code: 'TF',
    name: 'French Southern Territories',
  },
  {
    code: 'GA',
    name: 'Gabon',
  },
  {
    code: 'GM',
    name: 'Gambia',
  },
  {
    code: 'GE',
    name: 'Georgia',
  },
  {
    code: 'DE',
    name: 'Germany',
  },
  {
    code: 'GH',
    name: 'Ghana',
  },
  {
    code: 'GI',
    name: 'Gibraltar',
  },
  {
    code: 'GR',
    name: 'Greece',
  },
  {
    code: 'GL',
    name: 'Greenland',
  },
  {
    code: 'GD',
    name: 'Grenada',
  },
  {
    code: 'GP',
    name: 'Guadeloupe',
  },
  {
    code: 'GU',
    name: 'Guam',
  },
  {
    code: 'GT',
    name: 'Guatemala',
  },
  {
    code: 'GG',
    name: 'Guernsey',
  },
  {
    code: 'GN',
    name: 'Guinea',
  },
  {
    code: 'GW',
    name: 'Guinea-Bissau',
  },
  {
    code: 'GY',
    name: 'Guyana',
  },
  {
    code: 'HT',
    name: 'Haiti',
  },
  {
    code: 'HM',
    name: 'Heard Island and McDonald Islands',
  },
  {
    code: 'HN',
    name: 'Honduras',
  },
  {
    code: 'HK',
    name: 'Hong Kong',
  },
  {
    code: 'HU',
    name: 'Hungary',
  },
  {
    code: 'IS',
    name: 'Iceland',
  },
  {
    code: 'IN',
    name: 'India',
  },
  {
    code: 'ID',
    name: 'Indonesia',
  },
  {
    //   code: 'IR',
    //   name: 'Iran',
    // }, {
    //   code: 'IQ',
    //   name: 'Iraq',
    // }, {
    code: 'IE',
    name: 'Ireland',
  },
  {
    code: 'IM',
    name: 'Isle of Man',
  },
  {
    code: 'IL',
    name: 'Israel',
  },
  {
    code: 'IT',
    name: 'Italy',
  },
  {
    //   code: 'CI',
    //   name: 'Ivory Coast',
    // }, {
    code: 'JM',
    name: 'Jamaica',
  },
  {
    code: 'JP',
    name: 'Japan',
  },
  {
    code: 'JE',
    name: 'Jersey',
  },
  {
    code: 'JO',
    name: 'Jordan',
  },
  {
    code: 'KZ',
    name: 'Kazakhstan',
  },
  {
    code: 'KE',
    name: 'Kenya',
  },
  {
    code: 'KI',
    name: 'Kiribati',
  },
  {
    code: 'XK',
    name: 'Kosovo',
  },
  {
    code: 'KW',
    name: 'Kuwait',
  },
  {
    code: 'KG',
    name: 'Kyrgyzstan',
  },
  {
    code: 'LA',
    name: 'Laos',
  },
  {
    code: 'LV',
    name: 'Latvia',
  },
  {
    code: 'LB',
    name: 'Lebanon',
  },
  {
    code: 'LS',
    name: 'Lesotho',
  },
  {
    //   code: 'LR',
    //   name: 'Liberia',
    // }, {
    code: 'LY',
    name: 'Libya',
  },
  {
    code: 'LI',
    name: 'Liechtenstein',
  },
  {
    code: 'LT',
    name: 'Lithuania',
  },
  {
    code: 'LU',
    name: 'Luxembourg',
  },
  {
    code: 'MO',
    name: 'Macao',
  },
  {
    code: 'MK',
    name: 'Macedonia',
  },
  {
    code: 'MG',
    name: 'Madagascar',
  },
  {
    code: 'MW',
    name: 'Malawi',
  },
  {
    code: 'MY',
    name: 'Malaysia',
  },
  {
    code: 'MV',
    name: 'Maldives',
  },
  {
    code: 'ML',
    name: 'Mali',
  },
  {
    code: 'MT',
    name: 'Malta',
  },
  {
    code: 'MH',
    name: 'Marshall Islands',
  },
  {
    code: 'MQ',
    name: 'Martinique',
  },
  {
    code: 'MR',
    name: 'Mauritania',
  },
  {
    code: 'MU',
    name: 'Mauritius',
  },
  {
    code: 'YT',
    name: 'Mayotte',
  },
  {
    code: 'MX',
    name: 'Mexico',
  },
  {
    code: 'FM',
    name: 'Micronesia',
  },
  {
    code: 'MD',
    name: 'Moldova',
  },
  {
    code: 'MC',
    name: 'Monaco',
  },
  {
    code: 'MN',
    name: 'Mongolia',
  },
  {
    code: 'ME',
    name: 'Montenegro',
  },
  {
    code: 'MS',
    name: 'Montserrat',
  },
  {
    code: 'MA',
    name: 'Morocco',
  },
  {
    code: 'MZ',
    name: 'Mozambique',
  },
  {
    code: 'MM',
    name: 'Myanmar',
  },
  {
    code: 'NA',
    name: 'Namibia',
  },
  {
    code: 'NR',
    name: 'Nauru',
  },
  {
    code: 'NP',
    name: 'Nepal',
  },
  {
    code: 'NL',
    name: 'Netherlands',
  },
  {
    code: 'NC',
    name: 'New Caledonia',
  },
  {
    code: 'NZ',
    name: 'New Zealand',
  },
  {
    code: 'NI',
    name: 'Nicaragua',
  },
  {
    code: 'NE',
    name: 'Niger',
  },
  {
    code: 'NG',
    name: 'Nigeria',
  },
  {
    code: 'NU',
    name: 'Niue',
  },
  {
    code: 'NF',
    name: 'Norfolk Island',
  },
  {
    //   code: 'KP',
    //   name: 'North Korea',
    // }, {
    code: 'MP',
    name: 'Northern Mariana Islands',
  },
  {
    code: 'NO',
    name: 'Norway',
  },
  {
    code: 'OM',
    name: 'Oman',
  },
  {
    code: 'PK',
    name: 'Pakistan',
  },
  {
    code: 'PW',
    name: 'Palau',
  },
  {
    code: 'PS',
    name: 'Palestinian Territory',
  },
  {
    code: 'PA',
    name: 'Panama',
  },
  {
    code: 'PG',
    name: 'Papua New Guinea',
  },
  {
    code: 'PY',
    name: 'Paraguay',
  },
  {
    code: 'PE',
    name: 'Peru',
  },
  {
    code: 'PH',
    name: 'Philippines',
  },
  {
    code: 'PN',
    name: 'Pitcairn',
  },
  {
    code: 'PL',
    name: 'Poland',
  },
  {
    code: 'PT',
    name: 'Portugal',
  },
  {
    code: 'PR',
    name: 'Puerto Rico',
  },
  {
    code: 'QA',
    name: 'Qatar',
  },
  {
    code: 'CG',
    name: 'Republic of the Congo',
  },
  {
    code: 'RE',
    name: 'Reunion',
  },
  {
    code: 'RO',
    name: 'Romania',
  },
  {
    code: 'RU',
    name: 'Russia',
  },
  {
    code: 'RW',
    name: 'Rwanda',
  },
  {
    code: 'BL',
    name: 'Saint Barthelemy',
  },
  {
    code: 'SH',
    name: 'Saint Helena',
  },
  {
    code: 'KN',
    name: 'Saint Kitts and Nevis',
  },
  {
    code: 'LC',
    name: 'Saint Lucia',
  },
  {
    code: 'MF',
    name: 'Saint Martin',
  },
  {
    code: 'PM',
    name: 'Saint Pierre and Miquelon',
  },
  {
    code: 'VC',
    name: 'Saint Vincent and the Grenadines',
  },
  {
    code: 'WS',
    name: 'Samoa',
  },
  {
    code: 'SM',
    name: 'San Marino',
  },
  {
    code: 'ST',
    name: 'Sao Tome and Principe',
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
  },
  {
    code: 'SN',
    name: 'Senegal',
  },
  {
    code: 'RS',
    name: 'Serbia',
  },
  {
    code: 'SC',
    name: 'Seychelles',
  },
  {
    //   code: 'SL',
    //   name: 'Sierra Leone',
    // }, {
    code: 'SG',
    name: 'Singapore',
  },
  {
    code: 'SX',
    name: 'Sint Maarten',
  },
  {
    code: 'SK',
    name: 'Slovakia',
  },
  {
    code: 'SI',
    name: 'Slovenia',
  },
  {
    code: 'SB',
    name: 'Solomon Islands',
  },
  {
    //   code: 'SO',
    //   name: 'Somalia',
    // }, {
    code: 'ZA',
    name: 'South Africa',
  },
  {
    code: 'GS',
    name: 'South Georgia and the South Sandwich Islands',
  },
  {
    code: 'KR',
    name: 'South Korea',
  },
  {
    code: 'SS',
    name: 'South Sudan',
  },
  {
    code: 'ES',
    name: 'Spain',
  },
  {
    code: 'LK',
    name: 'Sri Lanka',
  },
  {
    //   code: 'SD',
    //   name: 'Sudan',
    // }, {
    code: 'SR',
    name: 'Suriname',
  },
  {
    code: 'SJ',
    name: 'Svalbard and Jan Mayen',
  },
  {
    code: 'SZ',
    name: 'Swaziland',
  },
  {
    code: 'SE',
    name: 'Sweden',
  },
  {
    code: 'CH',
    name: 'Switzerland',
  },
  {
    //   code: 'SY',
    //   name: 'Syria',
    // }, {
    code: 'TW',
    name: 'Taiwan',
  },
  {
    code: 'TJ',
    name: 'Tajikistan',
  },
  {
    code: 'TZ',
    name: 'Tanzania',
  },
  {
    code: 'TH',
    name: 'Thailand',
  },
  {
    code: 'TG',
    name: 'Togo',
  },
  {
    code: 'TK',
    name: 'Tokelau',
  },
  {
    code: 'TO',
    name: 'Tonga',
  },
  {
    code: 'TT',
    name: 'Trinidad and Tobago',
  },
  {
    code: 'TN',
    name: 'Tunisia',
  },
  {
    code: 'TR',
    name: 'Turkey',
  },
  {
    code: 'TM',
    name: 'Turkmenistan',
  },
  {
    code: 'TC',
    name: 'Turks and Caicos Islands',
  },
  {
    code: 'TV',
    name: 'Tuvalu',
  },
  {
    code: 'VI',
    name: 'U.S. Virgin Islands',
  },
  {
    code: 'UG',
    name: 'Uganda',
  },
  {
    code: 'UA',
    name: 'Ukraine',
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
  },
  {
    code: 'GB',
    name: 'United Kingdom',
  },
  {
    code: 'US',
    name: 'United States',
  },
  {
    code: 'UM',
    name: 'United States Minor Outlying Islands',
  },
  {
    code: 'UY',
    name: 'Uruguay',
  },
  {
    code: 'UZ',
    name: 'Uzbekistan',
  },
  {
    code: 'VU',
    name: 'Vanuatu',
  },
  {
    code: 'VA',
    name: 'Vatican',
  },
  {
    code: 'VE',
    name: 'Venezuela',
  },
  {
    code: 'VN',
    name: 'Vietnam',
  },
  {
    code: 'WF',
    name: 'Wallis and Futuna',
  },
  {
    code: 'EH',
    name: 'Western Sahara',
  },
  {
    code: 'YE',
    name: 'Yemen',
  },
  {
    code: 'ZM',
    name: 'Zambia',
  },
  //   {
  //   code: 'ZW',
  //   name: 'Zimbabwe',
  // }
];

const STATES = [
  { label: 'Select', value: null },
  {
    label: 'Alabama',
    value: 'AL',
    cities: [
      {
        label: 'Birmingham',
        value: 'Birmingham',
      },
      {
        label: 'Huntsville',
        value: 'Huntsville',
      },
      {
        label: 'Montgomery',
        value: 'Montgomery',
      },
      {
        label: 'Mobile',
        value: 'Mobile',
      },
      {
        label: 'Tuscaloosa',
        value: 'Tuscaloosa',
      },
    ],
  },
  {
    label: 'Alaska',
    value: 'AK',
    cities: [
      {
        value: 'Anchorage',
        label: 'Anchorage',
      },
    ],
  },
  {
    label: 'American Samoa',
    value: 'AS',
    cities: [
      {
        label: 'American Samoa',
        value: 'AS',
      },
    ],
  },
  {
    label: 'Arizona',
    value: 'AZ',
    cities: [
      {
        label: 'Phoenix',
        value: 'Phoenix',
      },
      {
        label: 'Tucson',
        value: 'Tucson',
      },
      {
        label: 'Mesa',
        value: 'Mesa',
      },
      {
        label: 'Chandler',
        value: 'Chandler',
      },
      {
        label: 'Scottsdale',
        value: 'Scottsdale',
      },
      {
        label: 'Gilbert',
        value: 'Gilbert',
      },
      {
        label: 'Glendale',
        value: 'Glendale',
      },
      {
        label: 'Tempe',
        value: 'Tempe',
      },
      {
        label: 'Peoria',
        value: 'Peoria',
      },
      {
        label: 'Surprise',
        value: 'Surprise',
      },
    ],
  },
  {
    label: 'Arkansas',
    value: 'AR',
    cities: [
      {
        label: 'Little Rock',
        value: 'Little Rock',
      },
    ],
  },
  {
    label: 'Armed Forces (the) Americas',
    value: 'AA',
    cities: [
      {
        label: 'Armed Forces (the) Americas',
        value: 'AA',
      },
    ],
  },
  {
    label: 'Armed Forces Europe',
    value: 'AE',
    cities: [
      {
        label: 'Armed Forces Europe',
        value: 'AE',
      },
    ],
  },
  {
    label: 'Armed Forces Pacific',
    value: 'AP',
    cities: [
      {
        label: 'Armed Forces Pacific',
        value: 'AP',
      },
    ],
  },
  {
    label: 'California',
    value: 'CA',
    cities: [
      {
        label: 'Los Angeles',
        value: 'Los Angeles',
      },
      {
        label: 'San Diego',
        value: 'San Diego',
      },
      {
        label: 'San Jose',
        value: 'San Jose',
      },
      {
        label: 'San Francisco[g]',
        value: 'San Francisco[g]',
      },
      {
        label: 'Fresno',
        value: 'Fresno',
      },
      {
        label: 'Sacramento',
        value: 'Sacramento',
      },
      {
        label: 'Long Beach',
        value: 'Long Beach',
      },
      {
        label: 'Oakland',
        value: 'Oakland',
      },
      {
        label: 'Bakersfield',
        value: 'Bakersfield',
      },
      {
        label: 'Anaheim',
        value: 'Anaheim',
      },
      {
        label: 'Santa Ana',
        value: 'Santa Ana',
      },
      {
        label: 'Riverside',
        value: 'Riverside',
      },
      {
        label: 'Stockton',
        value: 'Stockton',
      },
      {
        label: 'Irvine',
        value: 'Irvine',
      },
      {
        label: 'Chula Vista',
        value: 'Chula Vista',
      },
      {
        label: 'Fremont',
        value: 'Fremont',
      },
      {
        label: 'San Bernardino',
        value: 'San Bernardino',
      },
      {
        label: 'Modesto',
        value: 'Modesto',
      },
      {
        label: 'Fontana',
        value: 'Fontana',
      },
      {
        label: 'Moreno Valley',
        value: 'Moreno Valley',
      },
      {
        label: 'Santa Clarita',
        value: 'Santa Clarita',
      },
      {
        label: 'Oxnard',
        value: 'Oxnard',
      },
      {
        label: 'Glendale',
        value: 'Glendale',
      },
      {
        label: 'Huntington Beach',
        value: 'Huntington Beach',
      },
      {
        label: 'Ontario',
        value: 'Ontario',
      },
      {
        label: 'Rancho Cucamonga',
        value: 'Rancho Cucamonga',
      },
      {
        label: 'Santa Rosa',
        value: 'Santa Rosa',
      },
      {
        label: 'Oceanside',
        value: 'Oceanside',
      },
      {
        label: 'Elk Grove',
        value: 'Elk Grove',
      },
      {
        label: 'Garden Grove',
        value: 'Garden Grove',
      },
      {
        label: 'Corona',
        value: 'Corona',
      },
      {
        label: 'Hayward',
        value: 'Hayward',
      },
      {
        label: 'Lancaster',
        value: 'Lancaster',
      },
      {
        label: 'Salinas',
        value: 'Salinas',
      },
      {
        label: 'Palmdale',
        value: 'Palmdale',
      },
      {
        label: 'Sunnyvale',
        value: 'Sunnyvale',
      },
      {
        label: 'Pomona',
        value: 'Pomona',
      },
      {
        label: 'Escondido',
        value: 'Escondido',
      },
      {
        label: 'Torrance',
        value: 'Torrance',
      },
      {
        label: 'Roseville',
        value: 'Roseville',
      },
      {
        label: 'Pasadena',
        value: 'Pasadena',
      },
      {
        label: 'Orange',
        value: 'Orange',
      },
      {
        label: 'Fullerton',
        value: 'Fullerton',
      },
      {
        label: 'Visalia',
        value: 'Visalia',
      },
      {
        label: 'Santa Clara',
        value: 'Santa Clara',
      },
      {
        label: 'Concord',
        value: 'Concord',
      },
      {
        label: 'Thousand Oaks',
        value: 'Thousand Oaks',
      },
      {
        label: 'Simi Valley',
        value: 'Simi Valley',
      },
      {
        label: 'Victorville',
        value: 'Victorville',
      },
      {
        label: 'Vallejo',
        value: 'Vallejo',
      },
      {
        label: 'Berkeley',
        value: 'Berkeley',
      },
      {
        label: 'Fairfield',
        value: 'Fairfield',
      },
      {
        label: 'Murrieta',
        value: 'Murrieta',
      },
      {
        label: 'El Monte',
        value: 'El Monte',
      },
      {
        label: 'Carlsbad',
        value: 'Carlsbad',
      },
      {
        label: 'Temecula',
        value: 'Temecula',
      },
      {
        label: 'Clovis',
        value: 'Clovis',
      },
      {
        label: 'Costa Mesa',
        value: 'Costa Mesa',
      },
      {
        label: 'Antioch',
        value: 'Antioch',
      },
      {
        label: 'Downey',
        value: 'Downey',
      },
      {
        label: 'Richmond',
        value: 'Richmond',
      },
      {
        label: 'Jurupa Valley',
        value: 'Jurupa Valley',
      },
      {
        label: 'Ventura[ab]',
        value: 'Ventura[ab]',
      },
      {
        label: 'Inglewood',
        value: 'Inglewood',
      },
      {
        label: 'Santa Maria',
        value: 'Santa Maria',
      },
      {
        label: 'Daly City',
        value: 'Daly City',
      },
      {
        label: 'West Covina',
        value: 'West Covina',
      },
      {
        label: 'San Mateo',
        value: 'San Mateo',
      },
      {
        label: 'Norwalk',
        value: 'Norwalk',
      },
      {
        label: 'Rialto',
        value: 'Rialto',
      },
      {
        label: 'Chico',
        value: 'Chico',
      },
      {
        label: 'El Cajon',
        value: 'El Cajon',
      },
      {
        label: 'Burbank',
        value: 'Burbank',
      },
      {
        label: 'Vista',
        value: 'Vista',
      },
      {
        label: 'Vacaville',
        value: 'Vacaville',
      },
    ],
  },
  {
    label: 'Colorado',
    value: 'CO',
    cities: [
      {
        label: 'Denver[i]',
        value: 'Denver[i]',
      },
      {
        label: 'Colorado Springs',
        value: 'Colorado Springs',
      },
      {
        label: 'Aurora',
        value: 'Aurora',
      },
      {
        label: 'Fort Collins',
        value: 'Fort Collins',
      },
      {
        label: 'Lakewood',
        value: 'Lakewood',
      },
      {
        label: 'Thornton',
        value: 'Thornton',
      },
      {
        label: 'Arvada',
        value: 'Arvada',
      },
      {
        label: 'Westminster',
        value: 'Westminster',
      },
      {
        label: 'Pueblo',
        value: 'Pueblo',
      },
      {
        label: 'Centennial',
        value: 'Centennial',
      },
      {
        label: 'Greeley',
        value: 'Greeley',
      },
      {
        label: 'Boulder',
        value: 'Boulder',
      },
    ],
  },
  {
    label: 'Connecticut',
    value: 'CT',
    cities: [
      {
        label: 'Bridgeport',
        value: 'Bridgeport',
      },
      {
        label: 'New Haven',
        value: 'New Haven',
      },
      {
        label: 'Stamford',
        value: 'Stamford',
      },
      {
        label: 'Hartford',
        value: 'Hartford',
      },
      {
        label: 'Waterbury',
        value: 'Waterbury',
      },
    ],
  },
  {
    label: 'Delaware',
    value: 'DE',
    cities: [
      {
        label: 'Delaware City',
        value: 'Delaware City',
      },
      {
        label: 'Dover',
        value: 'Dover',
      },
      {
        label: 'Harrington',
        value: 'Harrington',
      },
      {
        label: 'Lewes',
        value: 'Lewes',
      },
      {
        label: 'Middletown',
        value: 'Middletown',
      },
      {
        label: 'Milford',
        value: 'Milford',
      },
      {
        label: 'New Castle',
        value: 'New Castle',
      },
      {
        label: 'Newark',
        value: 'Newark',
      },
      {
        label: 'Rehoboth Beach',
        value: 'Rehoboth Beach',
      },
      {
        label: 'Seaford',
        value: 'Seaford',
      },
      {
        label: 'Wilmington',
        value: 'Wilmington',
      },
    ],
  },

  {
    label: 'District of Columbia',
    value: 'DC',
    cities: [
      {
        label: 'Washington',
        value: 'Washington',
      },
    ],
  },
  {
    label: 'Florida',
    value: 'FL',
    cities: [
      {
        label: 'Jacksonville[f]',
        value: 'Jacksonville[f]',
      },
      {
        label: 'Miami',
        value: 'Miami',
      },
      {
        label: 'Tampa',
        value: 'Tampa',
      },
      {
        label: 'Orlando',
        value: 'Orlando',
      },
      {
        label: 'St. Petersburg',
        value: 'St. Petersburg',
      },
      {
        label: 'Hialeah',
        value: 'Hialeah',
      },
      {
        label: 'Port St. Lucie',
        value: 'Port St. Lucie',
      },
      {
        label: 'Tallahassee',
        value: 'Tallahassee',
      },
      {
        label: 'Cape Coral',
        value: 'Cape Coral',
      },
      {
        label: 'Fort Lauderdale',
        value: 'Fort Lauderdale',
      },
      {
        label: 'Pembroke Pines',
        value: 'Pembroke Pines',
      },
      {
        label: 'Hollywood',
        value: 'Hollywood',
      },
      {
        label: 'Miramar',
        value: 'Miramar',
      },
      {
        label: 'Gainesville',
        value: 'Gainesville',
      },
      {
        label: 'Coral Springs',
        value: 'Coral Springs',
      },
      {
        label: 'Clearwater',
        value: 'Clearwater',
      },
      {
        label: 'Palm Bay',
        value: 'Palm Bay',
      },
      {
        label: 'Lakeland',
        value: 'Lakeland',
      },
      {
        label: 'Pompano Beach',
        value: 'Pompano Beach',
      },
      {
        label: 'West Palm Beach',
        value: 'West Palm Beach',
      },
      {
        label: 'Miami Gardens',
        value: 'Miami Gardens',
      },
      {
        label: 'Davie[ac]',
        value: 'Davie[ac]',
      },
    ],
  },
  {
    label: 'Georgia',
    value: 'GA',
    cities: [
      {
        label: 'Atlanta',
        value: 'Atlanta',
      },
      {
        label: 'Augusta[t]',
        value: 'Augusta[t]',
      },
      {
        label: 'Columbus[u]',
        value: 'Columbus[u]',
      },
      {
        label: 'Macon[w]',
        value: 'Macon[w]',
      },
      {
        label: 'Savannah',
        value: 'Savannah',
      },
      {
        label: 'Athens[y]',
        value: 'Athens[y]',
      },
      {
        label: 'Sandy Springs',
        value: 'Sandy Springs',
      },
    ],
  },
  {
    label: 'Guam',
    value: 'GU',
    cities: [
      {
        label: 'Guam',
        value: 'GU',
      },
    ],
  },
  {
    label: 'Hawaii',
    value: 'HI',
    cities: [
      {
        label: 'Honolulu[b]',
        value: 'Honolulu[b]',
      },
    ],
  },
  {
    label: 'Idaho',
    value: 'ID',
    cities: [
      {
        label: 'Boise[r]',
        value: 'Boise[r]',
      },
      {
        label: 'Meridian',
        value: 'Meridian',
      },
    ],
  },
  {
    label: 'Illinois',
    value: 'IL',
    cities: [
      {
        label: 'Chicago',
        value: 'Chicago',
      },
      {
        label: 'Aurora',
        value: 'Aurora',
      },
      {
        label: 'Naperville',
        value: 'Naperville',
      },
      {
        label: 'Joliet',
        value: 'Joliet',
      },
      {
        label: 'Rockford',
        value: 'Rockford',
      },
      {
        label: 'Springfield',
        value: 'Springfield',
      },
      {
        label: 'Elgin',
        value: 'Elgin',
      },
      {
        label: 'Peoria',
        value: 'Peoria',
      },
    ],
  },
  {
    label: 'Indiana',
    value: 'IN',
    cities: [
      {
        label: 'Indianapolis[h]',
        value: 'Indianapolis[h]',
      },
      {
        label: 'Fort Wayne',
        value: 'Fort Wayne',
      },
      {
        label: 'Evansville',
        value: 'Evansville',
      },
      {
        label: 'South Bend',
        value: 'South Bend',
      },
      {
        label: 'Carmel',
        value: 'Carmel',
      },
    ],
  },
  {
    label: 'Iowa',
    value: 'IA',
    cities: [
      {
        label: 'Des Moines',
        value: 'Des Moines',
      },
      {
        label: 'Cedar Rapids',
        value: 'Cedar Rapids',
      },
      {
        label: 'Davenport',
        value: 'Davenport',
      },
    ],
  },
  {
    label: 'Kansas',
    value: 'KS',
    cities: [
      {
        label: 'Wichita',
        value: 'Wichita',
      },
      {
        label: 'Overland Park',
        value: 'Overland Park',
      },
      {
        label: 'Kansas City[x]',
        value: 'Kansas City[x]',
      },
      {
        label: 'Olathe',
        value: 'Olathe',
      },
      {
        label: 'Topeka',
        value: 'Topeka',
      },
    ],
  },
  {
    label: 'Kentucky',
    value: 'KY',
    cities: [
      {
        label: 'Louisville[l]',
        value: 'Louisville[l]',
      },
      {
        label: 'Lexington[o]',
        value: 'Lexington[o]',
      },
    ],
  },
  {
    label: 'Louisiana',
    value: 'LA',
    cities: [
      {
        label: 'New Orleans[n]',
        value: 'New Orleans[n]',
      },
      {
        label: 'Baton Rouge[s]',
        value: 'Baton Rouge[s]',
      },
      {
        label: 'Shreveport',
        value: 'Shreveport',
      },
      {
        label: 'Lafayette[z]',
        value: 'Lafayette[z]',
      },
    ],
  },
  {
    label: 'Maine',
    value: 'ME',
    cities: [
      {
        label: 'Portland',
        value: 'Portland',
      },
      {
        label: 'Lewiston',
        value: 'Lewiston',
      },
      {
        label: 'Bangor',
        value: 'Bangor',
      },
      {
        label: 'South Portland',
        value: 'South Portland',
      },
      {
        label: 'Auburn',
        value: 'Auburn',
      },
      {
        label: 'Biddeford',
        value: 'Biddeford',
      },
      {
        label: 'Sanford',
        value: 'Sanford',
      },
      {
        label: 'Saco',
        value: 'Saco',
      },
      {
        label: 'Westbrook',
        value: 'Westbrook',
      },
      {
        label: 'Augusta',
        value: 'Augusta',
      },
      {
        label: 'Waterville',
        value: 'Waterville',
      },
      {
        label: 'Brewer',
        value: 'Brewer',
      },
      {
        label: 'Presque Isle',
        value: 'Presque Isle',
      },
      {
        label: 'Bath',
        value: 'Bath',
      },
      {
        label: 'Ellsworth',
        value: 'Ellsworth',
      },
      {
        label: 'Caribou',
        value: 'Caribou',
      },
      {
        label: 'Old Town',
        value: 'Old Town',
      },
      {
        label: 'Rockland',
        value: 'Rockland',
      },
      {
        label: 'Belfast',
        value: 'Belfast',
      },
      {
        label: 'Gardiner',
        value: 'Gardiner',
      },
      {
        label: 'Calais',
        value: 'Calais',
      },
      {
        label: 'Hallowell',
        value: 'Hallowell',
      },
      {
        label: 'Eastport',
        value: 'Eastport',
      },
    ],
  },
  {
    label: 'Maryland',
    value: 'MD',
    cities: [
      {
        label: 'Baltimore[m]',
        value: 'Baltimore[m]',
      },
    ],
  },
  {
    label: 'Massachusetts',
    value: 'MA',
    cities: [
      {
        label: 'Boston',
        value: 'Boston',
      },
      {
        label: 'Worcester',
        value: 'Worcester',
      },
      {
        label: 'Springfield',
        value: 'Springfield',
      },
      {
        label: 'Cambridge',
        value: 'Cambridge',
      },
      {
        label: 'Lowell',
        value: 'Lowell',
      },
    ],
  },
  {
    label: 'Michigan',
    value: 'MI',
    cities: [
      {
        label: 'Detroit',
        value: 'Detroit',
      },
      {
        label: 'Grand Rapids',
        value: 'Grand Rapids',
      },
      {
        label: 'Warren',
        value: 'Warren',
      },
      {
        label: 'Sterling Heights',
        value: 'Sterling Heights',
      },
      {
        label: 'Ann Arbor',
        value: 'Ann Arbor',
      },
      {
        label: 'Lansing',
        value: 'Lansing',
      },
    ],
  },
  {
    label: 'Minnesota',
    value: 'MN',
    cities: [
      {
        label: 'Minneapolis',
        value: 'Minneapolis',
      },
      {
        label: 'Saint Paul',
        value: 'Saint Paul',
      },
      {
        label: 'Rochester',
        value: 'Rochester',
      },
    ],
  },
  {
    label: 'Mississippi',
    value: 'MS',
    cities: [
      {
        label: 'Jackson',
        value: 'Jackson',
      },
    ],
  },
  {
    label: 'Missouri',
    value: 'MO',
    cities: [
      {
        label: 'Kansas City',
        value: 'Kansas City',
      },
      {
        label: 'St. Louis[m]',
        value: 'St. Louis[m]',
      },
      {
        label: 'Springfield',
        value: 'Springfield',
      },
      {
        label: 'Columbia',
        value: 'Columbia',
      },
      {
        label: 'Independence',
        value: 'Independence',
      },
    ],
  },
  {
    label: 'Montana',
    value: 'MT',
    cities: [
      {
        label: 'Billings',
        value: 'Billings',
      },
    ],
  },
  {
    label: 'Nebraska',
    value: 'NE',
    cities: [
      {
        label: 'Omaha',
        value: 'Omaha',
      },
      {
        label: 'Lincoln',
        value: 'Lincoln',
      },
    ],
  },
  {
    label: 'Nevada',
    value: 'NV',
    cities: [
      {
        label: 'Las Vegas',
        value: 'Las Vegas',
      },
      {
        label: 'Henderson',
        value: 'Henderson',
      },
      {
        label: 'Reno',
        value: 'Reno',
      },
      {
        label: 'North Las Vegas',
        value: 'North Las Vegas',
      },
      {
        label: 'Sparks',
        value: 'Sparks',
      },
    ],
  },
  {
    label: 'New Hampshire',
    value: 'NH',
    cities: [
      {
        label: 'Manchester',
        value: 'Manchester',
      },
    ],
  },
  {
    label: 'New Jersey',
    value: 'NJ',
    cities: [
      {
        label: 'Newark',
        value: 'Newark',
      },
      {
        label: 'Jersey City',
        value: 'Jersey City',
      },
      {
        label: 'Paterson',
        value: 'Paterson',
      },
      {
        label: 'Elizabeth',
        value: 'Elizabeth',
      },
    ],
  },
  {
    label: 'New Mexico',
    value: 'NM',
    cities: [
      {
        label: 'Albuquerque',
        value: 'Albuquerque',
      },
      {
        label: 'Las Cruces',
        value: 'Las Cruces',
      },
    ],
  },
  {
    label: 'New York',
    value: 'NY',
    cities: [
      {
        label: 'New York City',
        value: 'New York City',
      },
      {
        label: 'Buffalo',
        value: 'Buffalo',
      },
      {
        label: 'Rochester',
        value: 'Rochester',
      },
      {
        label: 'Yonkers',
        value: 'Yonkers',
      },
      {
        label: 'Syracuse',
        value: 'Syracuse',
      },
    ],
  },
  {
    label: 'North Carolina',
    value: 'NC',
    cities: [
      {
        label: 'Charlotte',
        value: 'Charlotte',
      },
      {
        label: 'Raleigh',
        value: 'Raleigh',
      },
      {
        label: 'Greensboro',
        value: 'Greensboro',
      },
      {
        label: 'Durham',
        value: 'Durham',
      },
      {
        label: 'Winston–Salem',
        value: 'Winston–Salem',
      },
      {
        label: 'Fayetteville',
        value: 'Fayetteville',
      },
      {
        label: 'Cary',
        value: 'Cary',
      },
      {
        label: 'Wilmington',
        value: 'Wilmington',
      },
      {
        label: 'High Point',
        value: 'High Point',
      },
    ],
  },
  {
    label: 'North Dakota',
    value: 'ND',
    cities: [
      {
        label: 'Fargo',
        value: 'Fargo',
      },
    ],
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
    cities: [
      {
        label: 'Northern Mariana Islands',
        value: 'MP',
      },
    ],
  },
  {
    label: 'Ohio',
    value: 'OH',
    cities: [
      {
        label: 'Columbus',
        value: 'Columbus',
      },
      {
        label: 'Cleveland',
        value: 'Cleveland',
      },
      {
        label: 'Cincinnati',
        value: 'Cincinnati',
      },
      {
        label: 'Toledo',
        value: 'Toledo',
      },
      {
        label: 'Akron',
        value: 'Akron',
      },
      {
        label: 'Dayton',
        value: 'Dayton',
      },
    ],
  },
  {
    label: 'Oklahoma',
    value: 'OK',
    cities: [
      {
        label: 'Oklahoma City',
        value: 'Oklahoma City',
      },
      {
        label: 'Tulsa',
        value: 'Tulsa',
      },
      {
        label: 'Norman',
        value: 'Norman',
      },
      {
        label: 'Broken Arrow',
        value: 'Broken Arrow',
      },
    ],
  },
  {
    label: 'Oregon',
    value: 'OR',
    cities: [
      {
        label: 'Portland',
        value: 'Portland',
      },
      {
        label: 'Salem',
        value: 'Salem',
      },
      {
        label: 'Eugene',
        value: 'Eugene',
      },
      {
        label: 'Gresham',
        value: 'Gresham',
      },
    ],
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
    cities: [
      {
        label: 'Hillsboro',
        value: 'Hillsboro',
      },
      {
        label: 'Bend',
        value: 'Bend',
      },
      {
        label: 'Philadelphia[e]',
        value: 'Philadelphia[e]',
      },
    ],
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
    cities: [
      {
        label: 'Puerto Rico',
        value: 'PR',
      },
    ],
  },
  {
    label: 'Rhode Island',
    value: 'RI',
    cities: [
      {
        label: 'Pittsburgh',
        value: 'Pittsburgh',
      },
    ],
  },
  {
    label: 'South Carolina',
    value: 'SC',
    cities: [
      {
        label: 'Allentown',
        value: 'Allentown',
      },
      {
        label: 'Providence',
        value: 'Providence',
      },
      {
        label: 'Charleston',
        value: 'Charleston',
      },
    ],
  },
  {
    label: 'South Dakota',
    value: 'SD',
    cities: [
      {
        label: 'Columbia',
        value: 'Columbia',
      },
    ],
  },
  {
    label: 'Tennessee',
    value: 'TN',
    cities: [
      {
        label: 'North Charleston',
        value: 'North Charleston',
      },
      {
        label: 'Sioux Falls',
        value: 'Sioux Falls',
      },
      {
        label: 'Nashville[k]',
        value: 'Nashville[k]',
      },
      {
        label: 'Memphis',
        value: 'Memphis',
      },
      {
        label: 'Knoxville',
        value: 'Knoxville',
      },
      {
        label: 'Chattanooga',
        value: 'Chattanooga',
      },
    ],
  },
  {
    label: 'Texas',
    value: 'TX',
    cities: [
      {
        label: 'Clarksville',
        value: 'Clarksville',
      },
      {
        label: 'Murfreesboro',
        value: 'Murfreesboro',
      },
      {
        label: 'Houston[3]',
        value: 'Houston[3]',
      },
      {
        label: 'San Antonio',
        value: 'San Antonio',
      },
      {
        label: 'Dallas',
        value: 'Dallas',
      },
      {
        label: 'Austin',
        value: 'Austin',
      },
      {
        label: 'Fort Worth',
        value: 'Fort Worth',
      },
      {
        label: 'El Paso',
        value: 'El Paso',
      },
      {
        label: 'Arlington',
        value: 'Arlington',
      },
      {
        label: 'Corpus Christi',
        value: 'Corpus Christi',
      },
      {
        label: 'Plano',
        value: 'Plano',
      },
      {
        label: 'Laredo',
        value: 'Laredo',
      },
      {
        label: 'Lubbock',
        value: 'Lubbock',
      },
      {
        label: 'Garland',
        value: 'Garland',
      },
      {
        label: 'Irving',
        value: 'Irving',
      },
      {
        label: 'Frisco',
        value: 'Frisco',
      },
      {
        label: 'Amarillo',
        value: 'Amarillo',
      },
      {
        label: 'McKinney',
        value: 'McKinney',
      },
      {
        label: 'Grand Prairie',
        value: 'Grand Prairie',
      },
      {
        label: 'Brownsville',
        value: 'Brownsville',
      },
      {
        label: 'Killeen',
        value: 'Killeen',
      },
      {
        label: 'Pasadena',
        value: 'Pasadena',
      },
      {
        label: 'Midland',
        value: 'Midland',
      },
      {
        label: 'McAllen',
        value: 'McAllen',
      },
      {
        label: 'Denton',
        value: 'Denton',
      },
      {
        label: 'Mesquite',
        value: 'Mesquite',
      },
      {
        label: 'Carrollton',
        value: 'Carrollton',
      },
      {
        label: 'Waco',
        value: 'Waco',
      },
      {
        label: 'Round Rock',
        value: 'Round Rock',
      },
      {
        label: 'Abilene',
        value: 'Abilene',
      },
      {
        label: 'Odessa',
        value: 'Odessa',
      },
      {
        label: 'Pearland',
        value: 'Pearland',
      },
      {
        label: 'Richardson',
        value: 'Richardson',
      },
      {
        label: 'Sugar Land',
        value: 'Sugar Land',
      },
      {
        label: 'College Station',
        value: 'College Station',
      },
      {
        label: 'Beaumont',
        value: 'Beaumont',
      },
      {
        label: 'Lewisville',
        value: 'Lewisville',
      },
      {
        label: 'League City',
        value: 'League City',
      },
      {
        label: 'Tyler',
        value: 'Tyler',
      },
      {
        label: 'Allen',
        value: 'Allen',
      },
      {
        label: 'Wichita Falls',
        value: 'Wichita Falls',
      },
      {
        label: 'Edinburg',
        value: 'Edinburg',
      },
      {
        label: 'San Angelo',
        value: 'San Angelo',
      },
    ],
  },
  {
    label: 'United States Minor Outlying Islands',
    value: 'UM',
    cities: [
      {
        label: 'United States Minor Outlying Islands',
        value: 'UM',
      },
    ],
  },
  {
    label: 'Utah',
    value: 'UT',
    cities: [
      {
        label: 'Salt Lake City',
        value: 'Salt Lake City',
      },
      {
        label: 'West Valley City',
        value: 'West Valley City',
      },
      {
        label: 'Provo',
        value: 'Provo',
      },
      {
        label: 'West Jordan',
        value: 'West Jordan',
      },
    ],
  },
  {
    label: 'Vermont',
    value: 'VT',
    cities: [
      {
        label: 'Burlington',
        value: 'Burlington',
      },
      {
        label: 'South Burlington',
        value: 'South Burlington',
      },
      {
        label: 'Rutland',
        value: 'Rutland',
      },
      {
        label: 'Barre',
        value: 'Barre',
      },
      {
        label: 'Montpelier',
        value: 'Montpelier',
      },
      {
        label: 'Winooski',
        value: 'Winooski',
      },
      {
        label: 'St. Albans',
        value: 'St. Albans',
      },
      {
        label: 'Newport',
        value: 'Newport',
      },
      {
        label: 'Vergennes',
        value: 'Vergennes',
      },
    ],
  },
  {
    label: 'Virgin Islands',
    value: 'VI',
    cities: [
      {
        label: 'Virgin Islands',
        value: 'VI',
      },
    ],
  },
  {
    label: 'Virginia',
    value: 'VA',
    cities: [
      {
        label: 'Virginia Beach[m]',
        value: 'Virginia Beach[m]',
      },
      {
        label: 'Chesapeake[m]',
        value: 'Chesapeake[m]',
      },
      {
        label: 'Norfolk[m]',
        value: 'Norfolk[m]',
      },
      {
        label: 'Richmond[m]',
        value: 'Richmond[m]',
      },
      {
        label: 'Newport News[m]',
        value: 'Newport News[m]',
      },
      {
        label: 'Alexandria[m]',
        value: 'Alexandria[m]',
      },
      {
        label: 'Hampton[m]',
        value: 'Hampton[m]',
      },
    ],
  },
  {
    label: 'Washington',
    value: 'WA',
    cities: [
      {
        label: 'Seattle',
        value: 'Seattle',
      },
      {
        label: 'Spokane',
        value: 'Spokane',
      },
      {
        label: 'Tacoma',
        value: 'Tacoma',
      },
      {
        label: 'Vancouver',
        value: 'Vancouver',
      },
      {
        label: 'Bellevue',
        value: 'Bellevue',
      },
      {
        label: 'Kent',
        value: 'Kent',
      },
      {
        label: 'Everett',
        value: 'Everett',
      },
      {
        label: 'Renton',
        value: 'Renton',
      },
      {
        label: 'Spokane Valley',
        value: 'Spokane Valley',
      },
    ],
  },
  {
    label: 'West Virginia',
    value: 'WV',
    cities: [
      {
        label: 'Charleston',
        value: 'Charleston',
      },
      {
        label: 'Huntington',
        value: 'Huntington',
      },
      {
        label: 'Morgantown',
        value: 'Morgantown',
      },
      {
        label: 'Parkersburg',
        value: 'Parkersburg',
      },
      {
        label: 'Wheeling',
        value: 'Wheeling',
      },
      {
        label: 'Fairmont',
        value: 'Fairmont',
      },
      {
        label: 'Weirton',
        value: 'Weirton',
      },
      {
        label: 'Martinsburg',
        value: 'Martinsburg',
      },
      {
        label: 'Beckley',
        value: 'Beckley',
      },
      {
        label: 'Clarksburg',
        value: 'Clarksburg',
      },
    ],
  },
  {
    label: 'Wisconsin',
    value: 'WI',
    city: [
      {
        label: 'Green Bay',
        value: 'Green Bay',
      },
      {
        label: 'Madison',
        value: 'Madison',
      },
      {
        label: 'Milwaukee',
        value: 'Milwaukee',
      },
    ],
  },
  {
    label: 'Wyoming',
    value: 'WY',
    cities: [
      {
        label: 'Cheyenne',
        value: 'Cheyenne',
      },
      {
        label: 'Casper',
        value: 'Casper',
      },
      {
        label: 'Laramie',
        value: 'Laramie',
      },
      {
        label: 'Gillette',
        value: 'Gillette',
      },
      {
        label: 'Rock Springs',
        value: 'Rock Springs',
      },
      {
        label: 'Sheridan',
        value: 'Sheridan',
      },
      {
        label: 'Green River',
        value: 'Green River',
      },
      {
        label: 'Evanston',
        value: 'Evanston',
      },
      {
        label: 'Riverton',
        value: 'Riverton',
      },
      {
        label: 'Jackson',
        value: 'Jackson',
      },
    ],
  },
];

// Regional Variations
const CURRENCIES = ['AUD', 'CAD', 'EUR', 'GBP', 'USD'];

const getCurrencySign = (currency: string, classes: string) => {
  let signName;
  switch (currency) {
    case 'AUD':
    case 'CAD':
    case 'USD':
      signName = 'fa-dollar';
      break;
    case 'GBP':
      signName = 'fa-gbp';
      break;
    case 'EUR':
      signName = 'fa-euro';
      break;
    default:
      signName = 'fa-dollar';
      break;
  }
  return <i className={`fa ${signName} ${classes}`} />;
};

const getPhoneNumberLengthByCountry = (country: string): number => {
  let length;
  switch (country) {
    case 'AU':
    case 'CA':
    case 'ES':
    case 'FR':
    case 'GB':
    case 'US':
    default:
      length = 10;
      break;
  }
  return length;
};

const exports = {
  COUNTRIES,
  CURRENCIES,
  getCurrencySign,
  getPhoneNumberLengthByCountry,
  STATES,
};

export default exports;
