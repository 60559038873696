import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import constants from '../../Helpers/constants';
import { NotFound } from '../NotFound/NotFound';

import './WithAuthorization.scss';

export const checkRole = (feature, featurePermissions, roleId, userRole, selectedOrgId) => {
  let hasAccess = false;
  // If a role was set for the component being loaded, then let's verify the user's role matches
  // This would normally occur when a component requires the user to be an admin since a component that just requires a user would be open to all.
  const userRoleId = constants.ROLES.getRoleIdByName(userRole);

  if (
    roleId &&
    Number(roleId) === userRoleId &&
    featurePermissions[selectedOrgId] &&
    featurePermissions[selectedOrgId][feature]
  ) {
    hasAccess = true;
  } else if (
    typeof roleId === 'undefined' &&
    featurePermissions[selectedOrgId] &&
    featurePermissions[selectedOrgId][feature]
  ) {
    // In this condition, the component does not have a roleId limitation, and therefore we just check that the user has the feature
    hasAccess = true;
  }

  return hasAccess;
};

const PureAuthorization = (props) => {
  const { accessToken, Component, feature, featurePermissions, path, role, selectedOrgId, userRole } = props;

  const hasAccess = checkRole(feature, featurePermissions, role, userRole, selectedOrgId);
  const isSettingsPage = path === constants.ROUTES.ADMIN_SETTINGS;
  const isSystemOrg = selectedOrgId === constants.SYSTEM_ORGANIZATION_ID;

  if (hasAccess && isSettingsPage && !isSystemOrg) return <NotFound isUnauthorized accessToken={accessToken} />;

  if (hasAccess) return <Component {...props} />;

  if (!path) return <NotFound isUnauthorized accessToken={accessToken} />;

  return null;
};

PureAuthorization.propTypes = {
  Component: PropTypes.any.isRequired,
  feature: PropTypes.string.isRequired,
  selectedOrgId: PropTypes.number.isRequired,
  featurePermissions: PropTypes.object.isRequired,
  userRole: PropTypes.string,
  role: PropTypes.number,
  selectedOrganization: PropTypes.object,
  path: PropTypes.string,
};

const mapStateToProps = (state) => {
  const {
    featurePermissions,
    userRole,
    selectedOrganization: { id: selectedOrgId },
    accessToken,
  } = state.login;
  return {
    featurePermissions,
    userRole,
    selectedOrgId,
    accessToken,
  };
};

const WithAuthorization = connect(mapStateToProps)(PureAuthorization);

export default WithAuthorization;
