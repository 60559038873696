import moment from 'moment/moment';
import constants from '../../Helpers/constants';
import React from 'react';

const ANY_STATUS_VALUE = '';

function getLocalDate(value: moment.MomentInput): string {
  return moment.utc(value).local().format(constants.DATES.TABLE_DATE_FORMAT);
}

export const DateTimeCell = ({ value }: { value: moment.MomentInput }) => (
  <div>{value ? getLocalDate(value) : null}</div>
);

export const TimeAgoCell = ({ value }: { value: moment.MomentInput }) => (
  <div title={value ? getLocalDate(value) : '-'}>{value ? moment.utc(value).local().fromNow() : null}</div>
);

export interface DropDownFilterProps {
  options: Array<string>;
  filter: any;
  onChange: (val: string) => void;
}

export const DropDownFilter = ({ options, filter, onChange }: DropDownFilterProps) => {
  return (
    <select onChange={(e) => onChange(e.target.value)} style={{ width: '100%' }} value={filter ? filter.value : 'all'}>
      <option value={ANY_STATUS_VALUE}>any</option>
      {options.map((k) => (
        <option key={k.toString()} value={k}>
          {k}
        </option>
      ))}
    </select>
  );
};

export const dropDownColumnFilter = (elements: Array<string>) => (filter: any, row: any) =>
  elements.includes(filter.value) ? row[filter.id] === filter.value : true;
