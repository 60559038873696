import api from '../../../Services/Api';
import useSWR, { useSWRConfig } from 'swr';
import { Modal } from 'react-bootstrap';
import Badge from 'react-bootstrap/lib/Badge';
import { APIResponse } from '../../../Typings/Services/Api';
import './Queues.scss';
import constants from '../../../Helpers/constants';
import Queue from './Queue';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useState } from 'react';

const fetcher = (url: string) => api.api.get(url).then((res) => res);

interface QueueStatus {
  name: string;
  isPaused: boolean;
  active: number;
  completed: number;
  delayed: number;
  failed: number;
  waiting: number;
  waitingChildren: number;
}

interface QueueModal {
  isOpen: boolean;
  queueName?: string;
}

interface QueueStatusProps {
  queue: QueueStatus;
  onClearQueue: (name: string) => void;
  onStatusChanged: () => void;
}

const QueueStatusBadge = ({ isPaused }: { isPaused: boolean }) => (
  <Badge className={isPaused ? 'error' : 'active'}>{isPaused ? 'Paused' : 'Active'}</Badge>
);

interface QueueStatusRowProps {
  title: string;
  queueName: string;
  status: string;
  total: number;
}

const QueueStatusRow = ({ title, queueName, status, total }: QueueStatusRowProps) => (
  <>
    <div className="queue__status">
      <Link to={{ pathname: `${constants.ROUTES.QUEUES}/${queueName}/${status}` }}>{title}</Link>
    </div>
    <div className="queue__count">
      <Badge className="neutral">{total}</Badge>
    </div>
  </>
);

const QueueStatusInfo = ({ queue, onClearQueue, onStatusChanged }: QueueStatusProps) => {
  const onRestartClicked = async () => {
    await api.setQueueIsPaused(queue.name, false);
    onStatusChanged();
  };

  const onPauseClicked = async () => {
    await api.setQueueIsPaused(queue.name, true);
    onStatusChanged();
  };

  const onClearClicked = async () => {
    onClearQueue(queue.name);
  };

  return (
    <div className="queue__container">
      <div className="queue__header">{queue.name}</div>
      <div className="queue__status">Status</div>
      <div className="queue__count">
        <QueueStatusBadge isPaused={queue.isPaused} />
      </div>
      <QueueStatusRow queueName={queue.name} status="active" title="Active" total={queue.active} />
      <QueueStatusRow queueName={queue.name} status="completed" title="Completed" total={queue.completed} />
      <QueueStatusRow queueName={queue.name} status="failed" title="Failed" total={queue.failed} />
      <QueueStatusRow queueName={queue.name} status="delayed" title="Delayed" total={queue.delayed} />
      <QueueStatusRow queueName={queue.name} status="waiting" title="Waiting" total={queue.waiting} />
      <QueueStatusRow
        queueName={queue.name}
        status="waitingChildren"
        title="Waiting Children"
        total={queue.waitingChildren}
      />
      <div className="queue__footer">
        {queue.isPaused ? (
          <Button className="btn-primary" onClick={onRestartClicked}>
            Restart Queue
          </Button>
        ) : (
          <Button className="btn-danger" onClick={onPauseClicked}>
            Pause Queue
          </Button>
        )}
        <Button className="btn-danger" onClick={onClearClicked}>
          Clear Queue
        </Button>
      </div>
    </div>
  );
};

const Queues = () => {
  const { data: swrData } = useSWR<APIResponse<Array<QueueStatus>>>(`${api.apiUrl}queues`, fetcher as any);
  const { mutate } = useSWRConfig();

  const [queueModalInfo, setQueueModal] = useState<QueueModal>({ isOpen: false });
  const [loadingSoftClean, setLoadingSoftClean] = useState(false);
  const [loadingHardClean, setLoadingHardClean] = useState(false);

  const onStatusChanged = async () => {
    await mutate(`${api.apiUrl}queues`);
  };

  const onShowClearModal = (queueName: string) => setQueueModal({ isOpen: true, queueName });
  const onHideClearModal = () => setQueueModal({ isOpen: false });

  const onSoftClean = async () => {
    setLoadingSoftClean(true);
    await api.clearQueue(queueModalInfo.queueName ?? '', false);
    await onStatusChanged();
    setLoadingSoftClean(false);
    onHideClearModal();
  };

  const onHardClean = async () => {
    setLoadingHardClean(true);
    await api.clearQueue(queueModalInfo.queueName ?? '', true);
    await onStatusChanged();
    setLoadingHardClean(false);
    onHideClearModal();
  };

  return (
    <>
      <h4>Queues</h4>
      <div className="queues">
        {swrData &&
          swrData.success &&
          swrData.data.map((queue) => (
            <QueueStatusInfo
              key={queue.name}
              queue={queue}
              onClearQueue={onShowClearModal}
              onStatusChanged={onStatusChanged}
            />
          ))}
      </div>
      <Modal show={queueModalInfo.isOpen} onHide={onHideClearModal}>
        <Modal.Header closeButton>
          {<Modal.Title>Clear {queueModalInfo.queueName ?? ''} queue</Modal.Title>}
        </Modal.Header>
        <Modal.Body className="queues_modal">
          <div className="queues_modal__warning">
            <h2>Warning</h2>
            <p>
              The actions below will remove pending and/or active jobs, which could lead to data loss. Please reach out
              to the Bulk Import team before proceeding.
            </p>
          </div>
          <h3>Soft clean</h3>
          <p>
            This option will attempt to clear the queue's waiting, paused, completed and failed jobs.
            <br />
            This action might not clear all jobs, specially if there's dependencies between queues.
            <br />
            If that's the case, after the system is stabilized, use the second clear option to fully reset the queue.
            <br />
            <b>Changes are irreversible.</b>
          </p>
          <Button className="btn-danger" onClick={onSoftClean} disabled={loadingSoftClean || loadingHardClean}>
            {loadingSoftClean ? 'Loading...' : 'Soft clean'}
          </Button>
          <h3>Forcefully clean all</h3>
          <p>
            This option will clear every single job in the queue and restart it.
            <br />
            <b>Changes are irreversible.</b>
          </p>
          <Button className="btn-danger" onClick={onHardClean} disabled={loadingSoftClean || loadingHardClean}>
            {loadingHardClean ? 'Loading...' : 'Clean everything from the queue'}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

interface QueueSelectorProps {
  match: any;
}

const QueueSelector = ({ match }: QueueSelectorProps) => {
  const queue = match?.params?.id;
  const status = match?.params?.status;

  return queue && status ? <Queue name={queue} status={status} /> : <Queues />;
};

export default QueueSelector;
