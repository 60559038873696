import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

class ClassyAlert extends Component {
  static defaultProps = {
    alertMessage: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      show: this.props.show || false,
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.renderAlert = this.renderAlert.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.setState({ show: this.props.show });
    }
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  renderAlert() {
    const { alertMessage, onHide } = this.props;
    let message = alertMessage;
    if (Array.isArray(message) && message.length) {
      message = message.map((messageItem, i) => <div key={i}>{messageItem}</div>);
    }
    return (
      <Modal
        show={this.state.show}
        onHide={() => {
          this.handleClose();
          onHide?.();
        }}
      >
        <Modal.Body>{message}</Modal.Body>
      </Modal>
    );
  }

  render() {
    return <div>{this.renderAlert()}</div>;
  }
}

ClassyAlert.propTypes = {
  alertMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  onHide: PropTypes.func,
  show: PropTypes.bool.isRequired,
};

export default ClassyAlert;
