import React, { useEffect, useState } from 'react';
import history from '../../../Router/history';
import api from '../../../Services/Api';
import useSWR from 'swr';
import { APIPaginatedResponse } from '../../../Typings/Services/Api';
import Jobs from './Jobs';
import { BullMqJob } from './JobDetails';
import './Queues.scss';
import { Badge } from 'react-bootstrap';
import constants from '../../../Helpers/constants';

interface QueueProps {
  name: string;
  status: string;
}

export interface RlcQueueJobFilter {
  queue: string;
  status: string;
}

interface RlcQueueJobsFetcher {
  url: string;
  filter: RlcQueueJobFilter;
  name: string;
  status: string;
  currentPage: number;
  pageSize: number;
}

const fetcher = ({ url, name, status, currentPage, pageSize }: RlcQueueJobsFetcher) => {
  const searchParams = new URLSearchParams();
  searchParams.append('page', currentPage.toString());
  searchParams.append('pageSize', pageSize.toString());

  return api.api.get(`${url}/${encodeURIComponent(name)}/${status}?${searchParams.toString()}`).then((res) => res);
};

const Queue = (queueProps: QueueProps) => {
  const { name, status } = queueProps;

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [jobs, setJobs] = useState<Array<BullMqJob>>([]);

  const { data: swrData } = useSWR<APIPaginatedResponse<BullMqJob>>(
    { url: `${api.apiUrl}queues`, name, status, currentPage, pageSize },
    fetcher as any,
    {
      refreshInterval: 5000,
    },
  );

  useEffect(() => {
    try {
      if (swrData && swrData.success) {
        const { data, pagination } = swrData;
        setPageCount(pagination?.total?.pages || 0);
        setJobs(data ?? []);
      }
    } catch (error) {
      console.error('== error while retrieving jobs');
      const e = error as Error;
      console.error(e.message);
    }
  }, [swrData]);

  const onBackClick = () => history.replace(constants.ROUTES.QUEUES);

  return (
    <>
      <div className="queueheader">
        <div>Queue:</div>
        <div>
          <Badge>{name}</Badge>
        </div>
        <div>Status:</div>
        <div>
          <Badge>{status}</Badge>
        </div>
      </div>
      <>
        {jobs && (
          <Jobs jobs={jobs} pageCount={pageCount} onPageChanged={setCurrentPage} onPageSizeChanged={setPageSize} />
        )}
      </>
      <button className="btn-default" style={{ marginTop: '10px' }} onClick={onBackClick}>
        &lt;&lt; Back
      </button>
    </>
  );
};

export default Queue;
