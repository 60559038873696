import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const NavLink = ({ path, subLink, title }) => (
  <Panel>
    <Panel.Heading className="sidenav__nav-link">
      <LinkContainer to={path} className={subLink ? 'sidenav__nav-sublink' : null}>
        <Panel.Title>
          <Panel.Body className="sidenav__panel-body">
            <span>{title}</span>
          </Panel.Body>
        </Panel.Title>
      </LinkContainer>
    </Panel.Heading>
  </Panel>
);

NavLink.propTypes = {
  path: PropTypes.string.isRequired,
  subLink: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default NavLink;
