import React, { MouseEvent, useState } from 'react';
import { RowValidationError } from './Types';
import './DetailedErrors.scss';
import { csvRowOffset } from './Utils';

interface DetailedErrorsProps {
  errors: Array<RowValidationError>;
}

export const DetailedErrors = ({ errors }: DetailedErrorsProps) => {
  const [isOpen, setIsOpen] = useState(errors.length > 0);

  if (errors.length === 0) {
    return null;
  }

  const toggle = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const formatErrors = errors.map((error, i) => {
    return (
      <tr key={i}>
        <td>{error.error}</td>
        <td>{error.rows.map(csvRowOffset).join(', ')}</td>
      </tr>
    );
  });

  return (
    <>
      <button className="secondary-button detailed-errors__button" onClick={toggle}>
        Detailed Errors &gt;&gt;
      </button>

      <table className={`detailed-errors__errorlist ${isOpen ? '' : 'hide'}`}>
        <thead>
          <th>Error</th>
          <th>Row</th>
        </thead>
        <tbody>{formatErrors}</tbody>
      </table>
    </>
  );
};
