import React, { ButtonHTMLAttributes, CSSProperties } from 'react';
import propTypes from 'prop-types';
import './ClassyButton.scss';

interface ClassyButtonProps {
  type?: ButtonHTMLAttributes<unknown>['type'];
  className?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  title: string;
  style?: CSSProperties;
  tabIndex?: number;
}

const ClassyButton = ({ className, disabled, onClick, title, style, tabIndex, type = 'button' }: ClassyButtonProps) => (
  <button
    type={type}
    className={`classy-button ${className}${disabled ? ' classy-button__disabled' : ''}`}
    disabled={disabled}
    onClick={onClick}
    style={style}
    tabIndex={tabIndex}
  >
    {title}
  </button>
);

ClassyButton.propTypes = {
  className: propTypes.string,
  disabled: propTypes.bool,
  onClick: propTypes.func,
  title: propTypes.string,
  style: propTypes.object,
};

export default ClassyButton;
