import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NinjaRon from '../../Images/RonBurgundySword.png';
import history from '../../Router/history';

const notFoundMessage = "This is not the page you're looking for.";

const notAuthorizedMessage = 'You are not authorized to access this page.';

export class NotFound extends Component {
  render() {
    const { accessToken, isUnauthorized } = this.props;

    return (
      <div className="flexRow centered">
        <img src={NinjaRon} alt="Ron Burgundy, Ninja Warrior" />
        <div>
          <h1>{isUnauthorized ? notAuthorizedMessage : notFoundMessage}</h1>
          <h4>
            <i className="fa fa-arrow-right padding-medium" />
            <button className="btn-link" onClick={() => history.push('/')}>
              {accessToken ? 'Home' : 'Login Page'}
            </button>
          </h4>
        </div>
      </div>
    );
  }
}

NotFound.propTypes = {
  accessToken: PropTypes.string,
  isUnauthorized: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { accessToken } = state.login;

  return { accessToken };
};

export default connect(mapStateToProps)(NotFound);
