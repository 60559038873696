const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const formatSelectOptions = (options, label = 'name') => {
  let selectOptions;

  if (options) {
    selectOptions = options.map((option) => {
      const selectItem = {
        value: option.id,
        label: option[label],
      };
      return selectItem;
    });
  }

  return selectOptions;
};

const getMultipleOptionsByValue = (selectOptions, ids) => {
  let selectedOptions = null;

  if (selectOptions && ids && ids.length) {
    selectedOptions = selectOptions.filter((option) => ids.includes(option.value));
  }

  return selectedOptions;
};

const getSingleOptionByValue = (selectOptions, id) => {
  let selectedOption = null;

  if (selectOptions && id !== null) {
    selectedOption = selectOptions.find((option) => option.value === id);
  }

  return selectedOption;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  capitalizeFirstLetter,
  formatSelectOptions,
  getMultipleOptionsByValue,
  getSingleOptionByValue,
};
