import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getOrganizationsByUserSuccess: ['organizations'],
  getOrganizationsByUserFailure: ['getOrganizationsError'],
  loginSuccess: ['featurePermissions', 'user', 'userRole'],
  loginFailure: ['authenticationError'],
  logout: null,
  resetSession: null,
  selectOrganization: ['selectedOrganization'],
  setUser: ['user'],
  updateUser: ['featurePermissions', 'user', 'userRole', 'organizations'],
});

export const LoginTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  authenticationError: null,
  featurePermissions: {},
  getOrganizationsError: null,
  loading: false,
  organizations: [],
  selectedOrganization: {},
  user: {},
  userRole: '',
};

/* ------------- Reducers ------------- */
const getOrganizationsByUserSuccess = (state, { organizations }) => ({
  ...state,
  organizations,
  getOrganizationsError: null,
});

const getOrganizationsByUserFailure = (state, { getOrganizationsError }) => ({
  ...state,
  organizations: [],
  getOrganizationsError,
});

const loginSuccess = (state, { featurePermissions, user, userRole }) => ({
  ...state,
  featurePermissions,
  user,
  userRole,
  loading: false,
  authenticationError: null,
});

const loginFailure = (state, authenticationError) => ({
  ...state,
  authenticationError,
  loading: false,
});

const logout = () => ({ ...INITIAL_STATE });

const resetSession = (state) => ({ ...state });

const selectOrganization = (state, { selectedOrganization }) => ({
  ...state,
  selectedOrganization,
});

const setUser = (state, { user }) => ({ ...state, user });

const updateUser = (state, { featurePermissions, user, userRole, organizations }) => ({
  ...state,
  featurePermissions,
  organizations,
  user,
  userRole,
  loading: false,
  authenticationError: null,
});

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ORGANIZATIONS_BY_USER_SUCCESS]: getOrganizationsByUserSuccess,
  [Types.GET_ORGANIZATIONS_BY_USER_FAILURE]: getOrganizationsByUserFailure,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,
  [Types.LOGOUT]: logout,
  [Types.RESET_SESSION]: resetSession,
  [Types.SELECT_ORGANIZATION]: selectOrganization,
  [Types.SET_USER]: setUser,
  [Types.UPDATE_USER]: updateUser,
});
