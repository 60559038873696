import React from 'react';
import { CircleLoader } from 'react-spinners';

export interface ThrobberProps {
  loading: boolean;
}

const Throbber = ({ loading }: ThrobberProps) => (
  <CircleLoader color="#f77462" size={100} loading={loading} css="display: block" />
);

export default Throbber;
