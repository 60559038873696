import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getFundraisingMinimumsSuccess: ['getFundraisingMinimumsResponse'],
  getFundraisingMinimumsFailure: ['getFundraisingMinimumsError'],
});

export const FundraisingMinimumsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  getFundraisingMinimumsResponse: [],
  getFundraisingMinimumsError: {},
};

/* ------------- Reducers ------------- */
const getFundraisingMinimumsFailure = (state, { getFundraisingMinimumsError }) => ({
  ...state,
  getFundraisingMinimumsError,
  getFundraisingMinimumsResponse: [],
});

const getFundraisingMinimumsSuccess = (state, { getFundraisingMinimumsResponse }) => ({
  ...state,
  getFundraisingMinimumsResponse,
  getFundraisingMinimumsError: {},
});

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_FUNDRAISING_MINIMUMS_FAILURE]: getFundraisingMinimumsFailure,
  [Types.GET_FUNDRAISING_MINIMUMS_SUCCESS]: getFundraisingMinimumsSuccess,
});
