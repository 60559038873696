import { Checkbox, FormControl, Modal } from 'react-bootstrap';
import React, { ChangeEvent, FormEventHandler, useCallback, useState } from 'react';
import Throbber from '../Throbber/Throbber';
import ClassyButton from '../ClassyButton/ClassyButton';
import { RlcCampaign } from '../../Services/Rlc/RlcCampaign';
import { useIsClassyAdmin } from './RlcHooks';
import { getValidationState } from './ValidationMessage';
import { SimpleValidationResult } from '../../Helpers/validators';

interface EditCampaignProps {
  campaignDesignationCount: number | null;
  campaign: RlcCampaign;
  onSave: (editRlcCampaign: RlcCampaign) => void;
  onRemove: (rlcCampaignId: number) => void;
  onCancel: () => void;
  error: string | null;
  isInFlight: boolean;
}

const EditCampaign = ({
  campaign,
  onSave,
  onRemove,
  onCancel,
  error,
  campaignDesignationCount,
  isInFlight,
}: EditCampaignProps) => {
  const [usesZipCode, setUsesZipCode] = useState<boolean>(campaign.usesZipCode);
  const [usesActiveDesignations, setUsesActiveDesignations] = useState<boolean>(campaign.usesActiveDesignations);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<boolean>(false);
  const [validationError] = useState<SimpleValidationResult>({});

  const isClassyAdmin = useIsClassyAdmin();

  const onUpdateRequested = useCallback(() => {
    onSave({ ...campaign, usesZipCode, usesActiveDesignations });
  }, [onSave, campaign, usesZipCode, usesActiveDesignations]);
  const onRemoveConfirmed = useCallback(() => {
    onRemove(campaign.id);
  }, [onRemove, campaign]);
  const onRemoveRequested = useCallback(() => {
    setShowRemoveConfirmation(true);
  }, []);

  const onCheckedChangedZipCode: FormEventHandler<FormControl> = useCallback((event) => {
    const e = event as unknown as ChangeEvent<HTMLInputElement>;
    const value = e?.target?.checked;
    setUsesZipCode(value);
  }, []);

  const onCheckedChangedAddDesignations: FormEventHandler<FormControl> = useCallback((event) => {
    const e = event as unknown as ChangeEvent<HTMLInputElement>;
    const value = e?.target?.checked;
    setUsesActiveDesignations(value);
  }, []);

  return (
    <>
      {error && <div className="rlcDesignations__error_text">{error}</div>}
      {showRemoveConfirmation && (
        <>
          Are you sure you want to remove {campaignDesignationCount === null ? '...' : campaignDesignationCount}{' '}
          {campaignDesignationCount === 1 ? 'designation' : 'designations'} from campaign id {campaign.classyCampaignId}
          ? This will also remove the automatic designation assignment based on zip code. Please note that there may be
          customizations on your campaign that need to be removed.
          {!isInFlight && (
            <div className="submit-button text-center" style={{ marginTop: 20 }}>
              <ClassyButton
                title="Back"
                className={'secondary-button'}
                onClick={() => setShowRemoveConfirmation(false)}
              />
              <ClassyButton title="Delete" onClick={onRemoveConfirmed} />
            </div>
          )}
          {isInFlight && (
            <div className="rlcDesignations__throbber_container">
              <Throbber loading={isInFlight} />
            </div>
          )}
        </>
      )}
      {!showRemoveConfirmation && (
        <>
          {isClassyAdmin ? (
            <>
              <Checkbox
                data-testid="classy-admin-rlc-zip-code-tag-checkbox"
                checked={usesZipCode}
                onChange={onCheckedChangedZipCode}
                validationState={getValidationState(validationError, 'usesZipCode') || undefined}
              >
                Automatically assign designations based on zip code unless donor chooses a designation.
              </Checkbox>
              <Checkbox
                data-testid="classy-admin-rlc-add-all-active-designations-checkbox"
                checked={usesActiveDesignations}
                onChange={onCheckedChangedAddDesignations}
                validationState={getValidationState(validationError, 'usesActiveDesignations') || undefined}
              >
                Enable zip code override and add all active designations to the campaign.
              </Checkbox>
            </>
          ) : (
            <Checkbox
              data-testid="org-admin-rlc-zip-code-tag-checkbox"
              checked={usesZipCode}
              disabled={true}
              onChange={onCheckedChangedZipCode}
              validationState={getValidationState(validationError, 'usesZipCode') || undefined}
            >
              Automatically assign designations based on zip code.
            </Checkbox>
          )}

          {isInFlight && (
            <div className="rlcDesignations__throbber_container">
              <Throbber loading={isInFlight} />
            </div>
          )}
          {!isInFlight && (
            <div className="submit-button text-center" style={{ marginTop: 20 }}>
              <ClassyButton title="Cancel" className={'secondary-button'} onClick={onCancel} />
              <ClassyButton title="Save" onClick={onUpdateRequested} />
              <ClassyButton title="Remove" className={'tertiary-button'} onClick={onRemoveRequested} />
            </div>
          )}
        </>
      )}
    </>
  );
};

type EditCampaignModalProps = EditCampaignProps & {
  isOpen: boolean;
  title: string;
  campaignDesignationCount: number | null;
};

//  https://react-bootstrap-v3.netlify.app/components/modal/
const EditCampaignModal = ({ isOpen, title, ...props }: EditCampaignModalProps) => (
  <Modal show={isOpen} onHide={props.onCancel} bsSize="large">
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <EditCampaign {...props} />
    </Modal.Body>
  </Modal>
);

export default EditCampaignModal;
