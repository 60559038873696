import ClassyAlert from '../../ClassyAlert/ClassyAlert';
import React, { useEffect, useState } from 'react';
import api from '../../../Services/Api';
import OrganizationsTable, { Organization } from './OrganizationsTable';

const Organizations = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);
  const [organizations, setOrganizations] = useState<Array<Organization> | undefined>(undefined);

  useEffect(() => {
    const controller = new AbortController();
    let response;
    api
      .getAllOrganizations({
        signal: controller.signal,
      })
      .then((organizationsResponse) => {
        const organizations = organizationsResponse.data;
        setOrganizations(organizations);
      })
      .catch((e) => {
        response = (e as any).errors;
        setAlertMessage(response);
      });
    return () => {
      controller.abort();
    };
  }, []);

  const onError = (error: string) => {
    setAlertMessage(error);
    setShowAlert(true);
  };

  const onHide = () => {
    setAlertMessage('');
    setShowAlert(false);
  };
  return (
    <div>
      <ClassyAlert show={showAlert} alertMessage={alertMessage} onHide={onHide} />
      <h2 className="title-text">Organizations</h2>
      {organizations && <OrganizationsTable organizations={organizations} onError={onError} />}
    </div>
  );
};

export default Organizations;
