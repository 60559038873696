import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import api from '../../../Services/Api';
import ClassyAlert from '../../ClassyAlert/ClassyAlert';
import ClassyButton from '../../ClassyButton/ClassyButton';
import constants from '../../../Helpers/constants';
import validators from '../../../Helpers/validators';
import LoginActions from '../../../Redux/Login.redux';

export class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: this.props.user.firstName,
      lastName: this.props.user.lastName,
      email: this.props.user.email,
      newPassword: '',
      newPasswordConfirm: '',
      oldPassword: '',
      showAlert: false,
      alertMessage: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.setAlert = this.setAlert.bind(this);
  }

  setAlert(alertMessage, showAlert = true) {
    this.setState({ alertMessage, showAlert });
  }

  getNewPasswordValidState() {
    const { newPassword, newPasswordConfirm } = this.state;
    let validationResponse = null;

    if (newPassword) {
      if (newPassword !== newPasswordConfirm) {
        validationResponse = constants.VALIDATION_STATES.ERROR;
      } else {
        validationResponse = validators.getValidStatePassword(newPassword);
      }
    }

    return validationResponse;
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (this.isFormValid()) {
      let message;
      try {
        const editUserObj = {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          isEnabled: true,
          isOneTimePassword: false,
        };
        if (this.state.newPassword && this.state.oldPassword) {
          editUserObj.newPassword = this.state.newPassword;
          editUserObj.oldPassword = this.state.oldPassword;
        }

        const { user } = this.props;
        const editUserResponse = await api.editUser(editUserObj, user.id);
        if (editUserResponse.success) {
          message = editUserResponse.data;
          editUserObj.id = user.id;
          this.props.setUser(editUserObj);
        } else {
          message = constants.MESSAGES.ERROR_DEFAULT;
        }
      } catch (exception) {
        message = exception.errors;
      }
      this.setState({
        newPassword: '',
        newPasswordConfirm: '',
        oldPassword: '',
      });
      this.setAlert(message);
    }
  }

  isFormValid() {
    let response = false;
    if (this.isFormChanged()) {
      // Neither password can be in an error state, and if a value is provided to one it must be provided to the other too for the form to be valid
      const oldPasswordState = validators.getValidStatePassword(this.state.oldPassword);
      const newPasswordState = this.getNewPasswordValidState();
      if (
        validators.getValidStateName(this.state.firstName) !== constants.VALIDATION_STATES.ERROR &&
        validators.getValidStateName(this.state.lastName) !== constants.VALIDATION_STATES.ERROR &&
        validators.getValidStateEmail(this.state.email) === constants.VALIDATION_STATES.SUCCESS &&
        oldPasswordState !== constants.VALIDATION_STATES.ERROR &&
        oldPasswordState === newPasswordState
      ) {
        response = true;
      }
    }
    return response;
  }

  isFormChanged() {
    const { user } = this.props;
    let isChanged = false;
    if (
      this.state.email !== user.email ||
      this.state.firstName !== user.firstName ||
      this.state.lastName !== user.lastName ||
      this.state.newPassword
    ) {
      isChanged = true;
    }
    return isChanged;
  }

  render() {
    const { email, firstName, lastName, newPassword, newPasswordConfirm, oldPassword } = this.state;
    return (
      <div>
        <ClassyAlert
          show={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />

        <h2 className="title-text">My Profile</h2>
        <form onSubmit={() => {}}>
          <FormGroup controlId="firstName" validationState={validators.getValidStateName(this.state.firstName)}>
            <ControlLabel>First Name</ControlLabel>
            <FormControl
              name="firstName"
              type="text"
              value={firstName}
              placeholder="Jane"
              onChange={this.handleInputChange}
            />
            <FormControl.Feedback />
          </FormGroup>

          <FormGroup controlId="lastName" validationState={validators.getValidStateName(this.state.lastName)}>
            <ControlLabel>Last Name</ControlLabel>
            <FormControl
              name="lastName"
              type="text"
              value={lastName}
              placeholder="Doe"
              onChange={this.handleInputChange}
            />
            <FormControl.Feedback />
          </FormGroup>

          <FormGroup controlId="email" validationState={validators.getValidStateEmail(email)}>
            <ControlLabel>Email</ControlLabel>
            <FormControl
              name="email"
              type="email"
              value={email}
              placeholder="example@classy.org"
              onChange={this.handleInputChange}
            />
            <FormControl.Feedback />
          </FormGroup>

          <FormGroup validationState={validators.getValidStatePassword(oldPassword)}>
            <ControlLabel>Current Password</ControlLabel>
            <FormControl
              name="oldPassword"
              type="password"
              value={oldPassword}
              placeholder="12345"
              onChange={this.handleInputChange}
            />
            <FormControl.Feedback />
          </FormGroup>

          <FormGroup validationState={this.getNewPasswordValidState()}>
            <ControlLabel>New Password</ControlLabel>
            <FormControl
              name="newPassword"
              type="password"
              value={newPassword}
              placeholder="23456"
              onChange={this.handleInputChange}
            />
            <FormControl.Feedback />
          </FormGroup>

          <FormGroup validationState={this.getNewPasswordValidState()}>
            <ControlLabel>Confirm New Password</ControlLabel>
            <FormControl
              name="newPasswordConfirm"
              type="password"
              value={newPasswordConfirm}
              placeholder="23456"
              onChange={this.handleInputChange}
            />
            <FormControl.Feedback />
          </FormGroup>
        </form>
        <ClassyButton title="Submit" disabled={!this.isFormValid()} onClick={this.handleSubmit} />
      </div>
    );
  }
}

MyProfile.propTypes = {
  setUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state.login;
  return { user };
};

const mapDispatchToProps = (dispatch) => {
  const { setUser } = LoginActions;
  return { setUser: (user) => dispatch(setUser(user)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
