import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import './ClassyTableCell.scss';

class ClassyTableCell extends Component {
  constructor(props) {
    super(props);
    const { row, value } = this.props;

    this.state = {
      className: this.getClassName(value),
      columnId: row.column.id,
      isValid: this.validateInput(value),
      value,
    };
  }

  componentDidUpdate(prevProps) {
    const { columnId } = this.state;
    if (
      prevProps.row.original[columnId] !== this.props.row.original[columnId] ||
      prevProps.row.row.source !== this.props.row.row.source
    ) {
      this.setState({
        className: this.getClassName(this.props.row.original[columnId]),
        value: this.props.row.original[columnId],
      });
    }
  }

  validateInput(value) {
    const { isRequired, isNumeric, charLimit, isNonEmpty } = this.props;

    let isValid = true;

    // If the field is required, or if it has a value, we need to validate
    if (isRequired || value) {
      if (isNumeric === true) {
        const numericValue = Number(value);
        isValid = value && !Number.isNaN(numericValue);
      } else if (!value || !value.length || typeof value !== 'string') {
        isValid = false;
      }
    }

    if (charLimit && value.length > charLimit) {
      isValid = false;
    }

    if (isNonEmpty && !value.trim()) {
      isValid = false;
    }

    return isValid;
  }

  getClassName(value) {
    let className = 'input-valid';
    if (!this.validateInput(value)) {
      className = 'input-invalid';
    }
    return className;
  }

  render() {
    const { onChange, row, value, isDisabled } = this.props;

    return (
      <FormControl
        className={this.state.className}
        type="text"
        value={value || ''}
        onChange={(e) => {
          const input = e.target.value;
          const isValid = this.validateInput(input);
          const className = this.getClassName(input);
          this.setState({ isValid, className, value: input });
          onChange(row, input, isValid);
        }}
        disabled={isDisabled}
      />
    );
  }
}

ClassyTableCell.propTypes = {
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  isNonEmpty: PropTypes.bool,
  isNumeric: PropTypes.bool,
  charLimit: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ClassyTableCell;
