import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormControl, FormGroup } from 'react-bootstrap';
import LoginActions from '../../Redux/Login.redux';
import ClassyAlert from '../ClassyAlert/ClassyAlert';
import ClassyButton from '../ClassyButton/ClassyButton';
import ClassyLogo from '../../Images/classy-services-logo-500px.png';
import api from '../../Services/Api';
import history from '../../Router/history';
import constants from '../../Helpers/constants';
import validators from '../../Helpers/validators';
import './Login.scss';

export class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      showAlert: false,
      alertMessage: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.isLoginInfoValid = this.isLoginInfoValid.bind(this);
    this.setAlert = this.setAlert.bind(this);
  }

  setAlert(alertMessage, showAlert = true) {
    this.setState({ alertMessage, showAlert });
  }

  async handleLogin(event) {
    event.preventDefault();
    const { email, password } = this.state;
    let message = '';
    try {
      const loginResponse = await api.authenticateUser({ email, password });
      if (loginResponse.success) {
        const result = await api.getUserSession();
        const session = result.data;

        await this.props.loginSuccess(session.features, session.user, session.role);

        history.push('/');
      }
    } catch (error) {
      this.props.loginFailure(error);
      if (error.errors) {
        message = error.errors;
      }
      this.setAlert(message);
    }
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  isLoginInfoValid() {
    const { email, password } = this.state;
    let isValid = false;
    if (email && validators.isValidEmail(email) && password && validators.isValidPassword(password)) {
      isValid = true;
    }
    return isValid;
  }

  render() {
    const { email, password } = this.state;
    return (
      <div className="login">
        <ClassyAlert
          show={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />

        <div className="login__header">
          <img src={ClassyLogo} alt="Classy logo" />
        </div>
        <form className="password-form">
          <FormGroup>
            <FormControl
              autoComplete="email"
              name="email"
              onChange={this.handleInputChange}
              placeholder="Email"
              type="text"
              value={email}
            />
            <FormControl
              autoComplete="current-password"
              name="password"
              onChange={this.handleInputChange}
              placeholder="Password"
              type="password"
              value={password}
            />
            <ClassyButton
              className="margin-vertical-large"
              disabled={!this.isLoginInfoValid()}
              title="Submit"
              onClick={this.handleLogin}
            />
            <button
              type="button"
              className="login__forgot-password-link btn-link"
              onClick={() => history.push(constants.ROUTES.RESET_PASSWORD)}
            >
              Forgot Password?
            </button>
          </FormGroup>
        </form>
      </div>
    );
  }
}

Login.propTypes = {
  loginFailure: PropTypes.func.isRequired,
  loginSuccess: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  const { loginFailure, loginSuccess } = LoginActions;

  return {
    loginFailure: (error) => dispatch(loginFailure(error)),
    loginSuccess: (featurePermissions, user, userRole) => dispatch(loginSuccess(featurePermissions, user, userRole)),
  };
};

export default connect(null, mapDispatchToProps)(Login);
