import { RlcParentDesignation } from '../../Services/Rlc/RlcParentDesignation';
import { LinkContainer } from 'react-router-bootstrap';
import React, { useCallback } from 'react';
import ClassyTable from '../ClassyTable/ClassyTable';
import constants from '../../Helpers/constants';
import { CellInfo } from 'react-table';
import ClassyButton from '../ClassyButton/ClassyButton';

interface SortBy {
  id: string;
  desc: boolean;
}

interface FilterBy {
  id: string;
  value: string;
}

export interface DesignationFilter {
  currentPage: number; // 0-indexed current page
  pageSize: number;
  sortBy: Array<SortBy>;
  filterBy: Array<FilterBy>;
}

interface DesignationListProps {
  designations: Array<RlcParentDesignation>;
  onFilterChanged: (filter: DesignationFilter) => void;
  pageCount: number;
  onAddDesignationClicked: () => void;
  onEditDesignationClicked: (designation: RlcParentDesignation) => void;
}

// SEE: https://docs.google.com/document/d/10Szf7lfry5V_HMhB46KXmUOZNU54a4HevV-vuQdPH9Y/edit#

const displayBoolean = (row: CellInfo) => (
  <i className={`fa fa-${row.value ? 'check' : 'ban'} status-icon--${row.value ? 'success' : 'error'} center-block`} />
);

const DesignationList = ({
  designations,
  onFilterChanged,
  pageCount,
  onAddDesignationClicked,
  onEditDesignationClicked,
}: DesignationListProps) => {
  const onFetchData = useCallback(
    async (state) => {
      const { sorted, filtered, page, pageSize } = state;
      const newFilter: DesignationFilter = {
        currentPage: page,
        sortBy: sorted,
        filterBy: filtered,
        pageSize: pageSize,
      };
      onFilterChanged(newFilter);
    },
    [onFilterChanged],
  );
  const onEditClicked = useCallback(
    (rlcParentDesignation: RlcParentDesignation) => {
      onEditDesignationClicked(rlcParentDesignation);
    },
    [onEditDesignationClicked],
  );
  const columns = [
    {
      Header: 'Name',
      accessor: 'pdName',
      width: 150,
      sortable: true,
      filterable: true,
      Cell: (row: CellInfo) => <div title={row.value}>{row.value}</div>,
    },
    {
      Header: 'Description',
      width: 250,
      resize: true,
      filterable: true,
      accessor: 'pdDescription',
      Cell: (row: CellInfo) => <div title={row.value}>{row.value}</div>,
    },
    {
      Header: 'External ID',
      filterable: true,
      accessor: 'pdExternalReferenceId',
      width: 110,
      Cell: (row: CellInfo) => row.value,
    },
    {
      Header: 'Goal',
      accessor: 'pdGoal',
      width: 90,
      // filterable: true, // TODO: the wireframe has this both on and off
      Cell: (row: CellInfo) => <div style={{ textAlign: 'right' }}>{row.value}</div>,
      getProps: () => {
        return {
          style: {
            display: 'flex',
            justifyContent: 'flex-end',
          },
        };
      },
    },
    {
      Header: 'Display Status',
      accessor: 'pdIsActive', // TODO: is this display status?
      width: 50,
      sortable: false,
      Cell: displayBoolean,
    },
    {
      Header: 'City',
      accessor: 'pdCity',
      filterable: true,
      width: 125,
      Cell: (row: CellInfo) => row.value,
    },
    {
      Header: 'State',
      accessor: 'pdState',
      filterable: true,
      width: 75,
      Cell: (row: CellInfo) => row.value,
    },
    {
      Header: 'Zip',
      accessor: 'pdPostalCode',
      width: 65,
      filterable: true,
      Cell: (row: CellInfo) => row.value,
    },
    {
      Header: 'isPublic',
      accessor: 'isPublic',
      width: 95,
      Cell: displayBoolean,
    },
    {
      width: 150,
      Cell: (row: CellInfo) => (
        <>
          <button className="btn-link" onClick={() => onEditClicked(row.row._original)}>
            Edit
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      <ClassyTable
        showWhenEmpty
        columns={columns}
        data={designations}
        defaultPageSize={20}
        exportFilename={'rlcDesignations'}
        loading={false}
        manualPagination={true}
        onFetchData={onFetchData}
        pages={pageCount}
        pageSizeOptions={[20, 50, 100]}
        resizable={true}
        HeaderLeftComponent={
          <LinkContainer style={{ marginBottom: '10px' }} to={constants.ROUTES.RLC_DESIGNATIONS_CREATE}>
            <ClassyButton title="Add Designation" onClick={onAddDesignationClicked} />
          </LinkContainer>
        }
      />
      {designations && !designations.length ? <span>There are no designations.</span> : null}
    </>
  );
};

export default DesignationList;
