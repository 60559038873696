import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const validationState = {
  backgroundColor: {
    invalid: 'pink',
    valid: '#FAFAFA',
  },
};

class ClassySelect extends Component {
  constructor(props) {
    super(props);
    const { row, valueSingle } = this.props;

    this.state = {
      validationStateColor: this.getValidationStateColor(valueSingle),
      columnId: row.column.id,
      isValid: this.validateInput(valueSingle),
      selectedOption: valueSingle ? this.formatValueSingle(valueSingle) : null,
    };
  }

  componentDidUpdate(prevProps) {
    const { columnId } = this.state;
    const updatedOptions = this.props.options;
    const updatedValue = this.props.row.original[columnId];
    if (prevProps.row.original[columnId] !== updatedValue || prevProps.options !== updatedOptions) {
      this.setState({
        validationStateColor: this.getValidationStateColor(updatedValue),
        selectedOption: this.formatValueSingle(updatedValue),
      });
    }
  }

  formatValueSingle(valueSingle) {
    return { value: valueSingle, label: this.getLabel(valueSingle) };
  }

  getValidationStateColor(input) {
    const { isRequired } = this.props;
    let backgroundColor = validationState.backgroundColor.valid;
    if (isRequired) {
      if (input) {
        const inputString = input.toString();
        if (this.props.isMulti && !input.length) {
          backgroundColor = validationState.backgroundColor.invalid;
        } else if (!inputString.length) {
          backgroundColor = validationState.backgroundColor.invalid;
        }
      } else {
        backgroundColor = validationState.backgroundColor.invalid;
      }
    }
    return backgroundColor;
  }

  getLabel(value) {
    const { options } = this.props;
    let labelText = value;
    if (value) {
      let currentOptions;
      if (options.length && options[0].options) {
        const mergedOptions = options.map((opt) => opt.options);
        currentOptions = [].concat(...mergedOptions);
      } else {
        currentOptions = options;
      }
      const upperCaseValue = value.toString().toUpperCase();
      const matchedOptions = currentOptions.filter((option) => {
        let matchedOption;
        if (option.value && option.label) {
          const labelString = option.label.toString();
          const valueString = option.value.toString();
          matchedOption = labelString.toUpperCase() === upperCaseValue || valueString.toUpperCase() === upperCaseValue;
        }
        return matchedOption;
      });
      if (matchedOptions.length) {
        labelText = matchedOptions[0].label;
      }
    }
    return labelText;
  }

  validateInput(input) {
    const { isRequired } = this.props;
    let isValid = true;
    if (isRequired && input) {
      const inputString = input.toString();
      if (this.props.isMulti && !input.length) {
        isValid = false;
      } else if (!inputString.length || !Object.keys(input).length) {
        isValid = false;
      }
    }
    return isValid;
  }

  render() {
    const { isMulti, onChange, options, row, isDisabled, controlStylesOverride = {} } = this.props;
    const customStyles = {
      control: (base) => ({
        ...base,
        backgroundColor: this.state.validationStateColor,
        ...controlStylesOverride,
      }),
      groupHeading: (base) => ({
        ...base,
        color: 'black',
        fontWeight: 700,
      }),
    };
    return (
      <Select
        classNamePrefix="classySelect"
        isMulti={isMulti}
        menuPortalTarget={document.body}
        styles={customStyles}
        value={this.state.selectedOption}
        onChange={(selectedOption) => {
          const isValid = this.validateInput(selectedOption);
          const validationStateColor = this.getValidationStateColor(selectedOption);
          this.setState({ isValid, selectedOption, validationStateColor });
          onChange(row, selectedOption, isValid);
        }}
        options={options}
        isDisabled={isDisabled}
      />
    );
  }
}

ClassySelect.propTypes = {
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  row: PropTypes.object.isRequired,
  valueSingle: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  valueMultiple: PropTypes.array,
  customStylesOverride: PropTypes.object,
};

export default ClassySelect;
