import React from 'react';
import { RlcCampaign } from '../../Services/Rlc/RlcCampaign';
import { CellInfo } from 'react-table';
import ClassyTable from '../ClassyTable/ClassyTable';

interface CampaignListProps {
  campaigns: Array<RlcCampaign>;
  onCampaignEditRequested: (rlcCampaign: RlcCampaign) => void;
}

const CampaignList = ({ campaigns, onCampaignEditRequested }: CampaignListProps) => {
  const columns = [
    {
      Header: 'Campaign Id',
      accessor: 'classyCampaignId',
      width: 150,
      Cell: (row: CellInfo) => <div title={row.value}>{row.value}</div>,
    },
    {
      Header: 'Campaign Name',
      width: 250,
      resize: true,
      accessor: 'name',
      Cell: (row: CellInfo) => <div title={row.value}>{row.value}</div>,
    },
    {
      width: 100,
      Cell: (row: CellInfo) => (
        <button className="text-danger btn-link" onClick={() => onCampaignEditRequested(row.row._original)}>
          Modify
        </button>
      ),
    },
  ];
  return (
    <>
      <h2>Campaigns</h2>
      {campaigns && (
        <>
          {campaigns.length > 0 && (
            <ClassyTable
              // className="designation_list__table"
              showWhenEmpty
              columns={columns}
              data={campaigns}
              defaultPageSize={20}
              exportFilename={'rlcCampaigns'}
              loading={false}
              manualPagination={false}
              pageSizeOptions={[20, 50, 100]}
              resizable={true}
            />
          )}
          {!campaigns.length ? <span>There are no campaigns.</span> : null}
        </>
      )}
    </>
  );
};

export default CampaignList;
