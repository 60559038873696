import api, { Pagination } from '../../Services/Api';
import LogList, { LogFilter } from './LogList';
import useDebouncedState from '../../Helpers/debounceHook';
import React, { useCallback, useEffect, useState } from 'react';
import useSWR from 'swr';
import { APIPaginatedResponse } from '../../Typings/Services/Api';
import { RlcCommandLog } from '../../Services/Rlc/RlcCommandLog';

interface LogFetcherKey {
  url: string;
  filter: LogFilter;
  page: number;
}

const createLogSearchUrl = (url: string, filter: LogFilter) => {
  const searchParams = new URLSearchParams();
  searchParams.append('page', filter.currentPage.toString());
  searchParams.append('pageSize', filter.pageSize.toString());
  filter.sortBy.forEach((s) => {
    searchParams.append('sortBy', `${s.id}${s.desc ? ',desc' : ''}`);
  });
  filter.filterBy.forEach((s) => {
    searchParams.append(s.id, s.value);
  });
  return `${url}?${searchParams.toString()}`;
};

const fetcher = ({ url, filter }: LogFetcherKey) =>
  url
    ? api.api.get(createLogSearchUrl(url, filter)).then((res) => {
        return res;
      })
    : null;

const defaultFilter: LogFilter = {
  filterBy: [],
  sortBy: [],
  currentPage: 0,
  pageSize: 20,
};

const Logs = () => {
  const [filter, setFilter] = useDebouncedState<LogFilter>(defaultFilter);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const [commandLogs, setCommandLogs] = useState<Array<RlcCommandLog>>([]);

  const { /* error: swrError, */ data: swrData /* isValidating */ } = useSWR<APIPaginatedResponse<RlcCommandLog>>(
    { url: `${api.apiUrl}rlc/logs/`, filter },
    fetcher as any,
  );

  const onFilterChanged = useCallback(
    (newFilter: LogFilter) => {
      setFilter(newFilter);
    },
    [setFilter],
  );

  useEffect(() => {
    try {
      if (swrData && swrData.success) {
        const { data, pagination } = swrData;
        setPagination(pagination);
        setFilter((prevState: LogFilter) => ({
          ...prevState,
          pageSize: filter.pageSize,
          currentPage: filter.currentPage,
          sortBy: filter.sortBy,
          filterBy: filter.filterBy,
        }));
        setCommandLogs(data ?? []);
      }
    } catch (error) {
      console.error('== error while retrieving logs');
      const e = error as Error;
      console.error(e.message);
    }
    // eslint-disable-next-line
  }, [swrData]);

  return (
    commandLogs && (
      <LogList commandLogs={commandLogs} onFilterChanged={onFilterChanged} pageCount={pagination?.total.pages || 0} />
    )
  );
};

export default Logs;
