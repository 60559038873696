export const dateReviver = (_: string, value: any) => {
  if (typeof value === 'string') {
    const m = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z?$/.exec(value);
    if (m) {
      return new Date(Date.UTC(+m[1], +m[2] - 1, +m[3], +m[4], +m[5], +m[6]));
    }
  }
  return value;
};

export const withDateStringsAsDates = <T>(obj: any): T => {
  try {
    return JSON.parse(JSON.stringify(obj), dateReviver);
  } catch (err) {
    return obj;
  }
};
