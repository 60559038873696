import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Col, FormControl, FormGroup, Row } from 'react-bootstrap';
import constants from '../../Helpers/constants';
import validators from '../../Helpers/validators';
import formatters from '../../Helpers/formatters';
import TokenexIframe from '../TokenexIframe';

export class TokenExCard extends React.Component {
  constructor(props) {
    super(props);
    this.monthOptions = constants.DATES.MONTHS;
    this.yearOptions = constants.DATES.CC_EXPIRATION_YEARS();
    this.handleExpMonthChange = this.handleExpMonthChange.bind(this);
    this.handleExpYearChange = this.handleExpYearChange.bind(this);
    this.handleCardValidate = this.handleCardValidate.bind(this);
    this.handleTokenize = this.handleTokenize.bind(this);
  }

  handleExpMonthChange(selected) {
    this.props.handleCardExpiryChange({
      expirationMonth: selected.value,
    });
  }

  handleCardValidate(creditCard) {
    this.props.handleCardValidate({
      isCardValid: creditCard.isValid,
    });
  }

  handleExpYearChange(selected) {
    this.props.handleCardExpiryChange({
      expirationYear: selected.value,
    });
  }

  handleTokenize(creditCard) {
    const { token: tokenizedCreditCard, lastFour: ccLastFour, cardType: ccType } = creditCard;
    this.props.handleTokenize({ tokenizedCreditCard, ccLastFour, ccType });
  }

  render() {
    const {
      organizationId,
      expirationMonth,
      expirationYear,
      getValidationStateColor,
      changeSubmitStatus,
      registerFormResetHandler,
      registerFormSubmitHandler,
    } = this.props;

    const customStyles = {
      control: (base) => ({
        ...base,
        backgroundColor: getValidationStateColor(),
      }),
    };

    return (
      <Col>
        <Row>
          <Col xs={12}>
            <span>
              Credit Card<sup className="required-marker">*</sup>
            </span>
            <TokenexIframe
              iframeType={constants.PAYMENT_METHODS.CC}
              placeholder="1234 1234 1234 1234"
              organizationId={organizationId}
              onValid={this.handleCardValidate}
              onToken={this.handleTokenize}
              changeSubmitStatus={changeSubmitStatus}
              registerFormSubmitHandler={registerFormSubmitHandler}
              registerFormResetHandler={registerFormResetHandler}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span>
              Expiration Date<sup className="required-marker">*</sup>
            </span>
            <FormGroup controlId="ccExpirationMonth" validationState={validators.getValidStateId(expirationMonth)}>
              <FormControl componentClass="react-select" name="ccExpirationMonth">
                <Select
                  styles={customStyles}
                  name="ccExpMonth"
                  placeholder="Month"
                  isMulti={false}
                  options={this.monthOptions}
                  value={formatters.getSingleOptionByValue(this.monthOptions, expirationMonth)}
                  onChange={this.handleExpMonthChange}
                />
              </FormControl>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <span>&nbsp;</span>
            <FormGroup controlId="ccExpirationYear" validationState={validators.getValidStateId(expirationYear)}>
              <FormControl componentClass="react-select" name="ccExpirationYear">
                <Select
                  name="ccExpMonth"
                  placeholder="Year"
                  isMulti={false}
                  options={this.yearOptions}
                  value={formatters.getSingleOptionByValue(this.yearOptions, expirationYear)}
                  onChange={this.handleExpYearChange}
                />
              </FormControl>
            </FormGroup>
          </Col>
        </Row>
      </Col>
    );
  }
}

TokenExCard.propTypes = {
  handleTokenize: PropTypes.func.isRequired,
  handleCardExpiryChange: PropTypes.func.isRequired,
  handleCardValidate: PropTypes.func.isRequired,
  getValidationStateColor: PropTypes.func.isRequired,
  registerFormSubmitHandler: PropTypes.func.isRequired,
  registerFormResetHandler: PropTypes.func.isRequired,
  changeSubmitStatus: PropTypes.func,
  isCardValid: PropTypes.bool,
  expirationMonth: PropTypes.string,
  expirationYear: PropTypes.string,
  organizationId: PropTypes.number.isRequired,
};

export default TokenExCard;
