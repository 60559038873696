import { CellInfo } from 'react-table';
import React, { useState } from 'react';
import ClassyTable from '../../ClassyTable/ClassyTable';
import { TimeAgoCell } from '../../ClassyTable/ClassyTableUtils';
import JobDetailsModal, { BullMqJob } from './JobDetails';

export interface JobsPagingFilter {
  currentPage: number;
  pageSize: number;
}

interface JobsProps {
  jobs: Array<BullMqJob>;
  pageCount: number;
  onPageSizeChanged: (pageSize: number) => void;
  onPageChanged: (page: number) => void;
}

const Jobs = ({ jobs, pageCount, onPageChanged, onPageSizeChanged }: JobsProps) => {
  const [selectedJob, setSelectedJob] = useState<BullMqJob | null>(null);
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      width: 300,
      sortable: true,
      filterable: false,
      Cell: (row: CellInfo) => <div title={row.value}>{row.value}</div>,
    },
    {
      Header: 'Type',
      width: 200,
      sortable: false,
      filterable: false,
      Cell: (row: CellInfo) => <div>{row.row._original.data?.type}</div>,
    },
    {
      Header: 'TimeStamp',
      width: 150,
      accessor: 'timestamp',
      sortable: false,
      filterable: false,
      Cell: (row: CellInfo) => <TimeAgoCell value={row.value} />,
    },
    {
      Header: 'Finished On',
      width: 150,
      accessor: 'finishedOn',
      sortable: false,
      filterable: false,
      Cell: (row: CellInfo) => <TimeAgoCell value={row.value} />,
    },
    {
      Header: 'Processed On',
      width: 150,
      accessor: 'processedOn',
      sortable: false,
      filterable: false,
      Cell: (row: CellInfo) => <TimeAgoCell value={row.value} />,
    },
    {
      width: 150,
      Cell: (row: CellInfo) => (
        <>
          <button className="btn-link" onClick={() => setSelectedJob(row.row._original)}>
            View
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      {selectedJob && (
        <JobDetailsModal
          title={selectedJob.name}
          job={selectedJob}
          isOpen={Boolean(selectedJob)}
          onCancel={() => setSelectedJob(null)}
        />
      )}
      <ClassyTable
        showWhenEmpty={false}
        columns={columns}
        data={jobs}
        loading={false}
        manualPagination={true}
        onPageChange={onPageChanged}
        onPageSizeChange={onPageSizeChanged}
        pages={pageCount}
        pageSizeOptions={[7, 20, 50, 100]}
        resizable={true}
      />

      {jobs && !jobs.length ? <div>There are no jobs.</div> : null}
    </>
  );
};

export default Jobs;
