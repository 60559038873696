import ClassyTable from '../../ClassyTable/ClassyTable';
import { LinkContainer } from 'react-router-bootstrap';
import constants from '../../../Helpers/constants';
import ClassyButton from '../../ClassyButton/ClassyButton';
import React, { useCallback, useState } from 'react';
import utilities from '../../../Helpers/utilities';
import moment from 'moment/moment';
import { CellInfo } from 'react-table';
import history from '../../../Router/history';
import CreateRlcAccount from './CreateRlcAccount';
import api from '../../../Services/Api';

export interface OrganizationBase {
  id: number;
  classyOrganizationId: number;
  name: string;
  companyName: string;
  createdBy: number;
  updatedBy: number;
}

export type Organization = OrganizationBase & {
  isEnabled: boolean;
  createdAt: Date;
  updatedAt: Date;
  features: Array<string>;
};

type FilteredOrganization = OrganizationBase & {
  index: number;
  createdAt: string;
  updatedAt: string;
};

interface OrganizationsTableProps {
  organizations: Organization[];
  onError: (error: string) => void;
}

const filterOrganizationsData = (organizations: Array<Organization>): Array<FilteredOrganization> =>
  organizations.map((organization, index) => ({
    index: index + 1,
    id: organization.id,
    classyOrganizationId: organization.classyOrganizationId,
    name: organization.name,
    companyName: organization.companyName,
    status: organization.isEnabled ? constants.ACTIVE_STATUS.label : constants.DEACTIVATED_STATUS.label,
    createdBy: organization.createdBy,
    createdAt: moment.utc(organization.createdAt).local().format(constants.DATES.TABLE_DATE_FORMAT),
    updatedBy: organization.updatedBy,
    updatedAt: moment.utc(organization.updatedAt).local().format(constants.DATES.TABLE_DATE_FORMAT),
  }));

interface Rlc {
  rlcGroupId: number;
  organizationId: number;
  initialSetupComplete: string;
}

interface RlcButtonProps {
  organization: Organization & { features: Array<string>; rlc: Rlc };
  onClicked: (organization: Organization) => void;
}

const RlcButton = ({ organization, onClicked }: RlcButtonProps) => {
  if (organization.id === constants.SYSTEM_ORGANIZATION_ID) {
    // you can't set Classy org up---it has no apiv2 key
    return null;
  }
  try {
    if (!organization.features?.includes(constants.FEATURES.RLC_MAPPING)) {
      return null;
    }
    if (organization.rlc && organization.rlc.initialSetupComplete) {
      return <div>RLC Configured</div>;
    } else if (organization.rlc) {
      return <div>RLC Setup in progress...</div>;
    }
  } catch (e) {
    console.error(e);
    return null;
  }
  return (
    <button className="btn-link" onClick={() => onClicked(organization)}>
      Set up RLC
    </button>
  );
};

const OrganizationsTable = ({ organizations, onError }: OrganizationsTableProps) => {
  const [isCreateAccountModalOpen, setIsCreateAccountModalOpen] = useState<boolean>(false);
  const [selectedClassyOrganizationId, setSelectedClassyOrganizationId] = useState<number | undefined>(undefined);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<number | undefined>(undefined);

  const onSetupRlcAccountSubmitted = useCallback(
    async (rlcGroupId: number, selectedOrganizationId: number) => {
      try {
        if (selectedOrganizationId) {
          await api.addOrganizationToRlcGroup(selectedOrganizationId, rlcGroupId);
          // todo: refresh the page
          // await mutate(getRlcOrganizationsUrl(userRlcGroup));
        }
        return true;
      } catch (e) {
        const errorMessage = (e as any)?.errors || 'Error setting up RLC account';
        onError(errorMessage);
        return false;
      }
    },
    [onError],
  );

  const onClickEditOrganization = useCallback((id: number) => {
    history.push({
      pathname: `/admin/organizations/${id}/edit`,
    });
  }, []);
  const onAddDesignationsClicked = (organization: Organization) => {
    setSelectedClassyOrganizationId(organization.classyOrganizationId);
    setSelectedOrganizationId(organization.id);
    setIsCreateAccountModalOpen(true);
  };
  const columns = [
    {
      width: 50,
      Cell: (row: CellInfo) => (
        <>
          <button className="btn-link" onClick={() => onClickEditOrganization(row.row.id)}>
            Edit
          </button>
        </>
      ),
      filterable: false,
    },
    {
      Header: 'ID',
      accessor: 'id',
      width: 100,
      filterable: false,
    },
    {
      Header: 'Classy Organization ID',
      accessor: 'classyOrganizationId',
      width: 100,
      filterable: false,
    },
    {
      Header: 'Name',
      accessor: 'name',
      filterable: true,
      filterMethod: utilities.filterStringHelper,
    },
    {
      Header: 'Company',
      accessor: 'companyName',
      filterable: true,
      filterMethod: utilities.filterStringHelper,
    },
    {
      Header: 'Status',
      accessor: 'isEnabled',
      Cell: (row: CellInfo) => (row.value ? constants.ACTIVE_STATUS.label : constants.DEACTIVATED_STATUS.label),
      width: 125,
      filterable: false,
    },
    // {
    //   Header: 'Created By',
    //   accessor: 'createdBy',
    //   width: 125,
    //   filterable: false,
    // },
    // {
    //   Header: 'Created At',
    //   accessor: 'createdAt',
    //   Cell: (row: CellInfo) =>
    //     row.value ? moment.utc(row.value).local().format(constants.DATES.TABLE_DATE_FORMAT) : null,
    //   filterable: false,
    // },
    {
      width: 150,
      Cell: (row: CellInfo) => <RlcButton organization={row.row._original} onClicked={onAddDesignationsClicked} />,
      filterable: false,
    },
    // {
    //   Header: 'Updated By',
    //   accessor: 'updatedBy',
    //   width: 125,
    //   filterable: false,
    // },
    // {
    //   Header: 'Updated At',
    //   accessor: 'updatedAt',
    //   Cell: (row: CellInfo) =>
    //     row.value ? moment.utc(row.value).local().format(constants.DATES.TABLE_DATE_FORMAT) : null,
    //   filterable: false,
    // },
  ];

  return (
    <div>
      {selectedClassyOrganizationId && selectedOrganizationId && (
        <CreateRlcAccount
          // designationCount={designationCount}
          selectedOrganizationId={selectedOrganizationId}
          selectedClassyOrganizationId={selectedClassyOrganizationId}
          isOpen={isCreateAccountModalOpen}
          onCancel={() => setIsCreateAccountModalOpen(false)}
          onSave={onSetupRlcAccountSubmitted}
        />
      )}
      <ClassyTable
        filterable
        showWhenEmpty
        data={organizations || []}
        columns={columns || []}
        exportFilename={'Organizations'}
        formattedExportData={filterOrganizationsData(organizations)}
        HeaderLeftComponent={
          <LinkContainer to={constants.ROUTES.ADMIN_CREATE_ORGANIZATIONS}>
            <ClassyButton title="Create Organization" />
          </LinkContainer>
        }
        defaultPageSize={15}
        pageSizeOptions={[15, 25, 50, 100]}
      />
      {organizations && !organizations.length ? <span>No organizations found.</span> : null}
    </div>
  );
};

export default OrganizationsTable;
