import React, { Component } from 'react';
import propTypes from 'prop-types';
import './ClassyToggle.scss';

class ClassyToggle extends Component {
  isActive(side) {
    const isActive = this.props.defaultActiveSide === side;
    let className = '';
    if (isActive) {
      className = 'classy-toggle__active';
    }
    return className;
  }

  render() {
    const { labelLeft, labelRight, onClickLeft, onClickRight } = this.props;
    return (
      <div className="flexRow">
        <div className={`classy-toggle ${this.isActive('left')} classy-toggle__left`} onClick={() => onClickLeft()}>
          {labelLeft.toUpperCase()}
        </div>
        <div className={`classy-toggle ${this.isActive('right')} classy-toggle__right`} onClick={() => onClickRight()}>
          {labelRight.toUpperCase()}
        </div>
      </div>
    );
  }
}

ClassyToggle.propTypes = {
  defaultActiveSide: propTypes.string,
  labelLeft: propTypes.string.isRequired,
  labelRight: propTypes.string.isRequired,
  onClickLeft: propTypes.func.isRequired,
  onClickRight: propTypes.func.isRequired,
};

export default ClassyToggle;
