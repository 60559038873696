import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';

import './RequiredField.scss';

export const RequiredField = forwardRef(RequiredFieldComponent);

export function RequiredFieldComponent(
  { id, hasError, isValidated = false, useFeedback = true, message = 'This field is required', otherProps, children },
  ref,
) {
  const formGroupRef = useRef();
  useImperativeHandle(ref, () => ({ validate }));
  const validate = useCallback(() => formGroupRef?.current?.props?.validationState, []);

  return (
    <FormGroup
      controlId={id}
      className="required-field"
      validationState={hasError ? 'error' : isValidated ? 'success' : null}
      ref={formGroupRef}
      {...otherProps}
    >
      {children}
      {hasError ? <HelpBlock>{message}</HelpBlock> : null}
      {useFeedback ? <FormControl.Feedback /> : null}
    </FormGroup>
  );
}
