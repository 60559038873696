import React, { useCallback, useEffect, useState } from 'react';
import { Col, ControlLabel, FormControl, FormGroup, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { get, isEqual } from 'lodash';
import Select from 'react-select';
import { connect } from 'react-redux';

import validators from '../../../Helpers/validators';
import localization from '../../../Helpers/localization';
import ClassyButton from '../../ClassyButton/ClassyButton';
import api from '../../../Services/Api';
import ClassyAlert from '../../ClassyAlert/ClassyAlert';
import '../style.scss';

const initialState = {
  stateCode: '',
  cityName: '',
  customLocation: '',
  citiesOption: [],
};

const EditLocation = ({ selectedOrgId, match }) => {
  const [alert, setAlert] = useState({ alertMessage: '', showAlert: false });
  const [isEditing, setEditing] = useState(false);
  const [location, setLocation] = useState(initialState);
  const [initial, setInitial] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const getLocationById = useCallback(async () => {
    let response;
    setLoading(true);
    try {
      const locationResponse = await api.getLocationById({
        organizationId: selectedOrgId,
        locationId: get(match, 'params.id'),
      });
      if (locationResponse.success) {
        const citiesOption = localization.STATES.filter(
          (stateObj) => stateObj.value === locationResponse.data.stateCode,
        )[0].cities;
        setLocation({ ...locationResponse.data, citiesOption });
        setInitial({ ...locationResponse.data, citiesOption });
      } else {
        response =
          'Unfortunately, we ran into a problem while processing this request - please contact the administrator';
      }
    } catch (exception) {
      response = exception.errors;
    } finally {
      setLoading(false);
    }

    if (response) {
      setAlert({ alertMessage: response.data || response, showAlert: true });
    }
    return response;
  }, [selectedOrgId, match]);

  useEffect(() => {
    if (get(match, 'params.id')) {
      getLocationById();
      setEditing(true);
    }
  }, [getLocationById, match]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    let response;
    const { citiesOption, ...rest } = location;
    try {
      const locationResponse = isEditing
        ? await api.editLocation({
            organizationId: selectedOrgId,
            locationObj: rest,
            locationId: get(match, 'params.id'),
          })
        : await api.createLocation({
            organizationId: selectedOrgId,
            locationObj: location,
          });
      if (locationResponse.success) {
        setAlert({
          alertMessage: isEditing ? locationResponse.data : 'Location created successfully',
          showAlert: true,
        });
      } else {
        response =
          'Unfortunately, we ran into a problem while processing this request - please contact the administrator';
      }
    } catch (exception) {
      response = exception.errors;
    } finally {
      setLoading(false);
    }

    if (response) {
      setAlert({ alertMessage: response.data || response, showAlert: true });
    }
    return response;
  };

  const handleState = (name, e) => {
    const value = get(e, 'target.value', e.value);
    if (name === 'stateCode') {
      if (!value) {
        return setLocation({ stateCode: '', cityName: '', citiesOption: [] });
      }
      const citiesOption = localization.STATES.filter((stateObj) => stateObj.value === value)[0].cities;
      setLocation({
        ...location,
        citiesOption,
        [name]: value,
        customLocation: '',
      });
    } else if (name === 'cityName') {
      setLocation({ ...location, customLocation: '', [name]: value });
    } else {
      setLocation({ ...location, cityName: '', stateCode: '', [name]: value });
    }
  };

  const areCityAndStateSet = () => !!location.stateCode && !!location.cityName;

  const isCustomLocationSet = () => !!location.customLocation;

  const isFormValid = () => {
    if (!isEqual(initial, location)) {
      if ((areCityAndStateSet() && !isCustomLocationSet()) || (!areCityAndStateSet() && isCustomLocationSet())) {
        return false;
      }
    }
    return true;
  };

  return (
    <div>
      <ClassyAlert
        show={alert.showAlert}
        alertMessage={alert.alertMessage}
        onHide={() => setAlert({ alertMessage: '', showAlert: false })}
      />

      <h2 className="title-text">{isEditing ? 'Edit' : 'Create'} Location</h2>

      <form onSubmit={handleSubmit}>
        <fieldset disabled={loading ? 'disabled' : null}>
          <Row className="blog">
            <Col xs={12}>
              <FormGroup controlId="stateCode" validationState={validators.getValidStateName(location.stateCode)}>
                <ControlLabel>
                  State <sup className="required-marker">*</sup>
                </ControlLabel>
                <FormControl componentClass="react-select" name="stateCode">
                  <Select
                    isDisabled={loading}
                    menuPortalTarget={document.body}
                    value={localization.STATES.filter((stateObj) => stateObj.value === location.stateCode)}
                    onChange={(e) => handleState('stateCode', e)}
                    options={localization.STATES}
                  />
                </FormControl>
              </FormGroup>

              <FormGroup controlId="stateCode" validationState={validators.getValidStateName(location.cityName)}>
                <ControlLabel>
                  City <sup className="required-marker">*</sup>
                </ControlLabel>
                <FormControl componentClass="react-select" name="cityName">
                  <Select
                    isDisabled={loading || (location.citiesOption && !location.citiesOption.length)}
                    menuPortalTarget={document.body}
                    value={
                      localization.STATES.filter((stateObj) => stateObj.value === location.stateCode) &&
                      localization.STATES.filter((stateObj) => stateObj.value === location.stateCode).length > 0 &&
                      localization.STATES.filter((stateObj) => stateObj.value === location.stateCode)[0].cities &&
                      localization.STATES.filter((stateObj) => stateObj.value === location.stateCode)[0].cities.filter(
                        (cityObj) => cityObj.value === location.cityName,
                      )
                    }
                    onChange={(e) => handleState('cityName', e)}
                    options={location.citiesOption}
                  />
                </FormControl>
              </FormGroup>
            </Col>
          </Row>

          <Row className="or-blog">OR</Row>

          <Row className="blog">
            <Col xs={12}>
              <FormGroup
                controlId="customLocation"
                validationState={validators.getValidStateName(location.customLocation)}
              >
                <ControlLabel>
                  Custom Location <sup className="required-marker">*</sup>
                </ControlLabel>
                <FormControl
                  name="customLocation"
                  type="text"
                  value={location.customLocation}
                  onChange={(e) => handleState('customLocation', e)}
                />
                <FormControl.Feedback />
              </FormGroup>
            </Col>
          </Row>

          <ClassyButton title="Submit" type="submit" disabled={isFormValid()} />
        </fieldset>
      </form>
    </div>
  );
};

EditLocation.propTypes = {
  selectedOrgId: PropTypes.number,
  match: PropTypes.object,
};

const mapStateToProps = (state) => {
  const {
    selectedOrganization: { id: selectedOrgId },
  } = state.login;

  return {
    selectedOrgId,
  };
};

export default connect(mapStateToProps)(EditLocation);
