/**
 * Transforms a ReactTable sorted array into a string.
 * @param {array} sorted ReactTable sorted array. It's an array of objects with parameters "id" and "desc".
 * @returns a sorting string of the form "param1,param2.desc".
 */
const reactTableSortedArrayToString = (sorted) =>
  sorted
    .filter((item) => item.id)
    .reduce((result, item, index) => {
      const separator = index ? ',' : '';
      const direction = item.desc ? '.desc' : '';
      return `${result}${separator}${item.id}${direction}`;
    }, '');

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  reactTableSortedArrayToString,
};
