import { Modal } from 'react-bootstrap';
import React from 'react';
import ReactJson from 'react-json-view';

export interface BullMqJobOpts {
  attempts: number;
  delay: number;
  jobId: string;
}

export type BullMqJobData = Record<string, any>;

export interface BullMqJob {
  name: string;
  data: BullMqJobData;
  opts: BullMqJobOpts;
  id: string;
  progress: number;
  returnvalue: boolean;
  stacktrace: Array<any>;
  attemptsMade: number;
  timestamp: number;
  finishedOn?: number;
  processedOn?: number;
}

interface JobDetailsModalProps {
  title: string;
  isOpen: boolean;
  job: BullMqJob;
  onCancel: () => void;
}

interface JobDetailsProps {
  job: BullMqJob;
}

const JobDetails = ({ job }: JobDetailsProps) => {
  return <ReactJson src={job} />;
};

const JobDetailsModal = ({ isOpen, title, onCancel, ...props }: JobDetailsModalProps) => (
  <Modal show={isOpen} onHide={onCancel} bsSize="large">
    <Modal.Header closeButton>
      <Modal.Title>Job: {title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <JobDetails {...props} />
    </Modal.Body>
  </Modal>
);

export default JobDetailsModal;
