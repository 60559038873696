import React from 'react';

import useSWR from 'swr';
import { APIResponse } from '../../../Typings/Services/Api';
import api from '../../../Services/Api';
import useSWRMutation from 'swr/mutation';
import ReactJson from 'react-json-view';

const fetcher = (url: string) => api.api.get(url).then((res) => res.data);
const clearFetcher = (url: string) => api.api.post(url).then((res) => res.data);

const Caches = () => {
  const { data, mutate: refreshCache, error } = useSWR<APIResponse<Cache>>(`${api.apiUrl}caches`, fetcher);
  const { trigger: clearTrigger, error: clearError } = useSWRMutation<APIResponse<Cache>>(
    `${api.apiUrl}caches/clear`,
    clearFetcher,
  );

  const onRefresh = () => {
    refreshCache().catch(console.error);
  };

  const onClear = () => {
    clearTrigger()
      .then(() => onRefresh())
      .catch(console.error);
  };

  return (
    <>
      <h2 className="title-text">Credentials Cache</h2>
      {data && <ReactJson src={data} />}
      {error && (
        <div>
          <div>ERROR:</div>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </div>
      )}
      {clearError && (
        <div>
          <div>Clear ERROR:</div>
          <pre>{JSON.stringify(clearError, null, 2)}</pre>
        </div>
      )}

      <button className="btn-primary" style={{ marginTop: '10px' }} onClick={onRefresh}>
        Refresh
      </button>
      <button className="btn-default" style={{ marginTop: '10px' }} onClick={onClear}>
        Clear
      </button>
      <div style={{ marginTop: 20 }}>
        <em>Clearing the credentials cache should not affect users.</em>
      </div>
    </>
  );
};

export default Caches;
