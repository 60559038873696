import { CardExpiryElement, CardNumberElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { RequiredField } from '../VirtualTerminal/RequiredField/RequiredField';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export class StripeCard extends Component {
  constructor(props) {
    super(props);
    this.handleCardNumberChange = this.handleCardNumberChange.bind(this);
    this.handleCardExpiryChange = this.handleCardExpiryChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleCreateSource = this.handleCreateSource.bind(this);
    this.handleFormReset = this.handleFormReset.bind(this);
    // we need to pass a handler to parent component so when form
    // is submitted, we can call tokenize.
    this.props.registerFormSubmitHandler(this.handleFormSubmit);
    this.props.registerFormResetHandler(this.handleFormReset);
  }

  handleCardNumberChange(event) {
    this.props.handleCardValidate({ isCardValid: event.complete });
  }

  handleCardExpiryChange(event) {
    this.props.handleCardExpiryValidate({ isExpiryDateValid: event.complete });
  }

  async handleFormSubmit() {
    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
      this.props.handleError?.('Stripe has not loaded yet.');
      return;
    }

    const element = elements.getElement(CardNumberElement);

    try {
      this.props.changeSubmitStatus?.(true);
      const { source, error } = await stripe.createSource(element, {
        type: 'card',
      });

      if (error) {
        this.props.changeSubmitStatus?.(false);
        this.props.handleError?.(error.message);
        return;
      }

      this.handleCreateSource(source);
    } catch (error) {
      this.props.changeSubmitStatus?.(false);
      this.props.handleError?.(error.message);
    }
  }

  /**
   * Clears element's values. see link below
   * https://stripe.com/docs/js/element/other_methods/clear
   */
  handleFormReset() {
    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    let el = elements.getElement(CardNumberElement);
    if (el && el.clear) el.clear();
    el = elements.getElement(CardExpiryElement);
    if (el && el.clear) el.clear();
  }

  handleCreateSource(source) {
    const {
      id: tokenizedCreditCard,
      card: { brand: ccType, exp_month: expirationMonth, exp_year: expirationYear, last4: ccLastFour },
    } = source;

    this.props.handleCardExpiryChange({ expirationMonth, expirationYear });
    this.props.handleTokenize({ tokenizedCreditCard, ccLastFour, ccType });
  }

  render() {
    const options = {
      style: {
        base: {
          fontSize: '16px',
          color: '#424770',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: 'pink',
        },
      },
    };
    return (
      <Col>
        <Row>
          <Col xs={6}>
            <span>
              Credit Card<sup className="required-marker">*</sup>
            </span>
            <RequiredField
              id="cc-number"
              isValidated={this.props.isValidated}
              message="Please provide a credit card"
              hasError={this.props.isValidated && !this.props.isCardValid}
            >
              <CardNumberElement onChange={this.handleCardNumberChange} options={options} className="form-control" />
            </RequiredField>
          </Col>
          <Col xs={6}>
            <span>
              Expiration Date<sup className="required-marker">*</sup>
            </span>
            <RequiredField
              id="exp-date"
              isValidated={this.props.isValidated}
              message="Please enter a valid expiration date"
              hasError={this.props.isValidated && !this.props.isExpiryDateValid}
            >
              <CardExpiryElement onChange={this.handleCardExpiryChange} options={options} className="form-control" />
            </RequiredField>
          </Col>
        </Row>
      </Col>
    );
  }
}

const propTypes = {
  isCardValid: PropTypes.bool,
  isExpiryDateValid: PropTypes.bool,
  isValidated: PropTypes.bool.isRequired,
  handleTokenize: PropTypes.func.isRequired,
  changeSubmitStatus: PropTypes.func,
  registerFormSubmitHandler: PropTypes.func.isRequired,
  registerFormResetHandler: PropTypes.func.isRequired,
  handleCardValidate: PropTypes.func.isRequired,
  handleCardExpiryChange: PropTypes.func.isRequired,
  handleCardExpiryValidate: PropTypes.func.isRequired,
  handleError: PropTypes.func,
  stripe: PropTypes.any,
  elements: PropTypes.any,
};

StripeCard.propTypes = propTypes;

const InjectedStripeCard = (props) => (
  <Elements stripe={stripePromise}>
    <ElementsConsumer>{({ elements, stripe }) => <StripeCard {...{ ...props, elements, stripe }} />}</ElementsConsumer>
  </Elements>
);

InjectedStripeCard.propTypes = propTypes;

export default InjectedStripeCard;
