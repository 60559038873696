import React from 'react';
import { Col, ControlLabel, FormControl, FormGroup, Row } from 'react-bootstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment';

import ClassyButton from '../../ClassyButton/ClassyButton';
import './styles.scss';

import formatters from '../../../Helpers/formatters';
import ClassyAlert from '../../ClassyAlert/ClassyAlert';
import api from '../../../Services/Api';
import constants from '../../../Helpers/constants';
import history from '../../../Router/history';

const { REPORT_FTP_FIELDS, REPORT_FIELDS, WEEKLY_OPTIONS, DAILY_OPTIONS } = constants;

class CreateReports extends React.Component {
  defaultState = {
    reportUrl: '',
    fileNamePrefix: '',
    reportStartDate: '',
    timeRange: null,
    delimitedFormat: null,
    isConnectionValid: '',
    reportFrequency: null,
    isLoading: false,
    isSubmitting: false,
    ftpLocation: '',
    ftpPassword: '',
    ftpUserName: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      alertMessage: '',
      pageTitle: 'Create Scheduled Report',
      ...this.defaultState,
    };
  }

  async componentDidMount() {
    const { match: { params: { reportId = null } = {} } = {}, selectedOrgId } = this.props;

    if (reportId) {
      const response = await api.getReport({
        organizationId: selectedOrgId,
        reportId,
      });

      if (response.success) {
        const {
          reportUrl,
          fileNamePrefix,
          reportStartDate,
          timeRange,
          delimitedFormat,
          ftpLocation,
          ftpUserName,
          reportFrequency,
          ftpPassword,
        } = response.data;

        const data = {
          reportUrl,
          fileNamePrefix,
          reportStartDate: (reportStartDate && moment(reportStartDate).format('YYYY-MM-DD')) || '',
          reportFrequency,
          timeRange,
          delimitedFormat,
          ftpLocation,
          ftpUserName,
          ftpPassword,
          isConnectionValid: true,
        };

        if (reportFrequency && reportFrequency === 'daily') {
          this.setState({ timeRangeOptions: DAILY_OPTIONS });
        } else if (reportFrequency && reportFrequency === 'weekly') {
          this.setState({ timeRangeOptions: WEEKLY_OPTIONS });
        }

        this.setState({
          isEditing: true,
          pageTitle: 'Edit Scheduled Report',
          timeRangeOptions: reportFrequency === 'daily' ? DAILY_OPTIONS : WEEKLY_OPTIONS,
          ...data,
          defaultState: { ...data },
        });
      } else {
        this.setAlert(response.errors);
      }
    }
  }

  componentDidUpdate(prevprops) {
    const { selectedOrgId } = this.props;
    if (prevprops.selectedOrgId !== selectedOrgId) {
      history.push({ pathname: '/scheduled-reports' });
    }
  }

  setAlert = (alertMessage, showAlert = true) => {
    this.setState({ alertMessage, showAlert });
  };

  isFTPDisabled = () => {
    const { ftpUserName, ftpLocation, ftpPassword } = this.state;
    return !!(ftpLocation && ftpPassword && ftpUserName);
  };

  isFormValid = () => {
    const {
      reportUrl,
      fileNamePrefix,
      reportStartDate,
      timeRange,
      delimitedFormat,
      isConnectionValid,
      reportFrequency,
      isLoading,
      defaultState,
    } = this.state;

    const isChange =
      defaultState?.reportUrl === reportUrl &&
      defaultState?.fileNamePrefix === fileNamePrefix &&
      defaultState?.timeRange === timeRange &&
      defaultState?.delimitedFormat === delimitedFormat &&
      defaultState?.reportFrequency === reportFrequency &&
      defaultState?.reportStartDate === reportStartDate;

    if (reportFrequency === 'daily') {
      return !!(
        isConnectionValid &&
        reportUrl &&
        fileNamePrefix &&
        timeRange &&
        delimitedFormat &&
        reportFrequency &&
        !isLoading &&
        fileNamePrefix.length <= 100 &&
        !isChange
      );
    }

    return !!(
      isConnectionValid &&
      reportUrl &&
      reportStartDate &&
      fileNamePrefix &&
      timeRange &&
      delimitedFormat &&
      reportFrequency &&
      !isLoading &&
      fileNamePrefix.length <= 100 &&
      !isChange
    );
  };

  saveFtpConnection = async (e) => {
    e.preventDefault();
    const { ftpUserName, ftpLocation, ftpPassword } = this.state;

    try {
      this.setState({ isLoading: true, isConnectionValid: true });
      const response = await api.testFtpConnection({
        data: { ftpLocation, ftpUserName, ftpPassword },
      });

      if (response.success) {
        return this.setAlert(response.data);
      }

      return this.setAlert(response.errors);
    } catch (error) {
      this.setState({ isConnectionValid: false });
      this.setAlert(error.errors);
    } finally {
      this.setState({ isLoading: false });
    }

    return null;
  };

  resetConnection = (e) => {
    e.preventDefault();
    this.setState({ isConnectionValid: false });
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleDropdownChange = (selection) => (name) => {
    this.setState({ [name]: selection.value }, () => {
      const { reportFrequency } = this.state;

      if (name === 'reportFrequency') {
        this.setState({ timeRange: null, reportStartDate: '' });
      }

      if (reportFrequency && reportFrequency === 'daily') {
        this.setState({ timeRangeOptions: DAILY_OPTIONS });
      } else if (reportFrequency && reportFrequency === 'weekly') {
        this.setState({ timeRangeOptions: WEEKLY_OPTIONS });
      }
    });
  };

  submitForm = async (e) => {
    e.preventDefault();

    const {
      ftpLocation,
      ftpUserName,
      ftpPassword,
      reportUrl,
      fileNamePrefix,
      reportStartDate,
      timeRange,
      delimitedFormat,
      isEditing,
      reportFrequency,
    } = this.state;

    const { selectedOrgId, match: { params: { reportId = null } = {} } = {} } = this.props;

    const data = {
      reportUrl,
      fileNamePrefix,
      reportStartDate: (reportStartDate && moment(reportStartDate).format('YYYY-MM-DD')) || null,
      timeRange,
      delimitedFormat,
      ftpLocation,
      ftpUserName,
      ftpPassword,
    };

    this.setState({ isSubmitting: true });

    try {
      const response = isEditing
        ? await api.editScheduledReport({
            organizationId: selectedOrgId,
            data,
            reportId,
          })
        : await api.createScheduledReport({
            organizationId: selectedOrgId,
            data,
          });

      if (response.success) {
        const message = `${get(response, 'data.message')} First Report Date: ${get(response, 'data.firstReportDate')},
         Next Report Date: ${get(response, 'data.nextReportDate')}`;
        this.setAlert(message);

        if (!isEditing) {
          this.setState({ ...this.defaultState });
        }

        this.setState({
          defaultState: {
            ...data,
            reportFrequency,
            reportStartDate: reportStartDate || '',
          },
        });
      } else {
        this.setAlert(response.errors);
      }
    } catch (error) {
      this.setAlert(error.errors);
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  render() {
    const {
      showAlert,
      alertMessage,
      pageTitle,
      isConnectionValid,
      timeRangeOptions,
      reportFrequency,
      isLoading,
      isSubmitting,
    } = this.state;

    return (
      <div>
        <ClassyAlert
          show={showAlert}
          alertMessage={alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />
        <h2 className="title-text">{pageTitle}</h2>
        <form onSubmit={(e) => this.submitForm(e)}>
          <Row>
            {REPORT_FIELDS.map((field) => (
              <Col xs={6} key={field.name}>
                {!field.options ? (
                  <FormGroup
                    validationState={
                      (field.name === 'fileNamePrefix' && this.state[field.name].length > 100 && 'error') || null
                    }
                  >
                    <ControlLabel className="custom-label">{field.label}</ControlLabel>
                    <FormControl
                      className="custom-input"
                      name={field.name}
                      type={field.type}
                      disabled={field.name === 'reportStartDate' && (!reportFrequency || reportFrequency === 'daily')}
                      value={this.state[field.name]}
                      placeholder={field.placeholder}
                      onChange={this.handleInputChange}
                    />
                    <FormControl.Feedback />
                  </FormGroup>
                ) : (
                  <FormGroup controlId={field.name}>
                    <ControlLabel className="custom-label">{field.label}</ControlLabel>
                    <FormControl componentClass="react-select" name={field.name}>
                      <Select
                        options={field.name === 'timeRange' ? timeRangeOptions : field.options}
                        isDisabled={field.name === 'timeRange' && !timeRangeOptions}
                        value={formatters.getSingleOptionByValue(
                          field.name === 'timeRange' ? [...WEEKLY_OPTIONS, ...DAILY_OPTIONS] : field.options,
                          this.state[field.name],
                        )}
                        onChange={(e) => this.handleDropdownChange(e)(field.name)}
                      />
                    </FormControl>
                  </FormGroup>
                )}
              </Col>
            ))}
          </Row>

          <Row className="ftp-blog">
            {REPORT_FTP_FIELDS.map((field) => (
              <Col xs={6} key={field.name}>
                <FormGroup>
                  <ControlLabel className="custom-label">{field.label}</ControlLabel>
                  <FormControl
                    className="custom-input"
                    name={field.name}
                    type={field.type}
                    disabled={isConnectionValid}
                    value={this.state[field.name]}
                    placeholder={field.placeholder}
                    onChange={this.handleInputChange}
                  />
                  <FormControl.Feedback />
                </FormGroup>
              </Col>
            ))}

            <Col xs={12}>
              <ClassyButton
                className="custom-button"
                title={(isLoading && 'Loading...') || (isConnectionValid ? 'Reset Connection' : 'Save Connection')}
                disabled={isLoading || (!isConnectionValid && !this.isFTPDisabled())}
                onClick={(e) => (isConnectionValid ? this.resetConnection(e) : this.saveFtpConnection(e))}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <ClassyButton
                className="custom-button"
                title={isSubmitting ? 'Loading...' : 'Submit'}
                disabled={isSubmitting || !this.isFormValid()}
                type="submit"
              />
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

CreateReports.propTypes = {
  match: PropTypes.object,
  selectedOrgId: PropTypes.number,
};

const mapStateToProps = (state) => {
  const {
    selectedOrganization: { id: selectedOrgId },
  } = state.login;

  return {
    selectedOrgId,
  };
};

export default connect(mapStateToProps, null)(CreateReports);
