import { createActions, createReducer } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  createRecurringDonationMigrationJobSuccess: ['recurringDonationMigrationJob'],
  getRecurringDonationMigrationJobsFailure: ['recurringDonationMigrationJobsError'],
  getRecurringDonationMigrationJobsRequest: ['recurringDonationMigrationRequest'],
  getRecurringDonationMigrationJobsSuccess: ['recurringDonationMigrationJobs'],
});

export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  loadingRecurringDonationMigrationJobs: false,
  recurringDonationMigrationJobs: null,
  recurringDonationMigrationJobsError: {},
  recurringDonationMigrationJobsTotalPages: 0,
};

/* ------------- Reducers ------------- */
const getRecurringDonationMigrationJobsRequest = (state) => ({
  ...state,
  loadingRecurringDonationMigrationJobs: true,
});

const getRecurringDonationMigrationJobsFailure = (state, { recurringDonationMigrationJobsError }) => ({
  ...state,
  loadingRecurringDonationMigrationJobs: false,
  recurringDonationMigrationJobs: [],
  recurringDonationMigrationJobsError,
  recurringDonationMigrationJobsTotalPages: 0,
});

const getRecurringDonationMigrationJobsSuccess = (state, { recurringDonationMigrationJobs }) => ({
  ...state,
  loadingRecurringDonationMigrationJobs: false,
  recurringDonationMigrationJobs: recurringDonationMigrationJobs.data.jobs,
  recurringDonationMigrationJobsError: {},
  recurringDonationMigrationJobsTotalPages: recurringDonationMigrationJobs.data.pagination.total.pages,
});

const createRecurringDonationMigrationJobSuccess = (state, { recurringDonationMigrationJob }) => ({
  ...state,
  recurringDonationMigrationJobs: [recurringDonationMigrationJob, ...state.recurringDonationMigrationJobs],
});

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_RECURRING_DONATION_MIGRATION_JOB_SUCCESS]: createRecurringDonationMigrationJobSuccess,
  [Types.GET_RECURRING_DONATION_MIGRATION_JOBS_FAILURE]: getRecurringDonationMigrationJobsFailure,
  [Types.GET_RECURRING_DONATION_MIGRATION_JOBS_REQUEST]: getRecurringDonationMigrationJobsRequest,
  [Types.GET_RECURRING_DONATION_MIGRATION_JOBS_SUCCESS]: getRecurringDonationMigrationJobsSuccess,
});
