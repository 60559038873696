import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Checkbox, Col, ControlLabel, FormControl, FormGroup, HelpBlock, Row } from 'react-bootstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import constants from '../../../Helpers/constants';
import formatters from '../../../Helpers/formatters';
import utilities from '../../../Helpers/utilities';
import validators from '../../../Helpers/validators';
import api from '../../../Services/Api';
import ClassyAlert from '../../ClassyAlert/ClassyAlert';
import ClassyButton from '../../ClassyButton/ClassyButton';
import LoginActions from '../../../Redux/Login.redux';
import './index.scss';

class AddOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = this.formatInitialState();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFeaturesChange = this.handleFeaturesChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleCredentialChange = this.handleCredentialChange.bind(this);
    this.handleAddCompany = this.handleAddCompany.bind(this);
    this.handleSubmitCreateOrganization = this.handleSubmitCreateOrganization.bind(this);
    this.handleSubmitEditCredentials = this.handleSubmitEditCredentials.bind(this);
    this.handleSubmitEditGenericInfo = this.handleSubmitEditGenericInfo.bind(this);
    this.setAlert = this.setAlert.bind(this);
  }

  setAlert(alertMessage, showAlert = true) {
    this.setState({ alertMessage, showAlert });
  }

  formatInitialState(featureSelectOptions, companySelectOptions, credentialTypes) {
    return {
      classyOrganizationId: '',
      companyId: null,
      companySelectOptions: companySelectOptions || [],
      credentials: credentialTypes ? this.formatInitialStateCredentials() : null,
      credentialTypes: credentialTypes || [],
      featureIds: [],
      featureSelectOptions: featureSelectOptions || [],
      isEditing: false,
      isLoadingCompanies: false,
      name: '',
      minDonationAllowed: '',
      maxDonationAllowed: '',
      pageTitle: 'Create Organization',
      organizationOriginal: {},
      showAlert: false,
      alertMessage: '',
      isUseInternalCampaignNameVT: false,
    };
  }

  // We need to set up default values here so we can sync the value with state in the dynamic form below
  formatInitialStateCredentials() {
    const credentials = {};

    this.state.credentialTypes.forEach((credentialType) => {
      credentials[credentialType.id] = {
        clientId: '',
        clientSecret: '',
        credentialTypeId: credentialType.id,
      };
    });

    return credentials;
  }

  async componentDidMount() {
    let organizationId;
    if (this?.props?.match?.params?.organizationId) {
      organizationId = this.props.match.params.organizationId;
      this.getOrganizationById(organizationId);
      this.setState({ isEditing: true, pageTitle: 'Edit Organization' });
    }
    await this.getOptions(organizationId);

    this.setState({ credentials: this.formatInitialStateCredentials() });
  }

  async getCompanySelectOptions() {
    let errorMessage;

    try {
      const companyResponse = await api.getAllCompanies();

      if (companyResponse.success) {
        const selectOptions = formatters.formatSelectOptions(companyResponse.data);
        this.setState({ companySelectOptions: selectOptions });
      } else {
        errorMessage = 'We were unable to retrieve companies';
      }
    } catch (exception) {
      errorMessage = exception.errors;
    }
    if (errorMessage) {
      this.setAlert(errorMessage);
    }
  }

  async getOptions(organizationId) {
    // Load the features
    let errorMessage;

    try {
      const featureResponse = await api.getAllFeatures(organizationId);

      if (featureResponse.success) {
        const selectOptions = formatters.formatSelectOptions(featureResponse.data);
        this.setState({ featureSelectOptions: selectOptions });
      } else {
        errorMessage = 'We were unable to retrieve features';
      }
    } catch (exception) {
      errorMessage = exception.errors;
    }
    if (errorMessage) {
      this.setAlert(errorMessage);
    }

    // Now load the companies
    this.getCompanySelectOptions();

    // And now the credential types
    errorMessage = undefined;

    try {
      const credentialTypeResponse = await api.getAllCredentialTypes();

      if (credentialTypeResponse.success) {
        const credentialTypes =
          credentialTypeResponse?.data?.filter((type) => type.id !== constants.CLASSY_PAY_API_CREDENTIAL_ID) || [];
        this.setState({ credentialTypes });
      } else {
        errorMessage = 'We were unable to retrieve credential types';
      }
    } catch (exception) {
      errorMessage = exception.errors;
    }
    if (errorMessage) {
      this.setAlert(errorMessage);
    }
  }

  async getOrganizationById(organizationId) {
    let message;
    try {
      const getOrganizationByIdResponse = await api.getOrganizationById({
        organizationId,
      });
      if (getOrganizationByIdResponse.success) {
        const [organization] = getOrganizationByIdResponse.data;
        organization.featureIds = organization.Features.map((feature) => feature.id);
        this.setState({
          classyOrganizationId: organization.classyOrganizationId,
          emailDomain: organization.emailDomain,
          companyId: organization.companyId,
          featureIds: organization.featureIds,
          id: organization.id,
          name: organization.name,
          isEnabled: organization.isEnabled,
          organizationOriginal: organization,
          isUseInternalCampaignNameVT: organization.isUseInternalCampaignNameVT || false,
          minDonationAllowed: organization.minDonationAllowed,
          maxDonationAllowed: organization.maxDonationAllowed,
        });
      } else {
        message = 'We were unable to retrieve this organization';
      }
    } catch (exception) {
      message = exception.errors;
    }
    if (message) {
      this.setAlert(message);
    }
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleCompanyChange(selectedCompany) {
    this.setState({ companyId: selectedCompany.value });
  }

  handleFeaturesChange(selectedFeatures) {
    const featureIds = selectedFeatures.map((feature) => feature.value);
    this.setState({ featureIds });
  }

  handleCredentialChange(event) {
    // Get the type id for this credential
    const credentialTypeId = event.target.getAttribute('data-credentialtypeid');

    // Get the current list of credentials
    const { credentials } = this.state;

    // Get the existing credential for this type to update it
    const credential = credentials[credentialTypeId];
    if (credential) {
      // Update the credential for the field that was modified
      credential[event.target.name] = event.target.value;

      // Update the credentials array with the updated credential object
      credentials[credentialTypeId] = credential;

      this.setState({ credentials });
    }
  }

  isCredentialsFormValid() {
    let response = false;
    if (this.state.credentialTypes && this.state.credentials) {
      this.state.credentialTypes.forEach((credentialType) => {
        if (
          validators.getValidStateCredentials(this.state.credentials[credentialType.id]) ===
          constants.VALIDATION_STATES.SUCCESS
        ) {
          response = true;
        }
      });
    }
    return response;
  }

  isGenericInfoFormValid() {
    if (this.isGenericInfoFormChanged()) {
      return (
        validators.getValidStateName(this.state.name) === constants.VALIDATION_STATES.SUCCESS &&
        validators.getValidStateId(this.state.companyId) === constants.VALIDATION_STATES.SUCCESS &&
        validators.getValidStateId(this.state.classyOrganizationId) === constants.VALIDATION_STATES.SUCCESS &&
        validators.getIsValidMinDonationAllowed(this.state.minDonationAllowed) !== constants.VALIDATION_STATES.ERROR &&
        validators.getIsValidMaxDonationAllowed(this.state.minDonationAllowed, this.state.maxDonationAllowed) !==
          constants.VALIDATION_STATES.ERROR &&
        validators.getValidStateIdArray(this.state.featureIds) === constants.VALIDATION_STATES.SUCCESS &&
        (validators.getValidEmailDomain(this.state.emailDomain) === constants.VALIDATION_STATES.SUCCESS ||
          !this.state.emailDomain) &&
        this.getValidAccessibleFeatures() === constants.VALIDATION_STATES.SUCCESS
      );
    }

    return false;
  }

  isGenericInfoFormChanged() {
    let response = false;
    const { organizationOriginal } = this.state;
    if (
      Number(this.state.classyOrganizationId) !== Number(organizationOriginal.classyOrganizationId) ||
      this.state.companyId !== organizationOriginal.companyId ||
      !utilities.arraysEqual(this.state.featureIds, organizationOriginal.featureIds) ||
      this.state.name !== organizationOriginal.name ||
      this.state.isUseInternalCampaignNameVT !== organizationOriginal.isUseInternalCampaignNameVT ||
      this.state.emailDomain !== organizationOriginal.emailDomain ||
      Number(this.state.minDonationAllowed) !== Number(organizationOriginal.minDonationAllowed) ||
      Number(this.state.maxDonationAllowed) !== Number(organizationOriginal.maxDonationAllowed)
    ) {
      response = true;
    }
    return response;
  }

  getValidAccessibleFeatures() {
    return validators.getValidAccessibleFeatures(
      this.state.companyId,
      this.state.companySelectOptions,
      this.state.featureIds,
      this.state.featureSelectOptions,
    );
  }

  showAccessibleFeaturesError() {
    const valid = this.getValidAccessibleFeatures();

    if (valid === null) return false;

    return valid !== constants.VALIDATION_STATES.SUCCESS;
  }

  async handleSubmitCreateOrganization(event) {
    event.preventDefault();

    if (this.isGenericInfoFormValid() && this.isCredentialsFormValid()) {
      let response;
      try {
        const newOrganizationObj = {
          companyId: this.state.companyId,
          name: this.state.name,
          classyOrganizationId: this.state.classyOrganizationId,
          minDonationAllowed: this.state.minDonationAllowed || null,
          maxDonationAllowed: this.state.maxDonationAllowed || null,
          emailDomain: this.state.emailDomain,
          featureIds: this.state.featureIds,
          credentials: Object.values(this.state.credentials).filter(
            (credential) => credential != null && credential.clientId && credential.clientSecret,
          ),
        };

        const createOrganizationResponse = await api.createOrganization(newOrganizationObj);
        if (createOrganizationResponse.data && createOrganizationResponse.data.id) {
          response = `Organization id ${createOrganizationResponse.data.id} was successfully created.`;

          // Reset form, passing in the current featureSelectOptions to preserve data
          this.setState(
            this.formatInitialState(
              this.state.featureSelectOptions,
              this.state.companySelectOptions,
              this.state.credentialTypes,
            ),
          );
        } else {
          response = constants.MESSAGES.ERROR_DEFAULT;
        }
      } catch (exception) {
        response = exception.errors;
      }
      this.setAlert(response);
    }
  }

  async handleSubmitEditGenericInfo(event) {
    event.preventDefault();
    let message;

    if (this.isGenericInfoFormValid()) {
      try {
        const organization = {
          classyOrganizationId: this.state.classyOrganizationId,
          companyId: this.state.companyId,
          featureIds: this.state.featureIds,
          name: this.state.name,
          isUseInternalCampaignNameVT: this.state.isUseInternalCampaignNameVT,
          emailDomain: this.state.emailDomain,
          minDonationAllowed: this.state.minDonationAllowed || null,
          maxDonationAllowed: this.state.maxDonationAllowed || null,
        };

        const { organizationId } = this.props.match.params;
        const editOrganizationResponse = await api.editOrganizationById({
          organization,
          organizationId,
        });

        const result = await api.getUserSession();
        const session = result.data;
        await this.props.updateUser(session.features, session.user, session.role, session.organizations);

        if (editOrganizationResponse.success) {
          message = editOrganizationResponse.data;
          this.setState({ organizationOriginal: organization });
        } else {
          message = constants.MESSAGES.ERROR_DEFAULT;
        }
      } catch (exception) {
        message = exception.errors;
      }
    } else {
      message = 'Info invalid';
    }
    this.setAlert(message);
  }

  isValidNewCompany(inputValue, value, options) {
    let isValid = true;

    if (inputValue) {
      options.forEach((option) => {
        if (inputValue.toLowerCase() === option.label.toLowerCase()) {
          isValid = false;
        }
      });
    } else {
      isValid = false;
    }

    return isValid;
  }

  async handleAddCompany(companyName) {
    this.setState({ isLoadingCompanies: true });

    let response;
    try {
      const newCompanyObj = {
        name: companyName,
      };

      const createCompanyResponse = await api.createCompany(newCompanyObj);
      if (createCompanyResponse.data && createCompanyResponse.data.id) {
        await this.getCompanySelectOptions();

        this.setState({ companyId: createCompanyResponse.data.id });
        response = 'Company was successfully created.';
      } else {
        response = constants.MESSAGES.ERROR_DEFAULT;
      }
    } catch (exception) {
      response = exception.errors;
    }
    this.setAlert(response);

    this.setState({ isLoadingCompanies: false });
  }

  async handleSubmitEditCredentials(event) {
    event.preventDefault();
    let message;

    if (this.isCredentialsFormValid()) {
      const organization = {
        classyOrganizationId: this.state.organizationOriginal.classyOrganizationId,
        credentials: Object.values(this.state.credentials).filter(
          (credential) => credential != null && credential.clientId && credential.clientSecret,
        ),
      };
      try {
        const { organizationId } = this.props.match.params;
        const editOrganizationCredentialsResponse = await api.editOrganizationCredentialsById({
          organization,
          organizationId,
        });
        if (editOrganizationCredentialsResponse.success) {
          message = editOrganizationCredentialsResponse.data;
        }
      } catch (exception) {
        message = exception.errors;
      }
    }
    if (message) {
      this.setAlert(message);
    }
  }

  activateOrganization = async () => {
    let message;
    const { organizationOriginal: organization, id } = this.state;
    const organizationObj = {
      isEnabled: organization.isEnabled ? 0 : 1, // Toggle binary value isEnabled
    };
    try {
      const editOrganizationResponse = await api.editOrganizationById({
        organization: organizationObj,
        organizationId: id,
      });
      if (editOrganizationResponse.success) {
        message = editOrganizationResponse.data;
      }
    } catch (exception) {
      message = exception.errors;
    }
    if (message) {
      this.setAlert(message);
      this.getOrganizationById(id);
    }
  };

  renderCredentialsForm(credentialTypes) {
    return (
      <form>
        {credentialTypes.map((credentialType) => (
          <FormGroup
            key={credentialType.id}
            controlId={credentialType.name}
            validationState={validators.getValidStateCredentials(this.state.credentials[credentialType.id])}
          >
            <ControlLabel>{credentialType.friendlyName} Credentials</ControlLabel>
            <div className="margin-bottom-large">
              <span>Client Id</span>
              <FormControl
                name="clientId"
                data-credentialtypeid={credentialType.id}
                type="text"
                value={this.state.credentials[credentialType.id].clientId}
                onChange={this.handleCredentialChange}
              />
            </div>
            <div>
              <span>Client Secret</span>
              <FormControl
                name="clientSecret"
                data-credentialtypeid={credentialType.id}
                type="text"
                value={this.state.credentials[credentialType.id].clientSecret}
                onChange={this.handleCredentialChange}
              />
            </div>
          </FormGroup>
        ))}
        {this.state.isEditing ? (
          <ClassyButton
            title="Submit"
            disabled={!this.isCredentialsFormValid()}
            onClick={this.handleSubmitEditCredentials}
          />
        ) : null}
      </form>
    );
  }

  render() {
    const {
      classyOrganizationId,
      featureIds,
      featureSelectOptions,
      isEditing,
      pageTitle,
      isUseInternalCampaignNameVT,
      emailDomain,
      minDonationAllowed,
      maxDonationAllowed,
    } = this.state;

    return (
      <div>
        <ClassyAlert
          show={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />
        <div className="flexRow contentSpaced">
          <h2 className="title-text">{pageTitle}</h2>
          {isEditing && (
            <ClassyButton
              className="classy-button"
              title={
                this.state.isEnabled
                  ? constants.ACTIVE_STATUS.toggleActionLabel
                  : constants.DEACTIVATED_STATUS.toggleActionLabel
              }
              onClick={this.activateOrganization}
            />
          )}
        </div>
        <form>
          <FormGroup controlId="name" validationState={validators.getValidStateName(this.state.name)}>
            <ControlLabel>Name</ControlLabel>
            <FormControl
              name="name"
              type="text"
              value={this.state.name}
              placeholder="Classy"
              onChange={this.handleInputChange}
            />
            <FormControl.Feedback />
          </FormGroup>

          <FormGroup controlId="companySelect" validationState={validators.getValidStateId(this.state.companyId)}>
            <ControlLabel>Company</ControlLabel>
            <FormControl componentClass="react-select" name="company">
              <CreatableSelect
                options={this.state.companySelectOptions}
                value={formatters.getSingleOptionByValue(this.state.companySelectOptions, this.state.companyId)}
                onChange={this.handleCompanyChange.bind(this)}
                onCreateOption={this.handleAddCompany}
                isValidNewOption={this.isValidNewCompany}
                isDisabled={this.state.isLoadingCompanies}
                isLoading={this.state.isLoadingCompanies}
              />
            </FormControl>
          </FormGroup>

          <FormGroup
            controlId="classyOrganizationId"
            validationState={validators.getValidStateId(classyOrganizationId)}
          >
            <ControlLabel>Classy Organization Id</ControlLabel>
            <FormControl
              name="classyOrganizationId"
              type="text"
              value={classyOrganizationId || ''}
              placeholder="123456"
              onChange={this.handleInputChange}
            />
            <FormControl.Feedback />
          </FormGroup>

          <FormGroup controlId="emailDomain" validationState={validators.getValidEmailDomain(emailDomain)}>
            <ControlLabel>Email Domain</ControlLabel>
            <FormControl name="emailDomain" type="text" value={emailDomain || ''} onChange={this.handleInputChange} />
            <FormControl.Feedback />
          </FormGroup>

          <FormGroup controlId="featuresSelect" validationState={this.getValidAccessibleFeatures()}>
            <ControlLabel>Accessible Features</ControlLabel>
            <FormControl componentClass="react-select" name="features">
              <Select
                isMulti
                options={featureSelectOptions}
                value={formatters.getMultipleOptionsByValue(featureSelectOptions, featureIds)}
                onChange={this.handleFeaturesChange}
              />
            </FormControl>
            {this.showAccessibleFeaturesError() && (
              <HelpBlock>
                The {constants.FEATURES.RLC_MAPPING} feature can only be used by companies containing '
                {constants.TSA_COMPANY_NAME}' in their name.
              </HelpBlock>
            )}
          </FormGroup>

          <FormGroup
            controlId="minDonationAllowed"
            validationState={validators.getIsValidMinDonationAllowed(minDonationAllowed)}
          >
            <ControlLabel>Minimum Donation Allowed</ControlLabel>
            <FormControl
              name="minDonationAllowed"
              type="text"
              value={minDonationAllowed?.toString() || ''}
              placeholder="5"
              onChange={this.handleInputChange}
            />
            <FormControl.Feedback />
          </FormGroup>

          <FormGroup
            controlId="maxDonationAllowed"
            validationState={validators.getIsValidMaxDonationAllowed(minDonationAllowed, maxDonationAllowed)}
          >
            <ControlLabel>Maximum Donation Allowed</ControlLabel>
            <FormControl
              name="maxDonationAllowed"
              type="text"
              value={maxDonationAllowed?.toString() || ''}
              placeholder="5000"
              onChange={this.handleInputChange}
            />
            <FormControl.Feedback />
          </FormGroup>

          <FormGroup xs={12}>
            <Row>
              <Col xs={12}>
                <Checkbox
                  checked={isUseInternalCampaignNameVT}
                  onChange={(e) =>
                    this.setState({
                      isUseInternalCampaignNameVT: e.target.checked,
                    })
                  }
                >
                  Use Internal Campaign Name in Virtual Terminal
                </Checkbox>
              </Col>
            </Row>
          </FormGroup>
          {isEditing ? (
            <ClassyButton
              title="Submit"
              disabled={!this.isGenericInfoFormValid()}
              onClick={this.handleSubmitEditGenericInfo}
            />
          ) : null}
        </form>
        {this.state.credentials ? this.renderCredentialsForm(this.state.credentialTypes) : null}
        {!isEditing ? (
          <ClassyButton
            title="Submit"
            disabled={!this.isGenericInfoFormValid() || !this.isCredentialsFormValid()}
            onClick={this.handleSubmitCreateOrganization}
          />
        ) : null}
      </div>
    );
  }
}

AddOrganization.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  updateUser: (featurePermissions, user, userRole, organizations) =>
    dispatch(LoginActions.updateUser(featurePermissions, user, userRole, organizations)),
});

export default connect(null, mapDispatchToProps)(AddOrganization);
