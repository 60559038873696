import React, { useEffect, useState } from 'react';

export const useDebouncedState = <T extends any>(
  initialState: T,
  delay = 350,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [debouncedValue, setDebouncedValue] = useState<T>(initialState);
  const [requestedValue, setRequestedValue] = useState<T>(initialState);

  useEffect(() => {
    const delayFn = setTimeout(() => setDebouncedValue(requestedValue), delay);
    return () => clearTimeout(delayFn);
  }, [requestedValue, delay]);
  return [debouncedValue, setRequestedValue];
};

export default useDebouncedState;
