import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  clearTransactions: null,
  setLoadingTransactions: null,
  transactionSearchRequest: ['organizationId'],
  transactionSearchSuccess: ['transactions'],
  transactionSearchFailure: ['transactionSearchError'],
});

export const TransactionTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  transactions: [],
  transactionSearchError: null,
  loadingTransactions: false,
};

/* ------------- Reducers ------------- */
const clearTransactions = (state) => ({
  ...state,
  transactions: [],
  loadingTransactions: false,
});

const setLoadingTransactions = (state) => ({
  ...state,
  loadingTransactions: true,
});

const transactionSearchRequest = (state, { organizationId }) => ({
  ...state,
  organizationId,
  loadingTransactions: true,
  transactionSearchError: null,
});

const transactionSearchSuccess = (state, { transactions }) => ({
  ...state,
  transactions,
  loadingTransactions: false,
  transactionSearchError: null,
});

const transactionSearchFailure = (state, { transactionSearchError }) => ({
  ...state,
  transactionSearchError,
  loadingTransactions: false,
});

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.CLEAR_TRANSACTIONS]: clearTransactions,
  [Types.SET_LOADING_TRANSACTIONS]: setLoadingTransactions,
  [Types.TRANSACTION_SEARCH_REQUEST]: transactionSearchRequest,
  [Types.TRANSACTION_SEARCH_SUCCESS]: transactionSearchSuccess,
  [Types.TRANSACTION_SEARCH_FAILURE]: transactionSearchFailure,
});
