import { createReducer, createActions } from 'reduxsauce';

interface OfflineDonationsState {
  templateData: any;
  campaigns: Array<any>;
  fundraiserPages: Array<any>;
  fundraiserTeams: Array<any>;
  createOfflineDonationsResponse: object;
}

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  addTemplateRow: ['row'],
  clearOrganizationData: null,
  clearTemplateData: null,
  createOfflineDonationsFailure: ['createOfflineDonationsErrors'],
  createOfflineDonationsSuccess: ['createOfflineDonationsResponse'],
  getOfflineDonationsFailure: ['getOfflineDonationsError'],
  getOfflineDonationsSuccess: ['getOfflineDonationsResponse'],
  setCampaigns: ['campaigns'],
  setFundraiserPages: ['fundraiserPages'],
  setFundraiserTeams: ['fundraiserTeams'],
  setLoadingOfflineDonations: null,
  setProgramDesignations: ['programDesignations'],
  updateOfflineTemplate: ['templateData'],
});

export const OfflineDonationTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  campaigns: [],
  fundraiserPages: [],
  fundraiserTeams: [],
  programDesignations: [],
  loadingOfflineDonations: false,
  createOfflineDonationsResponse: {},
  createOfflineDonationsErrors: [],
  getOfflineDonationsError: {},
  getOfflineDonationsResponse: [],
  templateData: [],
  templateRow: {
    source: 'individual',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    companyName: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    currency: '',
    amount: null,
    makeDonationAnonymous: false,
    donorComment: '',
    paymentType: '',
    checkNumber: '',
    date: '',
    campaignId: null,
    fundraiserPageId: null,
    fundraiserTeamId: null,
    programDesignationId: null,
    salesForce: false,
    q1Id: null,
    q1Answer: '',
    q2Id: null,
    q2Answer: '',
    q3Id: null,
    q3Answer: '',
    q4Id: null,
    q4Answer: '',
    q5Id: null,
    q5Answer: '',
    q6Id: null,
    q6Answer: '',
    q7Id: null,
    q7Answer: '',
    q8Id: null,
    q8Answer: '',
    q9Id: null,
    q9Answer: '',
    q10Id: null,
    q10Answer: '',
  },
};

/* ------------- Reducers ------------- */
const addTemplateRow = (state: OfflineDonationsState, { row }: any) => ({
  ...state,
  templateData: [...state.templateData, row],
});

const clearOrganizationData = (state: OfflineDonationsState) => ({
  ...state,
  campaigns: [],
  fundraiserPages: [],
  fundraiserTeams: [],
  createOfflineDonationsResponse: {},
});

const clearTemplateData = (state: OfflineDonationsState) => ({
  ...state,
  templateData: [],
  loadingOfflineDonations: false,
});

const createOfflineDonationsFailure = (state: OfflineDonationsState, { createOfflineDonationsErrors }: any) => ({
  ...state,
  createOfflineDonationsErrors,
  createOfflineDonationsResponse: {},
  loadingOfflineDonations: false,
});

const createOfflineDonationsSuccess = (state: OfflineDonationsState, { createOfflineDonationsResponse }: any) => ({
  ...state,
  createOfflineDonationsResponse,
  createOfflineDonationsErrors: [],
  loadingOfflineDonations: false,
});

const getOfflineDonationsFailure = (state: OfflineDonationsState, { getOfflineDonationsError }: any) => ({
  ...state,
  getOfflineDonationsError,
  getOfflineDonationsResponse: [],
  loadingOfflineDonations: false,
});

const getOfflineDonationsSuccess = (state: OfflineDonationsState, { getOfflineDonationsResponse }: any) => ({
  ...state,
  getOfflineDonationsResponse,
  getOfflineDonationsError: {},
  loadingOfflineDonations: false,
});

const setLoadingOfflineDonations = (state: OfflineDonationsState) => ({
  ...state,
  loadingOfflineDonations: true,
});

const setCampaigns = (state: OfflineDonationsState, { campaigns }: any) => ({ ...state, campaigns });

const setFundraiserPages = (state: OfflineDonationsState, { fundraiserPages }: any) => ({
  ...state,
  fundraiserPages,
});

const setFundraiserTeams = (state: OfflineDonationsState, { fundraiserTeams }: any) => ({
  ...state,
  fundraiserTeams,
});

const setProgramDesignations = (state: OfflineDonationsState, { programDesignations }: any) => ({
  ...state,
  programDesignations,
});

const updateOfflineTemplate = (state: OfflineDonationsState, { templateData }: any) => ({
  ...state,
  templateData,
});

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_TEMPLATE_ROW]: addTemplateRow,
  [Types.CLEAR_ORGANIZATION_DATA]: clearOrganizationData,
  [Types.CLEAR_TEMPLATE_DATA]: clearTemplateData,
  [Types.CREATE_OFFLINE_DONATIONS_FAILURE]: createOfflineDonationsFailure,
  [Types.CREATE_OFFLINE_DONATIONS_SUCCESS]: createOfflineDonationsSuccess,
  [Types.GET_OFFLINE_DONATIONS_FAILURE]: getOfflineDonationsFailure,
  [Types.GET_OFFLINE_DONATIONS_SUCCESS]: getOfflineDonationsSuccess,
  [Types.SET_CAMPAIGNS]: setCampaigns,
  [Types.SET_LOADING_OFFLINE_DONATIONS]: setLoadingOfflineDonations,
  [Types.SET_FUNDRAISER_PAGES]: setFundraiserPages,
  [Types.SET_FUNDRAISER_TEAMS]: setFundraiserTeams,
  [Types.SET_PROGRAM_DESIGNATIONS]: setProgramDesignations,
  [Types.UPDATE_OFFLINE_TEMPLATE]: updateOfflineTemplate,
});
