import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  onMinimumResponse: ['minimumResponse'],
  onTeamResponse: ['teamResponse'],
  onPageResponse: ['pageResponse'],
  onStoryResponse: ['storyResponse'],
  onRecurringResponse: ['recurringResponse'],
  clearMinimumResponse: null,
  clearTeamResponse: null,
  clearPageResponse: null,
  clearStoryResponse: null,
  clearRecurringResponse: null,
  setLoading: null,
  clearLoading: null,
  validateMigrationsSuccess: ['validateMigrationsResponse'],
  validateMigrationsFailure: ['validateMigrationsError'],
  clearValidateMigrationsError: null,
  clearValidateMigrationsResponse: null,
});

export const AppTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  loading: false,
  error: null,
  healthCheck: {},
  minimumResponse: {},
  teamResponse: {},
  pageResponse: {},
  storyResponse: {},
  recurringResponse: {},
  validateMigrationsResponse: {},
  validateMigrationsError: [],
};

/* ------------- Reducers ------------- */
const onMinimumResponse = (state, { minimumResponse }) => ({
  ...state,
  minimumResponse,
  loading: false,
});

const onTeamResponse = (state, { teamResponse }) => ({
  ...state,
  teamResponse,
  loading: false,
});

const onPageResponse = (state, { pageResponse }) => ({
  ...state,
  pageResponse,
  loading: false,
});

const onStoryResponse = (state, { storyResponse }) => ({
  ...state,
  storyResponse,
  loading: false,
});

const onRecurringResponse = (state, { recurringResponse }) => ({
  ...state,
  recurringResponse,
  loading: false,
});

const clearMinimumResponse = (state) => ({
  ...state,
  minimumResponse: {},
  loading: false,
});

const clearTeamResponse = (state) => ({
  ...state,
  teamResponse: {},
  loading: false,
});

const clearStoryResponse = (state) => ({
  ...state,
  storyResponse: {},
  loading: false,
});

const clearPageResponse = (state) => ({
  ...state,
  pageResponse: {},
  loading: false,
});

const clearRecurringResponse = (state) => ({
  ...state,
  recurringResponse: {},
  loading: false,
});

const setLoading = (state) => ({ ...state, loading: true });

const clearLoading = (state) => ({ ...state, loading: false });

const validateMigrationsSuccess = (state, { validateMigrationsResponse }) => ({
  ...state,
  validateMigrationsResponse,
  validateMigrationsError: [],
  loading: false,
});

const validateMigrationsFailure = (state, { validateMigrationsError }) => ({
  ...state,
  validateMigrationsError: validateMigrationsError.errors,
  validateMigrationsResponse: {},
  loading: false,
});

const clearValidateMigrationsError = (state) => ({
  ...state,
  validateMigrationsError: [],
});

const clearValidateMigrationsResponse = (state) => ({
  ...state,
  validateMigrationsResponse: {},
});

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.ON_MINIMUM_RESPONSE]: onMinimumResponse,
  [Types.ON_TEAM_RESPONSE]: onTeamResponse,
  [Types.ON_PAGE_RESPONSE]: onPageResponse,
  [Types.ON_STORY_RESPONSE]: onStoryResponse,
  [Types.ON_RECURRING_RESPONSE]: onRecurringResponse,
  [Types.CLEAR_RECURRING_RESPONSE]: clearRecurringResponse,
  [Types.CLEAR_MINIMUM_RESPONSE]: clearMinimumResponse,
  [Types.CLEAR_TEAM_RESPONSE]: clearTeamResponse,
  [Types.CLEAR_PAGE_RESPONSE]: clearPageResponse,
  [Types.CLEAR_STORY_RESPONSE]: clearStoryResponse,
  [Types.SET_LOADING]: setLoading,
  [Types.CLEAR_LOADING]: clearLoading,
  [Types.VALIDATE_MIGRATIONS_SUCCESS]: validateMigrationsSuccess,
  [Types.VALIDATE_MIGRATIONS_FAILURE]: validateMigrationsFailure,
  [Types.CLEAR_VALIDATE_MIGRATIONS_ERROR]: clearValidateMigrationsError,
  [Types.CLEAR_VALIDATE_MIGRATIONS_RESPONSE]: clearValidateMigrationsResponse,
});
