import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import useSWRMutation from 'swr/mutation';
import {
  AddCampaignTaskLogEntry,
  AddDesignationTaskLogEntry,
  GenericFailedTaskLogEntry,
  LogEntry,
  RlcCommandLog,
  RlcCommandLogStatus,
} from '../../Services/Rlc/RlcCommandLog';
import api from '../../Services/Api';
import ReactJson, { CollapsedFieldProps } from 'react-json-view';

import './LogDetails.scss';

export interface LogDetailsProps {
  log: RlcCommandLog;
  onRetry: (id: number) => void;
}

export interface LogRetryDetailsProps {
  log: RlcCommandLog;
  retryStatus?: 'loading' | 'success' | 'error';
}

const shouldCollapse = ({ name }: CollapsedFieldProps) => {
  return name === 'entries';
};

const isAddDesignationTaskResult = (entry: LogEntry): entry is AddDesignationTaskLogEntry => {
  if (Object(entry)) {
    return 'designations' in entry;
  } else {
    return false;
  }
};
const isAddCampaignsTaskResult = (entry: LogEntry): entry is AddCampaignTaskLogEntry => {
  if (Object(entry)) {
    return 'campaigns' in entry;
  } else {
    return false;
  }
};
const isGenericFailedTaskResult = (entry: LogEntry): entry is GenericFailedTaskLogEntry => {
  if (Object(entry)) {
    return 'status' in entry && Object.keys(entry).length === 1;
  } else {
    return false;
  }
};

interface EntrySummary {
  status: string[];
  childDesignationIds: Array<number>;
  campaignDesignationIds: Array<number>;
}

const getEntrySummary = (log: RlcCommandLog): EntrySummary => {
  const childDesignationIds = log.entries.flatMap((entry) => {
    let results: Array<number> = [];
    if (entry.log && Object.keys(entry.log).length > 0) {
      if (isAddDesignationTaskResult(entry.log)) {
        const ids = entry.log.designations.filter((x) => !x.success).map((x) => (x as any).taskData.childDesignationId);
        results = results.concat(ids);
      }
    }
    return results;
  });
  const campaignDesignationIds = log.entries.flatMap((entry) => {
    let results: Array<number> = [];
    if (entry.log && Object.keys(entry.log).length > 0) {
      if (isAddCampaignsTaskResult(entry.log)) {
        const ids = entry.log.campaigns.filter((x) => !x.success).map((x) => (x as any).taskData.campaignDesignationId);
        results = results.concat(ids);
      }
    }
    return results;
  });

  const genericError = log.entries.flatMap((entry) => {
    if (entry.log && Object.keys(entry.log).length > 0) {
      if (isGenericFailedTaskResult(entry.log) && entry.log.status) {
        return [entry.log.status];
      }
    }

    return [];
  });

  return {
    status: genericError,
    childDesignationIds,
    campaignDesignationIds,
  };
};

interface LogFailureSummaryProps {
  log: RlcCommandLog;
  entrySummary: EntrySummary;
  onRetry: (id: number) => void;
}

const LogFailureSummary = ({ log, entrySummary, onRetry }: LogFailureSummaryProps) => {
  const showRetry = log.status === RlcCommandLogStatus.Failed && log.command === 'AddCampaignTask';

  const handleRetry = useCallback(() => {
    onRetry(log.id);
  }, [log, onRetry]);

  return (
    <div className="logDetailFailure">
      <div className="titleContainer">
        <h4>Failures</h4>
        {showRetry && <button onClick={handleRetry}>Retry</button>}
      </div>
      {entrySummary.status.length > 0 && (
        <>
          <h5>Generic Errors ({entrySummary.status.length}):</h5>
          <div>{entrySummary.status.join('\n')}</div>
        </>
      )}
      {entrySummary.childDesignationIds.length > 0 && (
        <>
          <h5>Designations ({entrySummary.childDesignationIds.length}):</h5>
          <div>{entrySummary.childDesignationIds.join(', ')}</div>
        </>
      )}
      {entrySummary.campaignDesignationIds.length > 0 && (
        <>
          <h5>Campaign Designations: ({entrySummary.campaignDesignationIds.length})</h5>
          <div>{entrySummary.campaignDesignationIds.join(', ')}</div>
        </>
      )}
    </div>
  );
};

const LogDetails = ({ log, onRetry }: LogDetailsProps) => {
  const entrySummary = getEntrySummary(log);
  return (
    <>
      <LogFailureSummary log={log} entrySummary={entrySummary} onRetry={onRetry}></LogFailureSummary>
      <hr />
      <ReactJson shouldCollapse={shouldCollapse} src={log} />;
    </>
  );
};

const LogRetryDetails = ({ retryStatus }: LogRetryDetailsProps) => {
  let message = 'There was an error while trying to schedule the retry of this command.';
  if (retryStatus === 'loading') {
    message = 'Preparing the data to retry the command...';
  } else if (retryStatus === 'success') {
    message = 'Command retry successfully scheduled.';
  }

  return <>{message}</>;
};

interface LogDetailsModalProps {
  title: string;
  isOpen: boolean;
  log: RlcCommandLog;
  onCancel: () => void;
}

const postFetcher = (_: string, commandId: { arg: string }) => {
  console.log(commandId);

  return api.api.post(`${api.apiUrl}rlc/logs/${commandId.arg}/retry`).then((res) => res.data);
};

// SEE: https://react-bootstrap-v3.netlify.app/components/modal/
const LogDetailsModal = ({ isOpen, title, onCancel, ...props }: LogDetailsModalProps) => {
  const [isRetrying, setIsRetrying] = useState(false);
  const [retryStatus, setRetryStatus] = useState<LogRetryDetailsProps['retryStatus']>('loading');

  const [key, setKey] = useState(Date.now());
  const { trigger } = useSWRMutation(`${api.apiUrl}rlc/logs/retry${key}`, postFetcher);

  useEffect(() => {
    if (isOpen) {
      setKey(Date.now());
    }
  }, [isOpen]);

  const handleRetryCommand = useCallback(
    async (commandId: number) => {
      setRetryStatus('loading');
      setIsRetrying(true);

      try {
        await trigger(commandId.toString());
        setRetryStatus('success');
      } catch (err) {
        setRetryStatus('error');
      }
    },
    [trigger],
  );

  return (
    <Modal show={isOpen} onHide={onCancel} bsSize="large">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isRetrying && <LogDetails onRetry={handleRetryCommand} {...props} />}
        {isRetrying && <LogRetryDetails log={props.log} retryStatus={retryStatus} />}
      </Modal.Body>
    </Modal>
  );
};

export default LogDetailsModal;
