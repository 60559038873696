import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import React, { ChangeEvent, FormEventHandler, useCallback, useState } from 'react';
import ClassyButton from '../../ClassyButton/ClassyButton';
import { SimpleValidationResult } from '../../../Helpers/validators';
import ValidationMessage, { getValidationState } from '../../RLC/ValidationMessage';
import RlcGroupSelector from './RlcGroupSelector';
import { useDesignationCount } from '../../RLC/RlcHooks';
import constants from '../../../Helpers/constants';

interface CreateRlcAccountModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onSave: (rlcGroupId: number, organizationId: number) => Promise<boolean>;
  selectedClassyOrganizationId: number;
  selectedOrganizationId: number;
}

const CreateRlcAccountModal = ({
  isOpen,
  onCancel,
  onSave,
  selectedClassyOrganizationId,
  selectedOrganizationId,
}: CreateRlcAccountModalProps) => {
  const [classyOrganizationId, setClassyOrganizationId] = useState<string>('');
  const [isPostConfirmation, setIsPostConfirmation] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<SimpleValidationResult>({});
  const [rlcGroupId, setRlcGroupId] = useState<number>(constants.RLC_GROUP_DEFAULT);
  const designationCount = useDesignationCount(rlcGroupId || undefined, true);

  const onCancelRequested = useCallback(() => {
    setValidationError({});
    setIsPostConfirmation(false);
    setClassyOrganizationId('');
    onCancel();
  }, [onCancel]);

  const onProceedRequested = useCallback(async () => {
    setValidationError({});
    if (selectedClassyOrganizationId !== Number(classyOrganizationId)) {
      setValidationError({ classyOrganizationId: 'The classy id appears to be incorrect.' });
    } else {
      if (rlcGroupId) {
        setIsPostConfirmation(true);
        const isSuccessful = await onSave(rlcGroupId, selectedOrganizationId);
        if (!isSuccessful) {
          onCancel();
        }
      } else {
        // this shouldn't happen
        console.error('rlcGroupId is not set');
      }
    }
  }, [onCancel, onSave, classyOrganizationId, selectedClassyOrganizationId, selectedOrganizationId, rlcGroupId]);

  const onInputChange: FormEventHandler<FormControl> = useCallback((event) => {
    const e = event as unknown as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
    const value = e?.target?.value || '';
    setClassyOrganizationId(value);
  }, []);

  return (
    <>
      {/* https://react-bootstrap-v3.netlify.app/components/modal/ */}
      <Modal show={isOpen} onHide={onCancelRequested}>
        <Modal.Header closeButton>{<Modal.Title>RLC Setup</Modal.Title>}</Modal.Header>
        <Modal.Body>
          {!isPostConfirmation && (
            <>
              Proceeding will add {designationCount || 0} Program Designations to this Classy Account. Are you sure that
              you would like to proceed?
              <FormGroup
                controlId="classyOrganizationId"
                style={{ marginTop: 15 }}
                validationState={getValidationState(validationError, 'classyOrganizationId')}
              >
                <ControlLabel>Classy Account Id</ControlLabel>
                <FormControl
                  name="classyOrganizationId"
                  type="text"
                  value={classyOrganizationId}
                  placeholder="Enter the classy id to confirm"
                  onChange={onInputChange}
                  style={{ width: '50%' }}
                />
                {/*<FormControl.Feedback />*/}
                <ValidationMessage field="classyOrganizationId" validationError={validationError} />
              </FormGroup>
              <FormGroup controlId="rlcGroupSelect">
                <ControlLabel>RLC Group</ControlLabel>
                <RlcGroupSelector value={rlcGroupId} onChanged={setRlcGroupId} />
              </FormGroup>
              <div className="submit-button text-center" style={{ marginTop: 20 }}>
                <ClassyButton title="Cancel" className={'secondary-button'} onClick={onCancelRequested} />
                <ClassyButton title="Proceed" disabled={!classyOrganizationId} onClick={onProceedRequested} />
              </div>
            </>
          )}
          {isPostConfirmation && (
            <>
              Your designations are currently being added to your Classy Account. You will receive a confirmation email
              when the upload is complete.
              <div className="submit-button text-center" style={{ marginTop: 20 }}>
                <ClassyButton title="Close" onClick={onCancelRequested} />
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateRlcAccountModal;
