import { useSelector } from 'react-redux';
import { RlcOrganization } from '../../Services/Rlc/RlcOrganization';
import { useEffect, useState } from 'react';
import api from '../../Services/Api';
import constants from '../../Helpers/constants';

export enum AccountStatus {
  Loading,
  IsPendingSetup,
  IsNotMember,
  IsMember,
}

export const useIsClassyAdmin = (): boolean =>
  useSelector<any, boolean>(
    (state) => state.login?.featurePermissions?.[constants.SYSTEM_ORGANIZATION_ID]?.admin === constants.FEATURES.ADMIN,
  );

export const useSelectedOrganizationId = (): number | undefined =>
  useSelector<any, number | undefined>((state) => state.login?.selectedOrganization?.id || undefined);

export const useRlcAccountStatus = (organizations: Array<RlcOrganization> | null) => {
  const selectedOrganizationId = useSelector<any, number | undefined>((state) => state.login?.selectedOrganization?.id);
  const [accountStatus, setAccountStatus] = useState<AccountStatus>(AccountStatus.Loading);

  useEffect(() => {
    if (organizations === null) {
      setAccountStatus(AccountStatus.Loading);
    } else {
      if (selectedOrganizationId) {
        const currentOrg = organizations.find((x) => x.organizationId === selectedOrganizationId);
        if (currentOrg && currentOrg.rlcGroupId !== null) {
          setAccountStatus(currentOrg.initialSetupComplete ? AccountStatus.IsMember : AccountStatus.IsPendingSetup);
        } else {
          setAccountStatus(AccountStatus.IsNotMember);
        }
      }
    }
  }, [organizations, selectedOrganizationId]);
  return accountStatus;
};

export const useDesignationCount = (rlcGroupId?: number, isPublic?: boolean) => {
  const [designationCount, setDesignationCount] = useState<number | null>(null);
  useEffect(() => {
    if (!rlcGroupId) {
      return;
    }

    const controller = new AbortController();
    api
      .getRlcParentDesignationCount(rlcGroupId, isPublic, { signal: controller.signal })
      .then((response) => {
        if (response.success) {
          setDesignationCount(response.data.count);
        } else {
          console.error(response.errors);
        }
      })
      .catch((e) => {
        console.error('== api.getRlcParentDesignationCount == ');
        console.error(e);
      });
    return () => {
      controller.abort();
    };
  }, [isPublic, rlcGroupId]);
  return designationCount;
};
